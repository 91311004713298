import React from 'react';

const NullSubscribeButton = () => {
    return (
      <div className={'mixtape-source-button-2-sub-unsub'}>
        ''
      </div>
    )
  }

  export default NullSubscribeButton