import React from 'react'

import PostCard from '../../PostCard'
import WinnerPostCardCaption from './WinnerPostCardCaption'

import './WinnerPostCard.css'

const WinnerPostCard = (props) => {
  const methodName = 'WinnerPostCard.render'
  console.log(methodName, '- start')

  const { electionDisplayName, electionImageUrl, electionTypeName, electionName, ...post } = props
  return (
    <div
      className="winner-post-card-body"
    >
      <WinnerPostCardCaption
        electionDisplayName={electionDisplayName}
        electionImageUrl={electionImageUrl}
        electionTypeName={electionTypeName}
        electionName={electionName}
      />
      <PostCard
        {...post}
      />
    </div>)
}

export default WinnerPostCard