import React from 'react'

const SearchBar = (props) => {
  const { 
    onChange, 
    handleKeyPress, 
    onSearchClick,
    searchableType,
    displayBorder, 
    displayWidth 
  } = props
  const placeholder = `Search ${searchableType.pluralName}`
  const inputBorder = displayBorder || '1px solid'
  const inputWidth = displayWidth || '50%'
  return (
    <div style={{ width: inputWidth }}>
      <input
        style={{ padding: '.5rem', border: inputBorder, width: '100%', outline: 'none' }}
        type="search"
        placeholder={placeholder}
        onChange={onChange}
        onKeyDown={(e) => handleKeyPress(e, () => onSearchClick())}
      />
    </div>
  )
}

export default SearchBar