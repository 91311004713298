import React from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import Img from 'react-image'

import '../PostCard.css'
import twitterVerifiedBadge from '../../../assets/twitterVerifiedBadge.svg'

const SmallText = (props) =>{
    const methodName = 'SmallText.render'
    console.log(methodName, '- props:', props)
  
      const {
        linkUrl,
        text,
        isVerified
      } = props

    return(
        <a
            href={linkUrl}
            target="_blank"
            rel="noopener noreferrer"
        >
        <Tooltip title={text}>
          <div className="post-small-text">
             {text}{isVerified ? <Img height='12px' style={{marginLeft: '3px'}} src={twitterVerifiedBadge}/> : null}
          </div>
        </Tooltip>
      </a>
    )
}

export default SmallText