import React from 'react'
import './Footer.css'
import { Link } from "react-router-dom"

const Footer = () => {
    return (
        <div className="FooterBody">
            <a 
                className="FooterBodyLink" 
                href='https://newsapi.org' 
                target="_blank" 
                rel="noopener noreferrer"
            >
                Powered by News API
            </a>
            <Link to="/privacyPolicy">
                <div className="FooterBodyLink" >Privacy Policy</div>
            </Link>
            <div>1vote © 2020</div>
        </div>
    )
}

export default Footer