import React from 'react'

import Vote from '../../Buttons/Vote'
import CircularImagesAndSmallTextContainer from '../CircularImagesAndSmallTextContainer/CircularImagesAndSmallTextContainer'
import TitleAndDescription from '../TitleAndDescription/TitleAndDescription'
import RedditPostCardHeaders from '../Headers/Reddit/RedditPostCardHeaders'

import redditUtils from '../../../functions/redditUtils'

import defaultSubredditIcon from '../../../assets/defaultSubredditIcon.png'
import redditRectangularImageUrl from '../../../assets/reddit_postcard_banner.png'

const redditSquareImageUrl = 'https://d.newsweek.com/en/full/1144653/reddit.png?w=1600&h=1600&q=88&f=ddac04ba445948d88075694066c3224d'
const redditUrl = 'https://www.reddit.com'
const redditDisplayUrl = 'www.reddit.com'

class RedditPostCard extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        postUrl: '',
        userUrl: '',
        subredditUrl: '',
        userIconUrl: '',
        subredditIconUrl: '',
        subredditBannerUrl: '',
        mediaThumbnailUrl: '',
      }
    }
  
    componentDidMount = async () => {
      const methodName = 'RedditPostCard.componentDidMount'
      console.log(methodName, '- props:', this.props)
  
      const { permalink, username, subreddit } = this.props.platformProps
  
      const postUrl = redditUtils.getRedditPostInfoUrl(permalink)
      console.log(methodName, '- postUrl:', postUrl)
      const userUrl = redditUtils.getRedditUserInfoUrl(username)
      console.log(methodName, '- userUrl:', userUrl)
      const subredditUrl = redditUtils.getRedditSubredditInfoUrl(subreddit)
      console.log(methodName, '- subredditUrl:', subredditUrl)
  
      const postData = await redditUtils.getRedditPostInfo(permalink)
      console.log(methodName, '- postData:', postData)
      const userData = await redditUtils.getRedditUserInfo(username)
      console.log(methodName, '- userData:', userData)
      const subredditData = await redditUtils.getRedditSubredditInfo(subreddit)
      console.log(methodName, '- subredditData:', subredditData)
  
      let mediaThumbnailUrl = ''
      if (!!postData.media && !!postData.media.oembed && !!postData.media.oembed.thumbnail_url) {
        mediaThumbnailUrl = postData.media.oembed.thumbnail_url.split('?')[0]
      }
  
      let redditVideoFallbackUrl = ''
      if (!!postData.secure_media && !!postData.secure_media.reddit_video && !!postData.secure_media.reddit_video.fallback_url) {
        redditVideoFallbackUrl = postData.secure_media.reddit_video.fallback_url.split('?')[0]
      }
  
      let userIconUrl = ''
      if (!!userData && !!userData.icon_img) {
        userIconUrl = userData.icon_img.split('?')[0]
      }
  
      let subredditIconUrl = ''
      let subredditBannerUrl = ''
      if (!!subredditData) {
        const { icon_img, community_icon, banner_img } = subredditData
        console.log(methodName, ' - icon_img:',icon_img,' - community_icon:',community_icon,' - banner_img:',banner_img)
        if (!!icon_img) {
          subredditIconUrl = icon_img.split('?')[0]
        }
        if (!!community_icon) {
          subredditIconUrl = community_icon.split('?')[0]
        }
        if (!!banner_img) {
          subredditBannerUrl = banner_img.split('?')[0]
        }
      }
  
      this.setState(
        {
          postUrl,
          userUrl,
          subredditUrl,
          userIconUrl,
          subredditIconUrl,
          subredditBannerUrl,
          mediaThumbnailUrl,
          redditVideoFallbackUrl,
        },
        () => {
          console.log(methodName, '- state:', this.state)
        }
      )
    }
  
    render = () => {
      const methodName = 'RedditPostCard.render'
      console.log(methodName, '- this.props:', this.props)
  
      console.log(methodName, '- here:', `this.onerror=null;this.src=${redditRectangularImageUrl};`)
  
      const {
        platformProps,
        formatDatetimePublished
      } = this.props
      
      const { 
        username, 
        title, 
        thumbnail, 
        link_url: linkUrl, 
        link_domain: linkDomain, 
        subreddit, 
        date 
      } = platformProps
      
      const {
        postUrl, 
        userUrl, 
        subredditUrl, 
        subredditBannerUrl, 
        mediaThumbnailUrl, 
        redditVideoFallbackUrl, 
        subredditIconUrl, 
        userIconUrl,
      } = this.state
  
      const capsText1 = `r/${subreddit}`
      const capsText2 = `u/${username}`
      const postTitle = `Post by ${username}:`
  
      const datetimePublished=formatDatetimePublished(date)
  
      return (
        <div className="post">
          <RedditPostCardHeaders
            postUrl={postUrl}
            linkUrl={linkUrl}
            linkDomain={linkDomain}
            postTitle={postTitle}
            mediaThumbnailUrl={mediaThumbnailUrl}
            thumbnail={thumbnail}
            redditVideoFallbackUrl={redditVideoFallbackUrl}
            subredditBannerUrl={subredditBannerUrl}
          />
          <CircularImagesAndSmallTextContainer
            {...this.props}
            imagesContainerLinkUrl1={redditUrl}
            imagesContainerLinkUrl2={subredditUrl}
            imagesContainerLinkUrl3={userUrl}
            imagesContainerImgOptions1={redditSquareImageUrl}
            imagesContainerImgOptions2={[subredditIconUrl, defaultSubredditIcon, redditSquareImageUrl]}
            imagesContainerImgOptions3={[userIconUrl, redditSquareImageUrl]}
            imagesContainerAltText1={redditDisplayUrl}
            imagesContainerAltText2={capsText1}
            imagesContainerAltText3={capsText2}
            textContainerLinkUrl1={subredditUrl}
            textContainerLinkUrl2={userUrl}
            textContainerText1={capsText1}
            textContainerText2={capsText2}
          />
          <TitleAndDescription 
            linkUrl={postUrl}
            datetimePublished={datetimePublished}
            postTitle={postTitle}
            description={title}
          />
          <Vote props={this.props} />
        </div>
      )
    }
  }

  export default RedditPostCard