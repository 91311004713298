import React from 'react'
// import google from '../../../../../assets/google.png'
import btn_google_signin_dark_normal_web from '../../../../../assets/btn_google_signin_dark_normal_web.png'
import btn_youtube_link from '../../../../../assets/btn_youtube_link.svg'
import youtubeUtils from '../../../../../functions/youtubeUtils'

const YoutubeAuth = (props) => {
  const methodName = 'YoutubeAuth.render'
  console.log(methodName, '- props:', props)

  const { src } = props
  return (
      <img
        id='youtubeSignInButton'
        className='sign-in-button-image'
        value={"youtube"}
        onClick={youtubeUtils.getYoutubeAuthorization}
        src={src==="link" ? btn_youtube_link : btn_google_signin_dark_normal_web}
        alt="youtube link button"
      />

    // <div style={{
    //   display: 'flex',
    //   justifyContent: contentJustification
    // }}>
    //   <button
    //     className={className}
    //     value={"youtube"}
    //     onClick={youtubeUtils.getYoutubeAuthorization}
    //     style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
    //   >
    //     <img
    //       src={google}
    //       alt="sign in with google"
    //       title="sign in with google"
    //       height="25px"
    //       style={{ marginLeft: '30px' }}
    //     />
    //     <div
    //       style={{
    //         marginRight: '30px',
    //         height: '25px',
    //         lineHeight: '25px',
    //         alignItems: 'center'
    //       }}
    //     >
    //       {message}
    //     </div>
    //   </button>
    // </div>
  )
}

export default YoutubeAuth