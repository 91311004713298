import { Component } from 'react'
import { withRouter } from "react-router-dom"
        //should make this actually be based on true location of results (since it could change based on screen size)
class ScrollToSearchDefinition extends Component {
    componentDidMount() {
      if (!!this.props.location&&!!this.props.location.state&&!!this.props.location.state.searchField&&this.props.location.state.searchField!=='') {
          console.log('SCROLL TO SEARCH')
        window.scrollTo(0, 1000)
      }
    }
  
    render() {
      return this.props.children
    }
  }
  
  class ScrollToTopDefinition extends Component {
    componentDidUpdate(prevProps) {
      if (this.props.location!==prevProps.location) {
          console.log('SCROLL TO TOP')
        window.scrollTo(0, 0)
      } 
    }
    
    render() {
        return this.props.children
    }
  }

  let ScrollToTop = withRouter(ScrollToTopDefinition)
  let ScrollToSearch = withRouter(ScrollToSearchDefinition)

  export {
    ScrollToTop,
    ScrollToSearch
  }
  