import ReactGA from 'react-ga'

const { OAuth2Client } = require('google-auth-library')

const getYoutubeOAuthClient = () => {
  const methodName = 'functions.youtubeUtils.getYoutubeOAuthClient'
  console.log(methodName)

  const oneVoteUrl = process.env.REACT_APP_ONEVOTE_URL
  const redirectPath = '/authHandler/youtube'
  const redirectUrl = oneVoteUrl + redirectPath

  return new OAuth2Client(
    process.env.REACT_APP_YOUTUBE_APP_CLIENT_ID,
    process.env.REACT_APP_YOUTUBE_APP_CLIENT_SECRET,
    redirectUrl,
  )
}

const getYoutubeAuthUrl = () => {
  const methodName = 'functions.youtubeUtils.getYoutubeAuthUrl'
  console.log(methodName)

  const oAuth2Client = getYoutubeOAuthClient()

  const defaultScope = [
    'https://www.googleapis.com/auth/youtube',
    'profile',
    'email',
    'openid'
  ]

  const options = {
    access_type: 'offline',
    response_type: 'code',
    state: 'youtube',
    // prompt: 'consent',
    scope: defaultScope,
  }

  const authUrl = oAuth2Client.generateAuthUrl(options)
  console.log(methodName, '- authUrl:', authUrl)

  return authUrl
}

const getYoutubeAuthorization = async () => {
  const methodName = 'functions.youtubeUtils.getYoutubeAuthorization'
  console.log(methodName)

  const authUrl = await getYoutubeAuthUrl()
  console.log(methodName, '- authUrl:', authUrl)

  ReactGA.pageview('/authHandler/youtube');

  try {
    window.open(authUrl, "_self")
  }
  catch (error) {
    console.error('error getting authorization from youtube:', error.message)
  }
}

const getYoutubeChannelInfo = async (channelId) => {
  const methodName = 'functions.youtubeUtils.getYoutubeChannelInfo'
  console.log(methodName, '- channelId:', channelId)

  const url = 'https://www.googleapis.com/youtube/v3/channels'
    + '?part=snippet'
    + `&id=${channelId}`
    + `&key=${process.env.REACT_APP_YOUTUBE_APP_API_KEY}`
  console.log(methodName, '- url:', url)

  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-type': 'application/json',
    },
  })  // UNCOMMENT BELOW TO LOG RESPONSE
  // console.log(methodName, "- response:", response)

  const data = await response.json()
  console.log(methodName, '- data:', data)
  const returnObject = data.items ? data.items[0].snippet : {}

  return returnObject
}

const revokeYoutubeToken = async (accessToken) => {
  const methodName = 'functions.youtubeUtils.revokeYoutubeToken'
  console.log(methodName, '- accessToken:', accessToken)

  const url = `https://oauth2.googleapis.com/revoke?token=${accessToken}`
  console.log(methodName, '- url:', url)

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-type': 'application/x-www-form-urlencoded',
    },
  }) 
  console.log(methodName, "- response:", response)

  const data = await response.json()
  console.log(methodName, '- data:', data)

  return data
}

export default {
  getYoutubeAuthorization,
  getYoutubeChannelInfo,
  revokeYoutubeToken
}
