import React from 'react';
import Button from '@material-ui/core/Button'

import '../Buttons/Buttons.css'

const ShowMoreButton = (props) => {
  const { name, onClick } = props
  return (
    <div className="buttonBody">
      <Button variant="contained" color="primary"
        onClick={onClick}
        name={name}
      >
        {name}
      </Button>
    </div>
  )
}

export default ShowMoreButton
