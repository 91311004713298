import arrayUtils from '../../../../functions/arrayUtils'
import allNewsSourcesRaw from './newsSources.json'

const allNewsSources = allNewsSourcesRaw.map(ns => {
  return {
    ...ns,
    imageLinkPath: '/news_source/' + ns.mixtapeSourceId,
  }
})

const allNewsSourcesObj = arrayUtils.arrayToObject(allNewsSources, 'mixtapeSourceId')

export default {
  allNewsSources,
  allNewsSourcesObj,
}