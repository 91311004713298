import React from 'react'

import DefaultPostCardHeader from './Headers/DefaultPostCardHeader'
import TitleAndDescription from './TitleAndDescription/TitleAndDescription'

import videoRectangularImage from '../../assets/videoRectangularImage.svg'
const newsRectangularImageUrl = 'https://live.staticflickr.com/6033/6277209256_934f20da10_b.jpg'

class PlaceholderCard extends React.Component {
 
    render = () => {
      const methodName = 'NewsPostCard.render'
      console.log(methodName, '- this.props:', this.props)
      const { electionTypeId } = this.props
      
      let headerImage;
      let bodyText='';
  
      switch(electionTypeId){
        case 0:
          headerImage=newsRectangularImageUrl
          bodyText="No news has been voted for yet. Be the first!"
          break;
        case 1:
          headerImage=videoRectangularImage
          bodyText="No videos have been voted for yet. Be the first!"
          break;
        case 2:
          headerImage=newsRectangularImageUrl
          bodyText="No one has been voted for yet. Be the first!"
          break;
        case 3:
          headerImage=newsRectangularImageUrl
          bodyText="Nothing has been voted for yet. Be the first!"
          break;
      }
  
      return (
        <div className="post">
          <DefaultPostCardHeader 
            contentSource={[headerImage]}
          />
          <div className="post-circle-images-and-small-text-container">
          </div>
          <TitleAndDescription 
            description={bodyText}
          />
          <div className="post-vote-container">
          </div>
        </div>
      )
    }
}

export default PlaceholderCard  