import React from 'react'
import './Settings.css'
import UserSettings from './UserSettings/UserSettings'
import PlatformSettings from './PlatformSettings/PlatformSettings'
import Menus from '../Menus/Menus'
import voteOn from '../../assets/1vote-on.svg'
import twitter from '../../assets/twitterLogo.svg'
import youtube from '../../assets/youtubeLogo.svg'
import reddit from '../../assets/redditLogo.svg'
import news from '../../assets/news.png'
import signOut from '../../assets/signOut.png'
import enums from '../../enums/enums'
import {
  Switch,
  Route,
  useRouteMatch
} from "react-router-dom"
import Img from 'react-image'
import defaultProfileIcon from '../../assets/default_profile.png'


const Settings = (props) => {
  let { path } = useRouteMatch()
  const {
    user,
    location,
    setUser,
    onInitializeState,
    isSignedIn,
    toggleVoteModal
  } = props
  const { searchField } = location.state || ''

  const routes = [
    {
      displayName: 'User',
      path: '',
      name: '',
      linkKey: 0,
      icon: <Img
        src={[user.imageUrl, defaultProfileIcon]}
        height={'25px'}
        style={{ borderRadius: '100%' }}
        alt="user avatar"
        title="user avatar"
        />,
      selected: location.pathname.replace('/userSettings', '')===''
    },
    {
      displayName: 'Twitter',
      path: '/twitter',
      name: '/twitter',
      linkKey: 1,
      icon: <img
        src={twitter}
        height={'25px'}
        alt="twitter"
        title="twitter"
      />,
      selected: location.pathname.replace('/userSettings', '')==='/twitter'
    },
    {
      displayName: 'News',
      path: '/news',
      name: '/news',
      linkKey: 2,
      icon: <img
        src={news}
        height={'25px'}
        alt="news"
        title="news"
      />,
      selected: location.pathname.replace('/userSettings', '')==='/news'
    },
    {
      displayName: 'YouTube',
      path: '/youtube',
      name: '/youtube',
      linkKey: 3,
      icon: <img
        src={youtube}
        height={'25px'}
        alt="youtube"
        title="youtube"
      />,
      selected: location.pathname.replace('/userSettings', '')==='/youtube'
    },
    {
      displayName: 'Reddit',
      path: '/reddit',
      name: '/reddit',
      linkKey: 4,
      icon: <img
        src={reddit}
        height={'25px'}
        alt="reddit"
        title="reddit"
      />,
      selected: location.pathname.replace('/userSettings', '')==='/reddit'
    },
    // {
    //   displayName: '1vote',
    //   path: '/1vote',
    //   name: '/1vote',
    //   linkKey: 5,
    //   icon: <img
    //     src={voteOn}
    //     height={'25px'}
    //     alt="1vote"
    //     title="1vote"
    //   />,
    //   selected: location.pathname.replace('/userSettings', '')==='/1vote'
    // },
    {
      displayName: 'SignOut',
      path: '/',
      name: '/',
      linkKey: 6,
      icon: <img
        src={signOut}
        height={'25px'}
        alt="signOut"
        title="signOut"
      />
    },
  ]

  return (
    <div>
      <div className="SettingsBody">
        <Switch>
          <Menus {...props}
            displayName={location.pathname.replace('/userSettings', '')}
            linkKey={(location.state === undefined || location.state.userSettings === undefined) ? 0 : location.state.userSettings.linkKey}
            user={user}
            routes={routes}
            onInitializeState={onInitializeState}
            parent={'userSettings'}
            navHeader={'Settings'}
            onPlatformSelect={()=>{}}
          >

            <Route exact path={path}>
              <div className="SettingsChildWrapper">
                <UserSettings {...props}
                  settingsPage={enums.SETTINGS_PAGE.USER_SETTINGS}
                  user={user}
                  setUser={setUser}
                  isSignedIn={isSignedIn}
                  toggleVoteModal={toggleVoteModal}
                />
              </div>
            </Route>

            {/* <Route exact path={`${path}/groups`}>
              <div className="SettingsChildWrapper">
                <GroupsSettings {...props}
                  settingsPage={enums.SETTINGS_PAGE.GROUP_SETTINGS}
                  user={user}
                  createGroup={createGroup}
                  subscribe={subscribe}
                  unsubscribe={unsubscribe}
                  handleKeyPress={handleKeyPress}
                  searchField={searchField}
                  isSignedIn={isSignedIn}
                  toggleVoteModal={toggleVoteModal}
                />
              </div>
            </Route> */}

            {
              Object.values(enums.PLATFORMS).map(platform => {
                return (
                  <Route exact path={`${path}/${platform.name}`} key={platform.id}>
                    <div className="SettingsChildWrapper">
                      <PlatformSettings {...props}
                        settingsPage={enums.getPlatformSettingsPageFromPlatformId(platform.id)}
                        searchField={searchField}
                        platform={platform}
                      />
                    </div>
                  </Route>
                )
              })
            }
          </Menus>
        </Switch>
      </div>
    </div>
  )
}

export default Settings