import React from 'react'

import DefaultPostCardHeader from '../Headers/DefaultPostCardHeader'
import Vote from '../../Buttons/Vote'
import CircularImagesAndSmallTextContainer from '../CircularImagesAndSmallTextContainer/CircularImagesAndSmallTextContainer'
import TitleAndDescription from '../TitleAndDescription/TitleAndDescription'

import newsSources from '../../Settings/PlatformSettings/NewsSettings/newsSources'

import defaultNewsIcon from '../../../assets/defaultNewsIcon.png'
import newsSquareImage from '../../../assets/N.png'

const newsRectangularImageUrl = 'https://live.staticflickr.com/6033/6277209256_934f20da10_b.jpg'

class NewsPostCard extends React.Component {
 
    render = () => {
      const methodName = 'NewsPostCard.render'
  
      const {
        platformProps,
        searchableType,
        formatDatetimePublished
      } = this.props
  
      const {
        newsAuthor, 
        newsDescription, 
        newsSourceName, 
        newsTitle, 
        newsUrl, 
        newsUrlToImage,
        newsSourceId, 
        date,
      } = platformProps
  
      const regex = /<\/?[\w\s="/.':;#-\/\?]+>/gi;
      const processedNewsTitle = newsTitle ? newsTitle.replace(regex, ''): null
      const processedNewsDescription = newsDescription ? newsDescription.replace(regex, ''): null
  
      const newsSource = newsSources.allNewsSourcesObj[newsSourceId]
      console.log('NEWSSOURCE', newsSource)
      
      let newsSourceImageUrl='', newsSourceRectangularImageUrl='', newsSourceUrl='';

      if(newsSource){
        const {
          imageUrl,
          rectangularImageUrl,
          url,
        } = newsSource
        newsSourceImageUrl=imageUrl
        newsSourceRectangularImageUrl=rectangularImageUrl
        newsSourceUrl=url
    } else {
      newsSourceUrl = newsUrl.slice(0, newsUrl.search(".com")+4)
    }
      
      const newsPlatformSettingsUrl = process.env.REACT_APP_ONEVOTE_URL + searchableType.settingsPagePath
      const altText = 'News Settings'
      const capsText1 = (newsSourceName || '').toUpperCase()
      const capsText2 = (newsAuthor || '').toUpperCase()
  
      const datetimePublished=formatDatetimePublished(date)
  
      return (
        <div className="post">
          <DefaultPostCardHeader 
            postUrl={newsUrl} 
            postTitle={newsTitle} 
            contentSource={[newsUrlToImage, newsSourceRectangularImageUrl, newsRectangularImageUrl]}
          />
         <CircularImagesAndSmallTextContainer
            {...this.props}
            imagesContainerLinkUrl1={newsPlatformSettingsUrl}
            imagesContainerLinkUrl2={newsSourceUrl}
            imagesContainerImgOptions1={newsSquareImage}
            imagesContainerImgOptions2={[newsSourceImageUrl, defaultNewsIcon]}
            imagesContainerAltText1={altText}
            imagesContainerAltText2={capsText1}
            textContainerLinkUrl1={newsSourceUrl}
            textContainerLinkUrl2={newsSourceUrl}
            textContainerText1={capsText1}
            textContainerText2={capsText2}
          />
          <TitleAndDescription 
            linkUrl={newsUrl}
            datetimePublished={datetimePublished}
            processedNewsTitle={processedNewsTitle}
            postTitle={newsTitle}
            description={newsDescription}
            processedNewsDescription={processedNewsDescription}
          />
          <Vote props={this.props} />
        </div>
      )
    }
  }
  
  export default NewsPostCard