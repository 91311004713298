import React from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import RemoveIcon from '@material-ui/icons/Remove'
import enums from '../../enums/enums'

const UnsubscribeButton = (props) => {
  const { onUnsubscribe, searchableType, isSignedIn, toggleVoteModal } = props
  let text = ''

  if (searchableType.id === enums.SEARCHABLE_TYPE.ONEVOTE_GROUP.id) {
    text = `Remove from your groups`
  } else {
    text = `Unsubscribe on 1vote and ${searchableType.platform.displayName}`
  }

  return (
    <div className={'mixtape-source-button-2-sub-unsub'}>
      <Tooltip title={text}>
      {(isSignedIn === false) ? (<RemoveIcon
          alt={text}
          style={{ fontSize: 35 }}
          onClick={() => toggleVoteModal({})}
        />) : (<RemoveIcon
          alt={text}
          style={{ fontSize: 35 }}
          onClick={() => onUnsubscribe()}
        />)}
      </Tooltip>
    </div>
  )
}

export default UnsubscribeButton