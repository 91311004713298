import React, { Component } from 'react'

import AuthHandlerBody from './AuthHandlerBody'

import api from '../../functions/api'
import localUtils from '../../functions/localUtils'
import enums from '../../enums/enums'

class TwitterAuthHandler extends Component {
  constructor(props) {
    const methodName = 'TwitterAuthHandler.constructor'
    console.log(methodName, '- props,', props)
    super(props)
  }

  componentDidMount = async () => {
    const methodName = 'TwitterAuthHandler.componentDidMount'
    console.log(methodName, '- props:', this.props)

    const { handleSignIn } = this.props
    const platformId = enums.PLATFORMS.TWITTER.id

    const urlParams = new URLSearchParams(window.location.search)
    const oauthVerifier = urlParams.get('oauth_verifier')
    console.log(methodName, 'oauthVerifier:', oauthVerifier)

    const twitterRequestToken = JSON.parse(window.localStorage.getItem('twitterRequestToken'))
    console.log(methodName, '- twitterRequestToken:', twitterRequestToken)

    const credentials = localUtils.getCredentials() || {}
    console.log(methodName, '- credentials:', credentials)

    const platformName='twitter'
    const provider='twitter'
    const otherSignInPlatformName='youtube'
    const signInMethod = api.twitterSignIn
    const pageView='TwitterAuthHandler'

    const hasNeededInfo = oauthVerifier && twitterRequestToken

    if (hasNeededInfo) {
      console.log(methodName, `- ${platformName} codes present!`)
      const platformProps = { oauthVerifier, twitterRequestToken }

      handleSignIn(credentials, platformId, platformProps, platformName, provider, otherSignInPlatformName, pageView, signInMethod)
    }
  }

  render() {
    const methodName = 'TwitterAuthHandler.render'
    console.log(methodName, '- props:', this.props)
    
    return <AuthHandlerBody platformName='twitter'/>
  }
}

export default TwitterAuthHandler