import React from 'react'
import { Redirect } from 'react-router-dom'

import TwitterAuth from '../Settings/PlatformSettings/TwitterSettings/TwitterAuth/TwitterAuth'
import YoutubeAuth from '../Settings/PlatformSettings/YoutubeSettings/YoutubeAuth/YoutubeAuth'
import Menus from '../Menus/Menus'
import enums from '../../enums/enums'

import './SignIn.css'

const SignIn = (props) => {

  console.log('SignIn', props)

  const { redirectToReferrer, location, user } = props
  const { from } = location.state || { from: { pathname: `/home/${enums.DEFAULT_ELECTION_TYPES[0].name}` } }
  const { originProps } = location 
  const origin = !!originProps ? originProps.origin : null
  const autoPlay =  origin==='demo' ? 1 : 0
  const tutorialUrl = `https://www.youtube.com/embed/ccY9Q_jmwFw`
  const tutorialUrlEmbedParameters = {
    modestbranding: 1,
    rel: 0,
    frameborder: 0,
    autoplay: autoPlay
  }
  let tutorialEmbedParamterString = ''
  Object.entries(tutorialUrlEmbedParameters)
    .forEach((setting, i) => tutorialEmbedParamterString += `${i===0?'':'&'}${setting[0]}=${setting[1]}`)
  const tutorialEmbedUrl = tutorialUrl + `?${tutorialEmbedParamterString}`
  if (redirectToReferrer) {
    return <Redirect to={from}/>
  }

  return (<div>
    <Menus
      user={user}
      parent='signIn'
      routes={[]}
    >
    <div className="signInContainer">
      <div className="pitchContainer">
        <h2 className="tagline">One Person. One Vote.</h2>
        <div className="tutorialVideo">
          <iframe
            title={'tutorialVideo'}
            className="tutorialVideoFrame"
            src={tutorialEmbedUrl}
            loading="lazy"
          />
        </div>
      </div>
      <div className="signInWithProviderContainer">
        <h2 className="tagline">Sign Up Now!</h2>
        <div className="styledAuth">
          <div style={{ boxSizing: 'border-box' }}>
            <TwitterAuth
              className="sign-in-button-image"
              message={"Sign In with Twitter"}
            />
          </div>
          <div style={{ marginTop: '2%' }}>
            <YoutubeAuth
              className="sign-in-button-image"
              message={"Sign In with Google"}
            />
          </div>
        </div>
      </div>
    </div>
    </Menus>
    </div>
  )
}

export default SignIn
