import enums from '../enums/enums'
import arrayUtils from './arrayUtils'

const incrementVote = (post, postId) => {
    const methodName = 'Mixtape.incrementVote'
    console.log(methodName, '- post:', post)
    return {
      ...post,
     postId,
      voteCount: post.voteCount + 1,
      postIsVoted: true,
    }
  }

  const  decrementVote = (post) => {
    const methodName = 'Mixtape.decrementVote'
    console.log(methodName, '- post:', post)
    return {
      ...post,
      voteCount: post.voteCount - 1,
      postIsVoted: false,
    }
  }

  const unVote = async (setState, postId, state, sortFunc) => {
    const methodName = 'MixtapeSourceTimeline.unVote'
    console.log(methodName, '- postId:', postId)

    const posts = state.posts.map(post => {
      if (post.postIsVoted) {
        return decrementVote(post)
      } else {
        return post
      }
    }).sort(sortFunc)


    setState({ posts }, async () => {
        console.log(methodName, '- setState() complete')
    })
  }

  const vote = async (setState, postId, platformProps, state, sortFunc) => {
    const methodName = 'PostCardList.vote'
    console.log(methodName, '- postId, platformProps:', postId, platformProps)

    const posts = state.posts.map(post => {
      if (post.postIsVoted && post.postId !== postId) {
        console.log(methodName, '- reducing vote count for post:', post.postId)
        return decrementVote(post)
      } else if ((platformProps === post.platformProps) || (postId === post.postId)) {
        console.log(methodName, '- increasing vote count for post:', post.postId, post.postIsVoted)
        return incrementVote(post, postId)
      } else {
        return post
      }
    }).sort(sortFunc)

    setState({
      posts,
    }, async () => {
      console.log(methodName, '- setState() complete')
    })
  }

  const populateNewPostId = (setState, postId, platformProps, state, cb) => {
    const methodName = 'populateNewPostId'
    console.log(methodName, '- postId, platformProps:', postId, platformProps)

    const posts = state.posts.map(post => {
      if (post.platformProps === platformProps) {
        console.log(methodName, '- populating postId', postId)
        return {
          ...post,
          postId
        }
      } else {
        return post
      }
    })

    setState(
      {
        posts
      }, cb
    )
  }

const getSessionPosts = (postsType) =>{
  const methodName = 'Mixtape.getSessionMixtapeSourceTimelinePosts'
  console.log(methodName, '- start')

  return JSON.parse(sessionStorage.getItem(postsType))
}

const setSessionPosts = (postsType, arr) =>{
  const methodName = 'MixtapeourceTimeline.setSessionMixtapeSourceTimePosts'
  console.log(methodName, '- start')
  
  sessionStorage.setItem(postsType, JSON.stringify(arr))
}

const getPosts = (setState, state) => {
  const methodName = 'MixtapeSourceTimeline.getPosts'
  console.log(methodName, '- start')

  const { nextStoredPostIndex, posts, postsType } = state
  console.log(methodName, '- nextStoredPostIndex', nextStoredPostIndex)
  
  const sessionPosts = getSessionPosts(postsType) 

  if (sessionPosts == null) {
    setSessionPosts(postsType, [])
  }

  const data = getSessionPosts(postsType)
  console.log(methodName, "- data.length:", data.length)
  // console.log(methodName, "- data:", data)

  const pageSize = 12
  const mixtapeSourceTimelinePage = data.slice(nextStoredPostIndex, nextStoredPostIndex + pageSize)
  console.log(methodName, '- mixtapeSourceTimelinePage', mixtapeSourceTimelinePage)

  if (mixtapeSourceTimelinePage.length > 0) {
    setState({
      posts: posts.concat(mixtapeSourceTimelinePage),
      nextStoredPostIndex: nextStoredPostIndex + mixtapeSourceTimelinePage.length
    })
  }

  return mixtapeSourceTimelinePage
}

const voteWhenPostsInSessionStorage = async(setState, postId, platformProps, state, sortFunc) => {
  const methodName = 'MixtapeSourceTimeline.vote'
  console.log(methodName, '- postId, platformProps:', postId, platformProps)

  vote(setState, postId, platformProps, state, sortFunc)
  const {postsType} = state
  const storedPosts = getSessionPosts(postsType).map(post => {
    if (post.postIsVoted && post.postId !== postId) {
      console.log(methodName, '- reducing vote count for post:', post.postId)
      return decrementVote(post)
    } else if ((platformProps === post.platformProps) || (postId === post.postId)) {
      console.log(methodName, '- increasing vote count for post:', post.postId)
      return incrementVote(post, postId)
    } else {
      return post
    }
  })

  sessionStorage.removeItem(postsType)
  setSessionPosts(postsType, storedPosts)
  console.log(methodName, '- stored mixtapeSourceTimelinePosts updated')
}

const onVoteChange = async (setState, postId, platformProps, state, isVoted, sortFunc) => {
  const methodName = 'MixtapeSourceTimeline.onVoteChange'
  console.log(methodName, '- postId, platformProps, isVoted, sortFunc:', postId, platformProps, isVoted, sortFunc)

  if (postId === null) {
    throw Error(methodName + ' called with null postId')
  }

  populateNewPostId(setState, postId, platformProps, state, async () => {
    console.log(methodName, '- postId populated, making changes to this.state.posts')
    if (isVoted) {
      return unVote(setState, postId, state)
    } else if(state.postsType) {
      return voteWhenPostsInSessionStorage(setState, postId, platformProps, state)
    } else {
      return vote(setState, postId, platformProps, state, sortFunc)
    }
  })
}

const mapPosts = (arr, user) => {
  const votedPost = user.activeVotes[1]
  const votedPostId = votedPost ? votedPost.postId : 0
  return arr.map((post, i) => {
    const { postId } = post
    const isVoted = votedPostId === postId
    return {
      ...post,
      voteCount: parseInt(post.voteCount),
      postIsVoted: isVoted,
    }
  })
}

const mixtapeSourceSubscriptionAndLinkageStatus = (post, user) => {
  const twitterAccountsArr = !!user.platforms.twitter ? Object.values(user.platforms.twitter.mixtapeSources) : []
  const twitterAccountsObj = arrayUtils.arrayToObject(twitterAccountsArr, 'idStr')
  const searchableType = enums.getSearchableTypeByPlatformId(post.platformId)
  const platform = searchableType.platform

  const subscriptionIdType = searchableType.subscriptionIdType

  const platformProps = post.platformProps
  const subscriptionId = platformProps[subscriptionIdType]

  const mixtapeSources = !!user.platforms[platform.name] ? user.platforms[platform.name].mixtapeSources : ''

  const isPlatformLinked = !!user.platforms[platform.name]
  let userIsSubscribed
  if (post.platformId === enums.PLATFORMS.NEWS.id
    || post.platformId === enums.PLATFORMS.YOUTUBE.id
    || post.platformId === enums.PLATFORMS.REDDIT.id
  ) {
    userIsSubscribed = !!mixtapeSources[subscriptionId]
    console.log('userIsSubscribed', userIsSubscribed)
  } else if (post.platformId === enums.PLATFORMS.TWITTER.id) {
    userIsSubscribed = !!twitterAccountsObj[subscriptionId]
  }
  return { userIsSubscribed, isPlatformLinked }
}

export default {
  incrementVote,
  decrementVote,
  unVote,
  vote,
  populateNewPostId,
  getSessionPosts,
  setSessionPosts,
  getPosts,
  voteWhenPostsInSessionStorage,
  onVoteChange,
  mapPosts,
  mixtapeSourceSubscriptionAndLinkageStatus
}