import React from 'react'
import Img from 'react-image'
import { Link } from 'react-router-dom'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

import voteIcon from '../../../../assets/1vote-checkmark.svg' 
import './WinnerPostCard.css'

const WinnerPostCardCaption = (props) => {
    const methodName = 'WinnerPostCardCaption.render'
    console.log(methodName, '- props:', props)

    const { electionDisplayName, electionImageUrl, electionTypeName, electionName } = props
    return (
        <div className="winner-post-card-caption">
            <div>
                <Img 
                src={[electionImageUrl]}
                className="winner-post-card-election-image"
                />  
            </div>
            <div>
                <div className="winner-post-card-election-name">
                {electionDisplayName}
                </div>
                <div className="winner-post-card-current-winner-subtitle">
                Current Winner
                <Img src ={voteIcon} height='20px' style={{marginLeft: '5px'}}/>
                </div>
            </div>
            <div >
                <Link
                to={`/home/${electionTypeName}/${electionName}`}
                >
                <ArrowForwardIosIcon 
                    className="winner-post-card-election-link-image"
                />
                </Link>
            </div>
        </div>
    )
}

  export default WinnerPostCardCaption