import React from 'react'

import PostCard from '../PostCard/PostCard'
import VotePostCard from '../PostCard/PageSpecificPostCards/VotePostCard/VotePostCard'
import WinnerPostCard from '../PostCard/PageSpecificPostCards/WinnerPostCard/WinnerPostCard'

import enums from '../../enums/enums'
import interactionUtils from '../../functions/interactionUtils'

import './PostCardList.css'

class PostCardList extends React.Component {
  constructor(props) {
    const methodName = 'PostCardList.constructor'
    console.log(methodName, '- start')
    console.log(methodName, props)
    super(props)
    this.state = {
      posts: [],
    }
  }

  componentDidMount = async () => {
    const methodName = 'PostCardList.componentDidMount'
    console.log(methodName, '- start')

    const posts = await this.props.getPosts()
    console.log(methodName, '- posts.length:', posts.length)

    const sortedPosts = posts.sort(this.props.sortFunc)
    console.log(methodName, '- posts sorted')

    this.setState({
      posts: sortedPosts,
    })
  }

  componentDidUpdate = async (prevProps) => {
    const methodName = 'PostCardList.componentDidUpdate'
    console.log(methodName, '- start')
    // console.log(methodName, '- prevProps, this.props:', prevProps, this.props)

    const {
      election,
      postCardListName,
      postCardListType,
      user
    } = this.props

    let activeVotesPostsSum = (obj) => {
      let arr = Object.values(obj.activeVotes)
      let postsArr = arr.map(
        vote => parseInt(vote.postId)
      )
      return postsArr.reduce(
        (accumulator, currentValue) => accumulator + currentValue, 0
      )
    }

    const postCardListNameChanged = prevProps.postCardListName !== postCardListName
    console.log("postCardListNameChanged", postCardListNameChanged)
    const electionChanged = prevProps.election.id !== election.id
    console.log("electionChanged", electionChanged)

    const votesChanged = (activeVotesPostsSum(prevProps.user) !== activeVotesPostsSum(user))
    console.log("votesChanged", votesChanged, postCardListType.refreshOnVote)

    if (postCardListNameChanged
      || electionChanged
      || (votesChanged && postCardListType.refreshOnVote)
    ) {
      console.log(methodName, ' - fetching posts again')
      const posts = await this.props.getPosts()
      console.log(methodName, '- posts.length:', posts.length)
      const sortedPosts = posts.sort(this.props.sortFunc)
      console.log(methodName, '- posts sorted')
      this.setState({ posts: sortedPosts })
    }
  }

  onVoteChange = async (electionId, postId, platformProps, isVoted) => {
    const methodName = 'MixtapeSourceTimeline.onVoteChange'
    console.log(methodName, '- electionId, postId, platformProps, isVoted:', electionId, postId, platformProps, isVoted)

    return interactionUtils.onVoteChange(this.setState.bind(this), postId, platformProps, this.state, isVoted, this.props.sortFunc)
  }

  render() {
    const methodName = 'PostCardList.render'
    console.log(methodName, '- start')
    console.log(methodName, '- this.props, this.state:', this.props, this.state)

    const {
      postCardListType,
      toggleVoteModal,
      loadingMessage,
      user,
      isSignedIn,
      subscribe,
      unsubscribe,
      election,
      toggleVote,
      voteModalState,
      updateActiveVotes
    } = this.props
    const { posts } = this.state

    if ((posts.length) === 0) {
      console.log(methodName, '- rendering loading message (no posts)')
      return (
        <div className="postCardListContainer">
          <div className="emptyList">
            <h3>{loadingMessage}</h3>
          </div>
        </div>
      )
    }

    return (
      <div className="postCardListContainer">
        <div className="postCardList">
          {
            posts.map((post, i) => {
              const subscriptionAndLinkageStatus = interactionUtils.mixtapeSourceSubscriptionAndLinkageStatus(post, user)
              const { userIsSubscribed, isPlatformLinked } = subscriptionAndLinkageStatus
              let key = ''
              let PostCardComponentType = ''
              switch (postCardListType.id) {
                case enums.POST_CARD_LIST_TYPE.PROFILE.id:
                  key = post.key + post.datetimeActivated
                  PostCardComponentType = VotePostCard
                  break
                case enums.POST_CARD_LIST_TYPE.WINNERS.id:
                  key = post.key
                  PostCardComponentType = WinnerPostCard
                  break
                default:
                  key = post.key
                  PostCardComponentType = PostCard
                  break
              }
              return (
                <div
                  className="post-card-body"
                  key={post.key + post.datetimeActivated}
                >
                  <PostCardComponentType
                    {...post}
                    key={key}
                    onVoteChange={this.onVoteChange}
                    toggleVoteModal={toggleVoteModal}
                    user={user}
                    subscribe={subscribe}
                    unsubscribe={unsubscribe}
                    userIsSubscribed={userIsSubscribed}
                    isPlatformLinked={isPlatformLinked}
                    isSignedIn={isSignedIn}
                    electionTypeName={election.name}
                    toggleVote={toggleVote}
                    voteModalState={voteModalState}
                    updateActiveVotes={updateActiveVotes}
                  />
                </div>)
            })
          }
        </div>
      </div>
    )
  }
}

export default PostCardList