const arrayToObject = (array, keyName) =>
  array.reduce((obj, item) => {
    obj[item[keyName]] = item
    return obj
  }, {})

const getByField = (array, field, value, expectOne) => {
  const filteredArray = array.filter(x => x[field] === value)
  if (expectOne && filteredArray.length !== 1) {
    throw Error(`Only one array element expected, found ${array.length}`)
  }
  if (filteredArray.length === 0) {
    return null
  }
  return filteredArray[0]
}

export default {
  arrayToObject,
  getByField,
}
