import React from 'react'
import SubscribeButton from './SubscribeButton'
import UnsubscribeButton from './UnsubscribeButton'
import NullSubscribeButton from './NullSubscribeButton'
import PlatformIconLinkButton from './PlatformIconLinkButton'

const SubUnsubButton = (props) => {
  const methodName = 'SubUnsubButton.render'
  console.log(methodName, '- props:', props)

  const { isSignedIn, userIsSubscribed, isPlatformLinked, onSubscribe, onUnsubscribe, searchableType, toggleVoteModal } = props
  const { platform } = searchableType
  if (userIsSubscribed === undefined) {
    return (<NullSubscribeButton/>)
  } else if (!isPlatformLinked) {
    return (
      <PlatformIconLinkButton
        platform={platform}
      />
    )
  } else if (userIsSubscribed) {
    return (
      <UnsubscribeButton
        searchableType={searchableType}
        onUnsubscribe={onUnsubscribe}
        isSignedIn={isSignedIn}
        toggleVoteModal={toggleVoteModal}
      />
    )
  } else {
    return (
      <SubscribeButton
        searchableType={searchableType}
        onSubscribe={onSubscribe}
        isSignedIn={isSignedIn}
        toggleVoteModal={toggleVoteModal}
      />
    )
  }
}

export default SubUnsubButton