import React from 'react'
import './SignIn.css'
import { Link } from "react-router-dom"
import Button from '@material-ui/core/Button'
import TwitterAuth from '../Settings/PlatformSettings/TwitterSettings/TwitterAuth/TwitterAuth'
import YoutubeAuth from '../Settings/PlatformSettings/YoutubeSettings/YoutubeAuth/YoutubeAuth'

const SignInModal = (props) => {

  return (
    <div className="signInModalContainer">
        <h2 className="callToAction">Sign in or Sign up</h2>
          <div className="signInModalButton">
            <TwitterAuth/>
          </div>
          <div className="signInModalButton" >
            <YoutubeAuth/>
          </div>
          <div className="signInModalButton">
          <Link to={{
            pathname: "/signIn",
            originProps: {
              origin: 'demo'
            }             
          }}
          >
            <Button variant={"contained"}
                    color={"primary"}
                    style={{
                      width: '190px'
                    }}
                    onClick={() => props.onModalClose('')}
                                >
              Watch a Demo
            </Button>
          </Link>
      </div>
      </div>
  )
}

export default SignInModal
