import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import App from './Containers/App';
// import { Provider } from 'react-redux'
import * as serviceWorker from './serviceWorker';

//material-ui
import 'typeface-roboto';

// import store from './redux/store'

//styling
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';



ReactDOM.render(
        <App />,
    document.getElementById('root')
    // < Provider store = { store } >
    //     <App />
    // </Provider >,
    // document.getElementById('root')
);

serviceWorker.register();
