import React from 'react'
import { Link, useLocation } from "react-router-dom"

import { MobileAppBar } from './AppBars'
import enums from '../../enums/enums'

import mixtape from '../../assets/mixtape.png'
import DynamicFeedIcon from '@material-ui/icons/DynamicFeed';
import SettingsIcon from '@material-ui/icons/Settings'
import SearchIcon from '@material-ui/icons/Search'
import HomeIcon from '@material-ui/icons/Home'

import '../Billboard/Billboard.css'
import './NavigationBar.css'

const MobileNavigationBar=(props)=>{
    const location = useLocation()
    const isSignInPage = (location.pathname==='/'||location.pathname==='/signIn') 
    if (!isSignInPage) {
      return (
        <MobileAppBar>
          <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            className: 'billboardNav'
          }}>
            <div style={{ width: '25%', height: '60px' }}>
              <Link 
            to={`/home/${enums.DEFAULT_ELECTION_TYPES[0].name}`}
            style={{ textDecoration: 'none', className: 'billboardNav' }}
              >
                <div style={{ fontSize: '40px' }}>
                  <HomeIcon style={{
                    color: '#2d2d2d',
                    fontSize: 'inherit',
                    className: 'billboardNav'
                  }}
                  />
                </div>
              </Link>
            </div>
            <div style={{ width: '25%', height: '60px' }}>
              <Link to="/feed">
                <div style={{ fontSize: '40px' }}>
                  <DynamicFeedIcon
                       style={{
                        color: '#2d2d2d',
                        fontSize: 'inherit',
                        className: 'billboardNav'
                      }}
                  />
                </div>
              </Link>
            </div>
            <div style={{ width: '25%', height: '60px' }}>
              <Link to="/search">
                <div style={{ fontSize: '40px' }}>
                  <SearchIcon style={{
                    color: '#2d2d2d',
                    fontSize: 'inherit',
                    className: 'billboardNav'
                  }}
                  />
                </div>
              </Link>
            </div>
            <div style={{ width: '25%', height: '60px' }}>
              <Link to="/userSettings">
                <div style={{ fontSize: '40px' }}>
                  <SettingsIcon style={{
                    color: '#2d2d2d',
                    fontSize: 'inherit',
                    className: 'billboardNav'
                  }}
                  />
                </div>
              </Link>
            </div>
          </div>
        </MobileAppBar>
      )
    } else {
      return null
    }
  }

export default MobileNavigationBar
