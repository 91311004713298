import React from 'react'
import AppBar from '@material-ui/core/AppBar'
import CssBaseline from '@material-ui/core/CssBaseline'
import Drawer from '@material-ui/core/Drawer'
import Hidden from '@material-ui/core/Hidden'
import { useTheme } from '@material-ui/core/styles'
import ScrollableTabs from './ScrollableTabs'
import UseStyles from './UseStyles'
import HideOnScroll from './HideOnScroll'
import DrawerWithLinks from './DrawerWithLinks'
import MainHeader from './MainHeader'
import '../Billboard/Billboard.css'
import './../PostCard/PostCard.css'

const Menus = (props) => {
  const { 
    user,
    isSignedIn,
    routes, 
    container, 
    children, 
    displayName, 
    linkKey,
    parent,
    navHeader,
    onInitializeState,
    onPlatformSelect, 
    argument 
  } = props
  const classes = UseStyles.menusUseStyles()
  const theme = useTheme()
  const [mobileOpen, setMobileOpen] = React.useState(false)

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  return (
    <div className={classes.root}>
      <CssBaseline/>
      <HideOnScroll {...props}>
        <AppBar position="fixed" className={classes.topAppBar}>
          <MainHeader 
            user={user}
            isSignedIn={isSignedIn}
            parent={parent} 
          />
        </AppBar>
      </HideOnScroll>
      {(parent === 'signIn') ? null : (
        <HideOnScroll {...props}>
          <AppBar className={classes.scrollableTabsButtonAuto}>
            <ScrollableTabs
              routes={routes}
              linkKey={linkKey}
              parent={parent}
              argument={argument}
              onTabClick={onPlatformSelect}
              onInitializeState={onInitializeState}
              isSignedIn={isSignedIn}
            >
              {children}
            </ScrollableTabs>
          </AppBar>
        </HideOnScroll>)
      }
      {(parent === 'signIn') ? null : (
      <nav className={classes.drawer}
      >
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            <DrawerWithLinks 
              displayName={displayName} 
              routes={routes} 
              navHeader={navHeader}
              parent={parent}
              argument={argument}
              onPlatformSelect={onPlatformSelect}
            />
          </Drawer>
        </Hidden>
            <Hidden xsDown implementation="css">
              <Drawer
                classes={{ paper: classes.drawerPaper }}
                variant="permanent"
                open
              >
                <DrawerWithLinks
                  displayName={displayName}
                  routes={routes}
                  navHeader={navHeader}
                  parent={parent}
                  argument={argument}
                  onPlatformSelect={onPlatformSelect}
                />
              </Drawer>
            </Hidden>
      </nav>
        )
      }
      <main style={{width:'100%'}}>
        <div className={classes.content}> {children}
        </div>
      </main>
    </div>
  )
}

export default Menus