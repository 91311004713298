import React from 'react';
import { Link } from "react-router-dom"
import Tooltip from '@material-ui/core/Tooltip'

const PlatformIconLinkButton = (props) => {
  const { platform } = props

  const text = `Link to Your ${platform.displayName} Account in Settings to Subscribe`

  return (
    <div className={'mixtape-source-button-2-sub-unsub'}>
        <Link to={`/userSettings/${platform.name}`} style={{ textDecoration: 'none' }}>
            <Tooltip title={text}>
              <platform.icon alt={text} style={{ fontSize: 35 }} />
            </Tooltip>
        </Link>
    </div>
  )
}

export default PlatformIconLinkButton