import React from 'react'
import './VoteModal.css'

import api from '../../functions/api'

import voteOff from '../../assets/1vote-off.svg'
import voteOn from '../../assets/1vote-on.svg'

class VoteModalRow extends React.Component {
  render() {
    const methodName = 'VoteModalRow.render'
    console.log(methodName, '- start')

    const { displayName, isVoted, toggleVote } = this.props
    console.log(methodName, '- isVoted:', isVoted)

    const voteImage = isVoted ? voteOn : voteOff
    
    const title = 'vote'

    return (
      <div className="voteContainer">
        <div>
          <div className="voteIcon">
            <img
              src={voteImage}
              alt={title}
              title={title}
              className={title}
              onClick={()=>toggleVote(this.props)}
            />
          </div>
        </div>
        <div className="electionName">
          {displayName}
        </div>
      </div>
    )
  }
}

export default VoteModalRow