import React from 'react'
import '../Mixtape/Mixtape.css'

const PostCardListPlaceholder = ()=>{
return(
    <div className="MixtapePostcardListWrapperLoader">
        <div className="MixtapePostCardListLoader">
        <div className="MixtapePostCardListBody">
            <div className="MixTapePostCardListList">
            <h5>Gimme a second...</h5>
            </div>
        </div>
        </div>
    </div>
    )
}

export default PostCardListPlaceholder