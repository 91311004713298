import React from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import ReactGA from 'react-ga'

import Menus from '../Menus/Menus'
import MixtapePostCardList from '../Mixtape/MixtapePostCardList'
import PostCardListPlaceholder from '../PostCardList/PostCardListPlaceholder'

import localUtils from '../../functions/localUtils'
import interactionUtils from '../../functions/interactionUtils'
import arrayUtils from '../../functions/arrayUtils'
import enums from '../../enums/enums'
import api from '../../functions/api'

import './OneVoteUserTimeline.css'

class MixtapeSourceTimeline extends React.Component {
  constructor(props) {
    const methodName = 'MixtapeSourceTimeline.constructor'
    console.log(methodName, '- start')
    super(props)
    this.state = {
      posts: [],
      nextStoredPostIndex: parseInt(0),
      storedPostsLength: parseInt(0),
      postsType: "mixtapeSourceTimelinePosts"
    }
  }

  componentDidMount = async () => {
    const methodName = 'MixtapeSourceTimeline.componentDidMount'
    console.log(methodName, '- start')

    await this.storePosts()
    await interactionUtils.getPosts(this.setState.bind(this), this.state)

    ReactGA.pageview('/mixtapeSourceTimeline');
  }

  storePosts = async () => {
    const methodName = 'MixtapeSourceTimeline.storePosts'
    console.log(methodName, '- start')

    const mappedPosts = await this.fetchPosts()
    
    const { postsType } = this.state

    await interactionUtils.setSessionPosts(postsType, mappedPosts)

    this.setState({ storedPostsLength: mappedPosts.length })
  }

  fetchPosts = async () => {
    const methodName = 'MixtapeSourceTimeline.fetchPosts'
    console.log(methodName, '- start')

    const { user, platform, getPlatformCredential } = this.props
    const searchableState = localUtils.getSearchableState()
    const { searchable } = searchableState
    console.log(methodName, '- platform, searchable', platform, searchable)

    const credential = await getPlatformCredential(platform)
    console.log(methodName, '- credential:', credential)
    const timelinePosts = await api.getMixtapeSourceTimeline(platform.id, credential, searchable)
    console.log(methodName, '- timelinePosts:', timelinePosts)

    //might need something better than the line below to determine if it should look voted on
    //--ie is it a video, person, etc. rather than has it been voted on as breaking news

    return interactionUtils.mapPosts(timelinePosts, user)
  }

  onVoteChange = async (electionId, postId, platformProps, isVoted) => {
    const methodName = 'MixtapeSourceTimeline.onVoteChange'
    console.log(methodName, '- electionId, postId, platformProps, isVoted:', electionId, postId, platformProps, isVoted)

    return interactionUtils.onVoteChange(this.setState.bind(this), postId, platformProps, this.state, isVoted)
  }

  render() {
    const methodName = 'MixtapeSourceTimeline.render'
    console.log(methodName, '- start')

    const searchableState = localUtils.getSearchableState()
    const {searchable} = searchableState
    console.log(methodName, '- searchable from local: ', searchable)
    console.log(methodName, '- fuck:', searchable, this.props.searchableState.searchable)
    const { source } = this.props.match.params
    const title = !!searchable ? searchable.title : source
    const { posts, storedPostsLength, nextStoredPostIndex } = this.state
    const { user, platform, refreshActiveVotes, toggleVoteModal, subscribe, isSignedIn, unsubscribe} = this.props

    const election = arrayUtils.getByField(user.elections, 'id', 1, true)

    return (
      <div>
      <Menus
        {...this.props}
        user={user}
        source={source}
        displayName={null} 
        routes={[]} 
        navHeader={`${title} Recent ${platform.displayName} Content`}
        parent={'mixtapeSourceTimeline'}
        argument={null}
      >
        <div className="MixtapeSourceTimelineBody" style={{marginTop:'120px'}}>
          {posts.length > 0
            ?
            (
              <div className="MixtapeSourceTimelinePostcardListWrapper">
                <div className="ProfilePostCardList">
                  <InfiniteScroll
                    dataLength={posts.length}
                    next={()=>interactionUtils.getPosts(this.setState.bind(this), this.state)}
                    hasMore={(storedPostsLength > nextStoredPostIndex)}
                    // endMessage={<h5>You ran out of posts! Refresh for more recent posts.</h5>}
                  >
                    <MixtapePostCardList
                      {...this.props}
                      postCardListType={enums.POST_CARD_LIST_TYPE.MIXTAPE_SOURCE_TIMELINE}
                      election={election}
                      posts={posts}
                      onVoteChange={this.onVoteChange}
                      filteredPlatformId={0}
                    />
                  </InfiniteScroll>
                </div>

              </div>)
            :
            <PostCardListPlaceholder />
          }
        </div>
      </Menus>
      </div>

    )
  }
}

export default MixtapeSourceTimeline
