import React, { Component } from 'react'
import ReactGA from 'react-ga'
import Img from 'react-image'

import PostCardList from '../PostCardList/PostCardList'
import api from '../../functions/api'
import enums from '../../enums/enums'

import './Billboard.css'
import voteIcon from '../../assets/1vote-icon.svg'

class BillboardBody extends Component {
  constructor(props) {
    const methodName = 'BillboardBody.constructor'
    console.log(methodName, '- start')
    // console.log(methodName, '- props:', props)
    super(props)
    this.state = {
      user: {}
    }
  }

  sortFuncBillboard = (a, b) => {
    const methodName = 'BillboardBody.sortFuncBillboard'
    console.log(methodName, '- start')

    if (b.voteCount === a.voteCount) {
      if (b.postIsVoted || a.postIsVoted) {
        return b.postIsVoted - a.postIsVoted
      } else {
        return b.date - a.date
      }
    } else {
      return b.voteCount - a.voteCount
    }
  }

  sortFuncWinners = (a, b) => {
    const methodName = 'BillboardBody.sortFuncWinners'
    console.log(methodName, '- start')

    return a.electionId - b.electionId
  }

  getElectionPosts = async () => {
    const methodName = 'BillboardBody.getElectionPosts'
    console.log(methodName, '- start')
    const { election, user } = this.props
    // const user = await localUtils.getUser()

    console.log(methodName, '- election:', election, user)

    const electionId = election.id
    console.log(methodName, '- electionId:', electionId)

    const data = await api.getLeaderboard(electionId)
    console.log(methodName, '- data:', data)

    const votedPost = user.activeVotes[electionId]
    console.log(methodName, '- votedPost:', votedPost)

    const votedPostId = votedPost ? votedPost.postId : null
    console.log(methodName, '- votedPostId:', votedPostId)

    return data.map((post) => {
      const { platformProps } = post
      /*this line is really just for posts added before we added date to platformProps
      as well as for twitter posts, until we refactor the posts tables to break out twitter 
      and add dates for twitter posts*/
      const sortDate = platformProps.date ? new Date(post.platformProps.date) : 0
      return {
        ...post,
        date: sortDate,
        postIsVoted: votedPostId === post.postId,
      }
    })
  }

  getElectionWinnersPosts = async () => {
    const methodName = 'BillboardBody.getElectionWinnerPosts'
    console.log(methodName, '- start')

    const { election, user } = this.props
    console.log(methodName, '- election, user:', election, user)

    const electionTypeId = election.id
    console.log(methodName, '- electionTypeId:', electionTypeId)

    const data = await api.getElectionWinners(electionTypeId)
    console.log(methodName, '- data:', data)

    return data.map((post) => {
      const { platformProps, electionId } = post
      console.log(methodName, '- electionId:', electionId)
      
      const usersElectionVote = user.activeVotes[electionId]
      console.log(methodName, '- usersElectionVote:', usersElectionVote)

      const userVotedInElection = !!usersElectionVote
      console.log(methodName, '- userVotedInElection:', userVotedInElection)
      
      const votedPostId = userVotedInElection ? usersElectionVote.postId : null
      console.log(methodName, '- votedPostId:', votedPostId)
      
      const isVoted = post.postId === votedPostId
      console.log(methodName, '- isVoted:', isVoted)
      /*this line is really just for posts added before we added date to platformProps
        as well as for twitter posts, until we refactor the posts tables to break out twitter 
        and add dates for twitter posts*/
      const sortDate = platformProps.date ? new Date(post.platformProps.date) : 0
      return {
        ...post,
        date: sortDate,
        postIsVoted: isVoted,
      }
    })
  }

  render() {
    const methodName = 'BillboardBody.render'
    console.log(methodName, '- start')
    // console.log(methodName, this.props)
    const {
      election,
    } = this.props
    //update for election v electionType
    let reactGaPath; 
    let postCardListType;
    if(election.electionTypeId>=0){
      postCardListType = enums.POST_CARD_LIST_TYPE.BILLBOARD
      console.log(methodName, 'postCardListType, election', postCardListType, election)
    } else {
      postCardListType = enums.POST_CARD_LIST_TYPE.WINNERS
      console.log(methodName, 'postCardListType, election', postCardListType, election)
    }
    let sortFunc;
    let getPosts;
    switch(postCardListType){
      case enums.POST_CARD_LIST_TYPE.BILLBOARD:
        sortFunc = this.sortFuncBillboard
        getPosts = this.getElectionPosts
        reactGaPath = `${election.electionTypeName}/${election.name}`
        break;
      case enums.POST_CARD_LIST_TYPE.WINNERS:
        sortFunc = this.sortFuncWinners
        getPosts = this.getElectionWinnersPosts
        reactGaPath = `${election.name}`
        break;  
    } 
    ReactGA.pageview(`/home/${reactGaPath}`);
    return (
      <div className="billboardContent">
        {
          postCardListType === enums.POST_CARD_LIST_TYPE.WINNERS
            ?
            <div className="elections-winners-header">
              {`${election.displayName} Elections`}
            </div>
            :
            <div className="elections-winners-header">
              {election.displayName}
              <Img src={voteIcon} height='35px' style={{ marginLeft: '5px' }} />
            </div>
        }
        <hr />
        <div className="postCardList">
          <PostCardList
            {...this.props}
            postCardListType={postCardListType}
            getPosts={getPosts}
            sortFunc={sortFunc}
            loadingMessage={`Coming Soon!`}
          />
        </div>
      </div>
    )
  }
}

export default BillboardBody

