import React from 'react'
import Img from 'react-image'

import btn_reddit_signin from '../../../../../assets/btn_reddit_signin2.svg'
import btn_reddit_link from '../../../../../assets/btn_reddit_link.svg'
import redditUtils from '../../../../../functions/redditUtils'

const RedditAuth = (props) => {
  const methodName = 'RedditAuth.render'
  console.log(methodName, '- props:', props)

  const { src } = props
  return (
    <Img
      className='sign-in-button-image'
      value="reddit"
      onClick={redditUtils.getRedditAuthorization}
      src={src==="link" ? btn_reddit_link : btn_reddit_signin}
      alt="Reddit link button"
    />
  )
}

export default RedditAuth