import React from 'react'
import { Redirect } from 'react-router-dom'

import localUtils from '../../functions/localUtils'
import youtubeUtils from '../../functions/youtubeUtils'
import api from '../../functions/api'
import enums from '../../enums/enums'

const DeleteUserComponent = (props) => {
    const methodName = 'DeleteUserComponent.render'
    console.log(methodName, '- props:', props)
    
    let credentials = localUtils.getCredentials()
    console.log(methodName, '- credentials (1):', credentials)
  
    if(credentials[enums.PLATFORMS.YOUTUBE.name]){
      youtubeUtils.revokeYoutubeToken(credentials.youtube.access_token)
    }
  
    api.deleteUser(props.userId)
    props.onInitializeState()
    return (
      <Redirect to={{ pathname: '/signIn' }}/>
    )
}

export default DeleteUserComponent