import React from 'react'

const TitleAndDescription = (props) =>{

    const {
        linkUrl,
        datetimePublished,
        processedNewsTitle,
        postTitle,
        description,
        processedNewsDescription
    } = props

    return (
        <div>
             {/* TITLE */}
       
            <div className="post-title">
                <a
                    href={linkUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {!!processedNewsTitle ? processedNewsTitle : postTitle}
                </a>
            </div>
            <a
                href={linkUrl}
                target="_blank"
                rel="noopener noreferrer"
            >
                <div className="post-published-date">
                    {datetimePublished}
                </div>
            </a>
            {/* TEXT */}
            <a
                href={linkUrl}
                target="_blank"
                rel="noopener noreferrer"
            >
            <div className="post-description">
                {processedNewsDescription ? processedNewsDescription: description}
            </div>
            </a>
        </div>
    )
}
export default TitleAndDescription
