import React from 'react'
import Img from 'react-image'
import { Link, useLocation } from "react-router-dom"

import NavigationIcon from './NavigationIcon';
import Search from '../Search/Search'
import { DesktopAppBar } from './AppBars'
import enums from '../../enums/enums'

import Button from '@material-ui/core/Button'
import logo from '../../assets/1vote-logo.svg'
import './NavigationBar.css'

const NavigationBar = ({ 
  onInitializeState, 
  isSignedIn, 
  toggleModal, 
  user, 
  handleKeyPress, 
  setCredential, 
  getYoutubeCredential
 }) => {
  const location = useLocation()
  const isSignInPage = (location.pathname==='/'||location.pathname==='/signIn')
  return (
    <DesktopAppBar>
      <header className="navigationBar" style={{ justifyContent: 'space-between' }}>
        <div style={{ height: '60px', marginLeft: '15px' }}>
          <Link 
            to={`/home/${enums.DEFAULT_ELECTION_TYPES[0].name}`}
            style={{ textDecoration: 'none', className: 'billboardNav' }}
          >
            <div style={{ width: '20%' }}></div>
            <div><Img
              src={[logo]}
              height="60px"
              alt={'1vote logo'}
            /></div>
          </Link>
        </div>
        {(isSignedIn) ? (
            <nav>
              <Search
                user={user}
                handleKeyPress={handleKeyPress}
                setCredential={setCredential}
                getYoutubeCredential={getYoutubeCredential}
              />
              <NavigationIcon
                onInitializeState={onInitializeState}
                toggleModal={toggleModal}
                user={user}
                isSignedIn={isSignedIn}
              />
            </nav>)
          : isSignInPage
            ? (<nav>
              <Link 
            to={`/home/${enums.DEFAULT_ELECTION_TYPES[0].name}`}
            >
                <Button variant="contained" color="primary">
                  Explore
                </Button>
              </Link>
            </nav>)
            : (<nav>
              <Link to={"/signIn"}>
                <Button id="navBarSignIn" variant="contained" color="primary">
                  Sign In
                </Button>
              </Link>
              <NavigationIcon
                onInitializeState={onInitializeState}
                toggleModal={toggleModal}
                user={user}
                isSignedIn={isSignedIn}
              />
            </nav>)
        }
      </header>
    </DesktopAppBar>
  );
}

export default NavigationBar
