import React from 'react'
import { DateTime } from 'luxon'

import YoutubePostCard from './PlatformPostCards/YoutubePostCard'
import RedditPostCard from './PlatformPostCards/RedditPostCard'
import TwitterPostCard from './PlatformPostCards/TwitterPostCard'
import NewsPostCard from './PlatformPostCards/NewsPostCard'
import PlaceholderCard from './PlaceholderCard'

import newsSources from '../Settings/PlatformSettings/NewsSettings/newsSources'

import './PostCard.css'
import enums from '../../enums/enums'
import api from '../../functions/api'
import localUtils from '../../functions/localUtils'

const formatDatetimePublished = (date=null) =>{
  if(date){
    return DateTime.fromJSDate(new Date(date)).toLocaleString(DateTime.DATETIME_FULL) 
  } else {
    return''
  }
}

class PostCard extends React.Component {
  constructor(props){
    super(props)
    this.state={
      mixtapeSourceId: '',
      mixtapeSourceObj: {},
      platform: {}
    }
  }

 shouldComponentUpdate(nextProps, nextState) {
    return (
      nextProps.postIsVoted!==this.props.postIsVoted
      ||nextProps.userIsSubscribed!==this.props.userIsSubscribed
      ||nextState.mixtapeSourceId !==this.state.mixtapeSourceId
      ||nextState.mixtapeSourceObj !==this.state.mixtapeSourceObj
    )
  }

  getMixtapeSourceAndPlatform=async(platformId, platformProps)=>{
    let mixtapeSourceId
    let mixtapeSourceObj
    let platform
    switch(platformId){
      case 0: 
        platform=null
        break
      case 1:
        const { twitterPostId, twitterPlatformUserId: idStr } = this.props.platformProps
        platform = enums.PLATFORMS.TWITTER
        try{
          const credential =  localUtils.getTwitterCredential()
          const data = await api.getTweetInfo(twitterPostId, credential)
          mixtapeSourceId=data.user.screen_name
          mixtapeSourceObj={ idStr, mixtapeSourceId }
        }catch(err){
          console.error(err)
        }
        break 
      case 2:
        platform = enums.PLATFORMS.NEWS
        break
      case 3:
        platform = enums.PLATFORMS.YOUTUBE
        mixtapeSourceId=platformProps.youtubeChannelId
        mixtapeSourceObj= { mixtapeSourceId: platformProps.youtubeChannelId }
        break
      case 4:
        platform=enums.PLATFORMS.REDDIT
        mixtapeSourceId=platformProps.subreddit
        mixtapeSourceObj= { mixtapeSourceId: platformProps.subreddit }
        break
      default:
        break
    }
    this.setState({
      mixtapeSourceId: mixtapeSourceId, 
      mixtapeSourceObj: mixtapeSourceObj, 
      platform: platform
    })
  }

  componentDidMount=async()=>{
    const { platformId, platformProps } = this.props
    await this.getMixtapeSourceAndPlatform(platformId, platformProps)
  }

  render() {
    const methodName = 'PostCard.render'
    console.log(methodName, this.props, this.state)
    const { platformId, user, platformProps, subscribe, unsubscribe } = this.props
    const { mixtapeSourceId, mixtapeSourceObj, platform }=this.state
    const searchableType = enums.getSearchableTypeByPlatformId(platformId)
    const mixtapeSources = !platform ? null : (!!user.platforms[platform.name] ? user.platforms[platform.name].mixtapeSources : '')
    const mixtapeSource = !platform ? null : platformId===2 ? newsSources.allNewsSourcesObj[platformProps.newsSourceId] : (mixtapeSources[mixtapeSourceId] || mixtapeSourceObj)
    const onSubscribe = () => subscribe(mixtapeSource, searchableType)
    const onUnsubscribe = () => unsubscribe(mixtapeSource, searchableType)

    switch (platformId) {
      case 0:
        return <PlaceholderCard {...this.props} />
      case 1:
        return <TwitterPostCard 
                  {...this.props} 
                  searchableType={searchableType}
                  onSubscribe={onSubscribe}
                  onUnsubscribe={onUnsubscribe}
                  formatDatetimePublished={formatDatetimePublished}             
              />
      case 2:
        return <NewsPostCard 
                  {...this.props} 
                  searchableType={searchableType}
                  onSubscribe={onSubscribe}
                  onUnsubscribe={onUnsubscribe}
                  formatDatetimePublished={formatDatetimePublished}             
              />
      case 3:
        return <YoutubePostCard 
                  {...this.props} 
                  searchableType={searchableType}
                  onSubscribe={onSubscribe}
                  onUnsubscribe={onUnsubscribe}
                  formatDatetimePublished={formatDatetimePublished}
                />
      case 4:
        return <RedditPostCard 
                  {...this.props} 
                  searchableType={searchableType}
                  onSubscribe={onSubscribe}
                  onUnsubscribe={onUnsubscribe}   
                  formatDatetimePublished={formatDatetimePublished}             
                />
      default:
        return
    }
  }
}

export default PostCard
