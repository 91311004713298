import React from 'react'
import LazyLoad from 'react-lazyload'

import voteOff from '../../assets/1vote-off.svg'
import voteOn from '../../assets/1vote-on.svg'
import '../PostCard/PostCard.css'

const Vote = ({props}) => {
    const methodName = 'Vote'
    const {voteCount, postIsVoted, toggleVoteModal, toggleVote, user} = props
    const onVote=props.electionId && !!user.id ? toggleVote : toggleVoteModal
    console.log(methodName, '- onVote:', onVote)

    return(
      <div className="post-vote-container">
        <div className="post-vote">
          <span className="post-vote-count">{voteCount}</span>
          <LazyLoad height={20} offset={100} once={true}>
            <img
              src={postIsVoted ? voteOn : voteOff}
              alt="vote"
              className="interaction vote"
              onClick={() => onVote(props)}
            />
          </LazyLoad>
        </div>
      </div>
    )
  }

  export default Vote