import ReactGA from 'react-ga'

const REDDIT_URL = 'https://www.reddit.com'
const ABOUT = 'about.json'

const getRedditAuthorizationUrl = () => {
  const methodName = 'functions.redditUtils.getRedditAuthorizationUrl'
  console.log(methodName)

  const oneVoteUrl = process.env.REACT_APP_ONEVOTE_URL
  const redirectPath = '/authHandler/reddit'
  const redirectUrl = oneVoteUrl + redirectPath

  const options = {
    clientId: process.env.REACT_APP_REDDIT_APP_CLIENT_ID,
    responseType: 'code',
    state: 'reddit',
    redirectUrl: redirectUrl,
    duration: 'permanent',
    scopes: 'read%20identity%20wikiread%20wikiedit%20mysubreddits%20subscribe',
  }

  const redditUrl = 'https://www.reddit.com/api/v1/authorize'
    + '?client_id=' + options.clientId
    + '&response_type=' + options.responseType
    + '&state=' + options.state
    + '&redirect_uri=' + options.redirectUrl
    + '&duration=' + options.duration
    + '&scope=' + options.scopes
  console.log(methodName, '- redditUrl:', redditUrl)

  return redditUrl
}

const getRedditAuthorization = async () => {
  const methodName = 'functions.redditUtils.getRedditAuthorization'
  console.log(methodName)

  console.log(methodName, '- redditClientId:', process.env.REACT_APP_REDDIT_APP_CLIENT_ID)
  console.log(methodName, '- oneVoteUrl:', process.env.REACT_APP_ONEVOTE_URL)

  const authUrl = getRedditAuthorizationUrl()
  console.log(methodName, '- authUrl:', authUrl)

  ReactGA.pageview('/authHandler/reddit')

  try {
    window.open(authUrl, "_self")
  }
  catch (error) {
    console.error('error getting authorization from reddit:', error.message)
  }
}

const getRedditPostInfoUrl = (permalink, asJson = false) => {
  const methodName = 'functions.redditUtils.getRedditPostInfoUrl'
  console.log(methodName, '- permalink, asJson:', permalink, asJson)

  const postUrl = `${REDDIT_URL}${permalink}`

  if (asJson) {
    return `${postUrl}${ABOUT}`
  }
  return postUrl
}

const getRedditPostInfo = async (permalink) => {
  const methodName = 'functions.redditUtils.getRedditPostInfo'
  console.log(methodName, '- permalink:', permalink)

  const url = getRedditPostInfoUrl(permalink, true)
  console.log(methodName, '- url:', url)

  const response = await fetch(url)
  console.log(methodName, '- response:', response)

  const rawData = await response.json()
  console.log(methodName, '- rawData:', rawData)

  const data = rawData[0].data.children[0].data
  console.log(methodName, '- data:', data)

  return data
}

const getRedditUserInfoUrl = (username, asJson = false) => {
  const methodName = 'functions.redditUtils.getRedditUserInfoUrl'
  console.log(methodName, '- username, asJson:', username, asJson)

  const userUrl = `${REDDIT_URL}/user/${username}/`
  console.log(methodName, '- userUrl:', userUrl)

  if (asJson) {
    return `${userUrl}${ABOUT}`
  }
  return userUrl
}

const getRedditUserInfo = async (username) => {
  const methodName = 'functions.redditUtils.getRedditUserInfo'
  console.log(methodName, '- username:', username)

  const url = getRedditUserInfoUrl(username, true)
  console.log(methodName, '- url:', url)

  const response = await fetch(url)
  console.log(methodName, "- response:", response)

  const rawData = await response.json()
  console.log(methodName, '- rawData:', rawData)

  const data = rawData.data
  console.log(methodName, '- data:', data)

  return data
}

const getRedditSubredditInfoUrl = (subreddit, asJson = false) => {
  const methodName = 'functions.redditUtils.getRedditSubredditInfoUrl'
  console.log(methodName, '- subreddit, asJson:', subreddit, asJson)

  const subredditUrl = `${REDDIT_URL}/r/${subreddit}/`
  console.log(methodName, '- subredditUrl:', subredditUrl)

  if (asJson) {
    return `${subredditUrl}${ABOUT}`
  }
  return subredditUrl
}

const getRedditSubredditInfo = async (subreddit) => {
  const methodName = 'functions.redditUtils.getRedditSubredditInfo'
  console.log(methodName, '- subreddit:', subreddit)

  const url = getRedditSubredditInfoUrl(subreddit, true)
  console.log(methodName, '- url:', url)

  const response = await fetch(url)
  console.log(methodName, "- response:", response)

  const rawData = await response.json()
  console.log(methodName, '- rawData:', rawData)

  const data = rawData.data
  console.log(methodName, '- data:', data)

  return data
}

export default {
  getRedditPostInfoUrl,
  getRedditPostInfo,
  getRedditUserInfoUrl,
  getRedditUserInfo,
  getRedditSubredditInfoUrl,
  getRedditSubredditInfo,
  getRedditAuthorization,
}