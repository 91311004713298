import React from 'react'
import useScrollTrigger from '@material-ui/core/useScrollTrigger'
import Slide from '@material-ui/core/Slide'

export default function HideOnScroll(props) {
    const { children } = props
  
    return (
      <Slide appear={false} direction="down" in={!useScrollTrigger()}>
        {children}
      </Slide>
    )
  }