import { makeStyles, createStyles } from '@material-ui/core/styles'

const drawerWidth = '240px'

const menusUseStyles = makeStyles((theme) =>
createStyles({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    position: 'fixed',
    top: 'auto',
    bottom: '0px',
    height: '60px',
    background: '#303f9f',
    zIndex: '4',
    //breakpoints.up means above (exclusive), .down means below (exclusive)
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  scrollableTabsButtonAuto: {
    position: 'fixed',
    top: '60px',
    left: '0px',
    zIndex: '4',
    //breakpoints.up means above (exclusive), .down means below (exclusive)
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  secondAppBar: {
    position: 'fixed',
    top: 'auto',
    bottom: '60px',
    height: '60px',
    width: '160px',
    left: '0px',
    background: '#9fa8da',
    zIndex: '4',
    //breakpoints.up means above (exclusive), .down means below (exclusive)
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  thirdAppBar: {
    position: 'fixed',
    top: '120px',
    height: '60px',
    zIndex: '4',
    //breakpoints.up means above (exclusive), .down means below (exclusive)
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  topAppBar: {
    position: 'fixed',
    top: '0px',
    height: '60px',
    backgroundColor: theme.palette.background.paper,
    zIndex: '11',
    //breakpoints.up means above (exclusive), .down means below (exclusive)
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  }, 
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    position: 'absolute',
    top: '0px',
    width: drawerWidth,
    [theme.breakpoints.up('sm')]: {
      position: 'fixed',
      top: '20px',
      zIndex: 3,
    }
  },
  content: {
    flexGrow: 0,
    marginTop: '0px',
    padding: theme.spacing(0),
    [theme.breakpoints.down('md')]: {}
  },
}),
)

const scrollableTabsUseStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
  },
}))

export default {
  menusUseStyles,
  scrollableTabsUseStyles
}