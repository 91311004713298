import React, { Component } from 'react'
import ReactGA from 'react-ga'
import { BrowserRouter as Router, Redirect, Route, Switch, } from 'react-router-dom'

import DeleteUserComponent from '../Components/DeleteUserComponent/DeleteUserComponent'
import SignIn from '../Components/SignIn/SignIn'
import NavigationBar from '../Components/Navigation/NavigationBar'
import MobileNavigationBar from '../Components/Navigation/MobileNavigationBar'
import OneVoteUserTimeline from '../Components/PlatformUserTimeline/OneVoteUserTimeline'
import Billboard from '../Components/Billboard/Billboard'
import Mixtape from '../Components/Mixtape/Mixtape'
import Modal from '../Components/Modal/Modal'
import VoteModal from '../Components/VoteModal/VoteModal'
import Settings from '../Components/Settings/Settings'
import SearchMobile from '../Components/Search/SearchMobile'
import Footer from '../Components/Footer/Footer'
import PrivacyPolicy from '../Components/PrivacyPolicy/PrivacyPolicy'
import MixtapeSourceTimeline from '../Components/PlatformUserTimeline/MixtapeSourceTimeline'
import AuthHandler from '../Components/AuthHandler/AuthHandler'
import { ScrollToTop } from '../Components/Scroll/ScrollToLocation'

import api from '../functions/api'
import localUtils from '../functions/localUtils'
import youtubeUtils from '../functions/youtubeUtils'
import copy from '../functions/copy'
import googleanalyticsUtils from '../functions/googleanalyticsUtils'

import enums from '../enums/enums'

import './App.css'


const urlParams = new URLSearchParams(window.location.search)

//GA attribution
const campaignSource = urlParams.get('utm_source')
const campaignMedium = urlParams.get('utm_medium')
const campaignContent = urlParams.get('utm_content')
const campaignId = urlParams.get('utm_campaign')

// import AppContext from '../context/AppContext'
//comment

const initialState = {
  isSignedIn: false,
  redirectToReferrer: false,
  isVoteModalOpen: false,
  voteModalState: {
    postProps: {},
  },
  searchableState: {
    searchableType: {},
    searchable: {},
  },
  user: {
    createdAt: new Date(),
    name: '',
    displayName: '',
    description: '',
    imageUrl: '',
    location: '',
    credentials: {},
    platforms: enums.INITIAL_PLATFORMS,
    activeVotes: {},
    elections: enums.DEFAULT_ELECTIONS,
  },
}

class App extends Component {
  constructor(props) {
    const methodName = 'App.constructor'
    console.log(methodName, '- props: ', props)

    super(props)
    this.state = initialState
    console.log(methodName, '- state: ', this.state)

    if (!(localUtils.getUser()))
    {
    this.setUser(initialState.user)
    localUtils.setUser(initialState.user)
    }

  }

  setUser = async (user, save) => {
    const methodName = 'App.setUser'
    console.log(methodName, '- user, save:', user, save)

    try {

      if (save) {
        console.log(methodName, '- updating user in postgres')
        const credentials = localUtils.getCredentials()

        const data = await api.updateUserData(user, credentials)
        console.log(methodName, '- data', data)
      }

      this.setState({
        user: user,
        isSignedIn: !!user,
        redirectToReferrer: !!user,
      }, async () => {
        console.log(methodName, '- setState complete:', this.state)

        console.log(methodName, '- updating user in local storage')
        localUtils.setUser(this.state.user)
      })

      return true
    }
    catch (e) {
      console.error(methodName, '- error creating user:', e)
      return false
    }
  }

  setCredential = async (platform, credential) => {
    const methodName = 'App.setCredential'
    console.log(methodName, '- platform, credential:', platform, credential)

    let credentials = localUtils.getCredentials()
    console.log(methodName, '- credentials (before):', credentials)
    credentials[platform] = credential
    console.log(methodName, '- credentials (after):', credentials)

    console.log(methodName, '- updating credentials in local storage')
    localUtils.setCredentials(credentials)

    console.log(methodName, '- updating credentials in postgres')
    const data = await api.updateUserData(this.state.user, credentials)
    console.log(methodName, '- data', data)

    return data
  }

  setUserAndCredentials = async (user, credentials, save) => {
    const methodName = 'App.setUserAndCredentials'
    console.log(methodName, '- user, credentials, save:', user, credentials, save)

    if (!user) {
      console.error(methodName, '- no user!')
      throw Error('No User')
    }
    if (!credentials) {
      console.error(methodName, '- no credentials!')
      throw Error('No Credentials')
    }

    this.setState({
      user: user,
      isSignedIn: !!user,
      redirectToReferrer: !!user,
    }, async () => {
      console.log(methodName, '- setState complete:', this.state)

      if (!this.state.user) {
        console.error(methodName, '- no this.state.user!')
        throw Error('No this.state.user')
      }

      console.log(methodName, '- updating user/credentials in local storage')
      localUtils.setUser(this.state.user)
      localUtils.setCredentials(credentials)

      if (save) {
        console.log(methodName, '- updating user in postgres')
        const data = await api.updateUserData(this.state.user, credentials)
        console.log(methodName, '- data', data)
      }
    })
  }
  
  unlinkPlatform = async (platform) => {
    const methodName = 'App.unlinkPlatform'
    console.log(methodName, '- platform:', platform)

    let user = copy.copyObject(this.state.user)
    console.log(methodName, '- user (1):', user)
    delete user.platforms[platform.name]
    console.log(methodName, '- user (2):', user)

    let credentials = await localUtils.getCredentials()
    console.log(methodName, '- credentials (1):', credentials)

    if(platform.name===enums.PLATFORMS.YOUTUBE.name){
      youtubeUtils.revokeYoutubeToken(credentials.youtube.access_token)
    }

    delete credentials[platform.name]
    console.log(methodName, '- credentials (2):', credentials)

    const data = await this.setUserAndCredentials(user, credentials, true)
    console.log(methodName, '- data:', data)
  }

  refreshUserData = async (user, credentials) => {
    const methodName = 'App.refreshUserData'
    console.log(methodName, '- user, credentials:', user, credentials)

    const { platforms, id } = user
    let builtUser = copy.copyObject(user)
    console.log(methodName, '- builtUser: ', builtUser)

    let electionTypeIds = enums.DEFAULT_ELECTION_TYPES.map(electionType=>electionType.id)
    let promises = [
      this.getPlatforms(platforms, credentials),
      api.getActiveVotes(id),
      api.getElections(electionTypeIds),
    ]
    console.log(methodName, '- promises: ', promises)
    
    const results = await Promise.all(promises)
    console.log(methodName, '- results: ', results)
    
    builtUser.platforms = results[0]
    console.log(methodName, '- platforms:', builtUser.platforms)

    builtUser.activeVotes = results[1]
    console.log(methodName, '- activeVotes:', builtUser.activeVotes)

    let elections = results[2]
    console.log(methodName, '- elections:', elections)

    elections.sort((a, b) => a.id - b.id)
    console.log(methodName, '- elections (sorted):', elections)
    builtUser.elections = elections
    this.setUser(builtUser, true)
  }

  getPlatforms = async (platforms, credentials) => {
    const methodName = 'App.getPlatforms'
    console.log(methodName, '- platforms, credentials:', platforms, credentials)

    let newPlatforms = copy.copyObject(platforms)
    let promises = []
    let o = {}
    let i = 0

    if (('twitter' in platforms) && ('twitter' in credentials)) {
      console.log(methodName, '- adding twitter promise')
      promises.push(api.getUsersMixtapeSources(enums.PLATFORMS.TWITTER.id, credentials.twitter))
      o[i] = 'twitter'
      i = i + 1
    }

    if (('youtube' in platforms) && ('youtube' in credentials)) {
      console.log(methodName, '- adding youtube promise')
      const youtubeCredential = await this.getYoutubeCredential()
      promises.push(api.getUsersMixtapeSources(enums.PLATFORMS.YOUTUBE.id, youtubeCredential))
      o[i] = 'youtube'
      i = i + 1
    }

    if (('reddit' in platforms) && ('reddit' in credentials)) {
      console.log(methodName, '- adding reddit promise')
      promises.push(api.getUsersMixtapeSources(enums.PLATFORMS.REDDIT.id, credentials.reddit))
      o[i] = 'reddit'
    }

    const mixtapeSourcesObjects = await Promise.all(promises)
    console.log(methodName, '- mixtapeSourcesObjects:', mixtapeSourcesObjects)

    mixtapeSourcesObjects.forEach((mixtapeSourcesObject, j) => {
      newPlatforms[o[j]].mixtapeSources = mixtapeSourcesObject
    })

    return newPlatforms
  }

  refreshActiveVotes = async () => {
    const methodName = 'App.refreshActiveVotes'
    console.log(methodName, '- this.props:', this.props)

    const activeVotes = await api.getActiveVotes(this.state.user.id)
    console.log(methodName, '- activeVotes', activeVotes)
    let user = copy.copyObject(this.state.user)
    console.log(methodName, '- user', user)
    user.activeVotes = activeVotes

    this.setUser(user, true)
  }

  updateActiveVotes = (electionId, data) => {
    const methodName = 'App.updateActiveVotes'
    console.log(methodName, '- electionId, data:', electionId, data)

    console.log(methodName, '- calling setState...')
    this.setState(prevState => {
      // const prevActiveVotes = prevState.user.activeVotes
      if (!data) {
        console.log(methodName, '- un-voting')
        const { [electionId]: oldVote, ...rest } = prevState.user.activeVotes
        return {
          user: {
            ...prevState.user,
            activeVotes: rest,
          }
        }
      } else {
        console.log(methodName, '- voting')
        return {
          user: {
            ...prevState.user,
            activeVotes: {
              ...prevState.user.activeVotes,
              [electionId]: data
            },
          }
        }
      }
    }, () => {
      console.log(methodName, '- setState complete.')
      localUtils.setUser(this.state.user)
    })
  }

  getYoutubeCredential = async () => {
    const methodName = 'App.getYoutubeCredential'
    console.log(methodName)

    const credential = localUtils.getYoutubeCredential()
    console.log(methodName, '- credential:', credential)

    if (!credential) {
      console.error(methodName, 'credential null')
      return credential
    }

    const expiryDate = new Date(credential.expiry_date)
    const now = new Date()
    console.log(methodName, '- now, expiryDate:', now, expiryDate)
    //could skip following logic and just refresh youtube credential anytime we need it
    if (now < expiryDate) {
      console.log(methodName, '- tokens still fresh, returning:', credential)
      return credential
    }
    console.log(methodName, '- access token expired, refreshing...')

    let newCredential = await api.refreshYoutubeCredential(credential)
    console.log(methodName, '- newCredential:', newCredential)
    const data = await this.setCredential('youtube', newCredential)
    console.log(methodName, '- data:', data)

    return newCredential
  }

  setMixtapeSourcesState = (platformName, mixtapeSources) => {
    const methodName = 'App.setMixtapeSourcesState'
    console.log(methodName, '- mixtapeSources:', mixtapeSources)

    let user = copy.copyObject(this.state.user)
    if (user.platforms[platformName]) {
      user.platforms[platformName].mixtapeSources = mixtapeSources
    } else {
      user.platforms[platformName] = { mixtapeSources }
    }
    console.log(methodName, '- (new) user', user)

    this.setUser(user, true)
  }

  setPlatformState = async (platformName, platform) => {
    const methodName = 'App.setPlatformState'
    console.log(methodName, '- platform:', platform)

    let user = copy.copyObject(this.state.user)
    user.platforms[platformName] = platform

    this.setUser(user, true)
  }

  setSearchableState = (searchableState, cb) => {
    const methodName = 'App.setSearchableState'
    console.log(methodName, '- searchableState:', searchableState)

    localUtils.setSearchableState(searchableState)

    this.setState({ searchableState: searchableState }, () => {
      console.log(methodName, '- setState complete, calling callback')
      if (cb) {
        cb()
      }
    })
  }

  componentDidMount = async () => {
    const methodName = 'App.componentDidMount'
    console.log(methodName)

    const localUser = localUtils.getUser()
    console.log(methodName, '- user (from local)', localUser)

    const searchableState = localUtils.getSearchableState()
    console.log(methodName, '- searchableState (from local)', searchableState)

    //store any google anlaytics tracking and create temp userId
    if (campaignSource || campaignMedium || campaignContent || campaignId) {
      localUtils.setCampaignAttribution({
        userId: new Date().getTime(),
        campaignSource: campaignSource,
        campaignMedium: campaignMedium,
        campaignContent: campaignContent,
        campaignId: campaignId,
      })
    }

    googleanalyticsUtils.initializeGoogleAnalytics()
    //end google analytics

    if (!localUser || !('id' in localUser)) {
      return
    }

    this.setSearchableState(searchableState)
    this.setUser(localUser, false)
  }

  handleKeyPress = (event, cb) => {
    if (event.key === 'Enter') {
      cb()
    }
  }

  PrivateRoute = ({ render, ...rest }) => {
    console.log('PrivateRoute', rest)
    console.log('isSignedIn:', this.state.isSignedIn)
    return (<Route {...rest} render={(props) => (
      this.state.isSignedIn === true
        ? render(props)
        : <Redirect to={{
          pathname: '/',
          state: { from: props.location },
        }}/>
    )}/>)
  }

  onInitializeState = () => {
    localUtils.clearLocalStorage()
    window.sessionStorage.clear()
    this.setState(initialState)
  }

  toggleVoteModal = async (postProps) => {
    const methodName = 'App.toggleVoteModal'
 
    //google analytics
    ReactGA.event({
      category: 'user',
      action: 'toggle modal',
    })
    //end google analytics

    this.setState(prevState => ({
      isVoteModalOpen: !prevState.isVoteModalOpen,
      voteModalState: {
        postProps: postProps
      }
    }))
  }

  toggleVote = async ({
    voteModalState,
    user, 
    isVoted, 
    election, 
    updatePostProps,
    updateActiveVotes,
    electionId,
    postIsVoted,
    postId,
    onVoteChange
  }) => {
    const methodName = 'VoteModal.toggleVote'
    console.log(methodName, '- start')
  
    const { postProps } = voteModalState
    const { platformProps } = postProps
    let togglePostId = postProps.postId ? postProps.postId : postId
    let toggleVoteElectionId = election ? election.id : electionId
    let toggleIsVoted = isVoted ? isVoted : postIsVoted
    let toggleOnVoteChange = postProps.onVoteChange ? postProps.onVoteChange: onVoteChange
    if (!toggleIsVoted) {
      console.log(methodName, '- post is not currently voted, voting...')
  
      if (!!togglePostId) {
        console.log(methodName, '- postId present')
        console.log(methodName, '- postId:', togglePostId)
        const data = await api.voteForPost(toggleVoteElectionId, user.id, togglePostId)
        console.log(methodName, '- data:', data)
        // Updating PostCardList.state.posts (Vote/Protest count / sorting)
        await toggleOnVoteChange(toggleVoteElectionId, togglePostId, platformProps, toggleIsVoted)
        // Updating App.state.user.activeVotes
        updateActiveVotes(toggleVoteElectionId, data)
      } else {
        console.log(methodName, '- postId not present')
        const data = await api.voteForPostFromPlatformProps(toggleVoteElectionId, user.id, postProps.platformId, platformProps)
        console.log(methodName, '- data:', data)
        // Updating PostCardList.state.posts (Vote/Protest count / sorting)
        await toggleOnVoteChange(toggleVoteElectionId, data.postId, platformProps, toggleIsVoted)
        // Updating PostCardList.state.posts (populating postId for voted post)
        updatePostProps(data.postId)
        // Updating App.state.user.activeVotes
        updateActiveVotes(toggleVoteElectionId, data)
      }
  
    } else {
      console.log(methodName, '- post is currently voted, un-voting...')
      if (togglePostId !== null) {
        console.log(methodName, '- postId is present:', togglePostId)
        const data = await api.unVoteForPost(toggleVoteElectionId, user.id)
        console.log(methodName, '- data:', data)
        // Updating PostCardList.state.posts (Vote/Protest count / sorting)
        await toggleOnVoteChange(toggleVoteElectionId, togglePostId, platformProps, toggleIsVoted)
        // Updating App.state.user.activeVotes
        updateActiveVotes(toggleVoteElectionId, null)
        // await this.props.refreshActiveVotes()
      } else {
        console.error(methodName, '- Error: post isVoted but has no postId')
        throw Error('post isVoted but has no postId')
      }
    }
  }

  updatePostProps = (postId) => {
    const methodName = 'App.updatePostProps'
    console.log(methodName, '- START')
    console.log(methodName, '- postId:', postId)

    this.setState(prevState => {
      return {
        voteModalState: {
          ...prevState.voteModalState,
          postProps: {
            ...prevState.voteModalState.postProps,
            postId: postId
          }
        }
      }
    }, () => console.log(methodName, '- setState Complete'))
  }

  getPlatformCredential = async (platform) => {
    const methodName = 'App.getPlatformCredential'
    console.log(methodName, '- platform:', platform)

    switch (platform.id) {
      case enums.PLATFORMS.TWITTER.id:
        return localUtils.getTwitterCredential()
      case enums.PLATFORMS.NEWS.id:
        return {}
      case enums.PLATFORMS.YOUTUBE.id:
        return await this.getYoutubeCredential()
      case enums.PLATFORMS.REDDIT.id:
        return localUtils.getRedditCredential()
      default:
        return
    }
  }

  addMixtapeSource = async (mixtapeSource, platform) => {
    const methodName = 'App.addMixtapeSource'
    console.log(methodName, '- mixtapeSource, platform:', mixtapeSource, platform)

    let addedMixtapeSource=mixtapeSource;

    // Update External Platform
    if (platform.id !== enums.PLATFORMS.NEWS.id) {
      const credential = await this.getPlatformCredential(platform)
      console.log(methodName, '- credential:', credential)

      addedMixtapeSource = await api.addMixtapeSource(platform.id, credential, mixtapeSource)
      console.log(methodName, '- addedMixtapeSource in conditional:', addedMixtapeSource)
    }
    console.log(methodName, '- addedMixtapeSource:', addedMixtapeSource)

    // Update App.state and DB
    const { user } = this.state
    let newMixtapeSources = copy.copyObject(user.platforms[platform.name].mixtapeSources)
    newMixtapeSources[addedMixtapeSource.mixtapeSourceId] = addedMixtapeSource
    console.log(methodName, '- newMixtapeSources:', newMixtapeSources)
    this.setMixtapeSourcesState(platform.name, newMixtapeSources)
  }

  removeMixtapeSource = async (mixtapeSource, platform) => {
    const methodName = 'App.removeMixtapeSource'
    console.log(methodName, '- mixtapeSource, platform:', mixtapeSource, platform)

    // Update External Platform
    if (platform.id !== enums.PLATFORMS.NEWS.id) {
      const credential = await this.getPlatformCredential(platform)
      console.log(methodName, '- credential:', credential)

      const data = await api.removeMixtapeSource(platform.id, credential, mixtapeSource)
      console.log(methodName, '- data:', data)
    }

    // Update App.state and DB
    const { user } = this.state
    let newMixtapeSources = copy.copyObject(user.platforms[platform.name].mixtapeSources)
    delete newMixtapeSources[mixtapeSource.mixtapeSourceId]
    console.log(methodName, '- newMixtapeSources:', newMixtapeSources)
    this.setMixtapeSourcesState(platform.name, newMixtapeSources)
  }

  subscribe = async (searchable, searchableType) => {
    const methodName = 'App.subscribe'
    console.log(methodName, '- searchable, searchableType:', searchable, searchableType)

    if (!searchableType.isSubscribable) {
      throw new Error(`${methodName} was called with unsubscribable: ${searchableType}`)
    } else if (searchableType.id === enums.SEARCHABLE_TYPE.ONEVOTE_GROUP.id) {
      console.log(methodName, '- subscribing to group')
      return await this.joinGroup(searchable)
    } else {
      console.log(methodName, '- subscribing to mixtapeSource')
      const { platform } = searchableType
      console.log(methodName, '- platform:', platform)
      return await this.addMixtapeSource(searchable, platform)
    }
  }

  unsubscribe = async (searchable, searchableType) => {
    const methodName = 'App.unsubscribe'
    console.log(methodName, '- searchable, searchableType:', searchable, searchableType)

    if (!searchableType.isSubscribable) {
      throw new Error(`${methodName} was called with unsubscribable: ${searchableType}`)
    } else if (searchableType.id === enums.SEARCHABLE_TYPE.ONEVOTE_GROUP.id) {
      return await this.leaveGroup(searchable)
    } else {
      const { platform } = searchableType
      return await this.removeMixtapeSource(searchable, platform)
    }
  }

  render() {
    console.log('App.render.routing.window.location.href', window.location.href)
    console.log('App.render.routing.this.props.location', this.props.location)
    console.log('App.render', this.props, this.state)
    const { isSignedIn, isVoteModalOpen, voteModalState, user, searchableState, redirectToReferrer } = this.state
    const { PrivateRoute } = this
    return (
        <Router>
          <ScrollToTop>
            <div className="App" style={{ width: '100%' }}>
              <NavigationBar
                isSignedIn={isSignedIn}
                toggleModal={this.toggleModal}
                onInitializeState={this.onInitializeState}
                user={user}
                handleKeyPress={this.handleKeyPress}
                getYoutubeCredential={this.getYoutubeCredential}
              />
              <MobileNavigationBar
                isSignedIn={isSignedIn}
                toggleModal={this.toggleModal}
                onInitializeState={this.onInitializeState}
                user={user}
                handleKeyPress={this.handleKeyPress}
                setCredential={this.setCredential}
                getYoutubeCredential={this.getYoutubeCredential}
              />
              {
                isVoteModalOpen &&
                <Modal>
                  <VoteModal
                    isVoteModalOpen={isVoteModalOpen}
                    voteModalState={voteModalState}
                    toggleVoteModal={this.toggleVoteModal}
                    user={user}
                    isSignedIn={isSignedIn}
                    updateActiveVotes={this.updateActiveVotes}
                    refreshActiveVotes={this.refreshActiveVotes}
                    updatePostProps={this.updatePostProps}
                    toggleVote={this.toggleVote}
                  />
                </Modal>
              }
              <div style={{marginTop: '10px'}}>
                <Switch>
                  <Route
                    path="/privacyPolicy"
                    render={() => (
                      <PrivacyPolicy/>
                    )}
                  />
                  <Route
                    path={`/feed`}
                    exact={true}
                    render={(props) => <Mixtape {...props}
                      user={user}
                      isSignedIn={isSignedIn}
                      subscribe={this.subscribe}
                      unsubscribe={this.unsubscribe}
                      refreshActiveVotes={this.refreshActiveVotes}
                      handleKeyPress={this.handleKeyPress}
                      toggleVoteModal={this.toggleVoteModal}
                      getYoutubeCredential={this.getYoutubeCredential}
                    />}
                  />
                  <Route
                    path="/userSettings"
                    render={(props) => <Settings {...props}
                      createGroup={this.createGroup}
                      setSearchableState={this.setSearchableState}
                      user={user}
                      isSignedIn={isSignedIn}
                      toggleVoteModal={this.toggleVoteModal}
                      setUser={this.setUser}
                      setCredential={this.setCredential}
                      getYoutubeCredential={this.getYoutubeCredential}
                      subscribe={this.subscribe}
                      unsubscribe={this.unsubscribe}
                      handleKeyPress={this.handleKeyPress}
                      unlinkPlatform={this.unlinkPlatform}
                      onInitializeState={this.onInitializeState}
                      getPlatformCredential={this.getPlatformCredential}
                    />}
                  />
                  <Route
                    path="/search"
                    render={(props) => <SearchMobile {...props}
                      user={user}
                      isSignedIn={isSignedIn}
                      handleKeyPress={this.handleKeyPress}
                    />}
                  />
                  <Route
                    path="/authHandler"
                    render={(props) => <AuthHandler {...props}
                      setUser={this.setUser}
                      refreshUserData={this.refreshUserData}
                      setPlatformState={this.setPlatformState}
                      setCredential={this.setCredential}
                      isSignedIn={isSignedIn}
                    />}
                  />
                  <Route
                    path={`/1vote_user/:userId`}
                    render={(props) => <OneVoteUserTimeline {...props}
                      user={user}
                      isSignedIn={isSignedIn}
                      subscribe={this.subscribe}
                      unsubscribe={this.unsubscribe}
                      refreshActiveVotes={this.refreshActiveVotes}
                      toggleVoteModal={this.toggleVoteModal}
                      toggleVote={this.toggleVote}
                      voteModalState={voteModalState}
                      updateActiveVotes={this.updateActiveVotes}
                    />}
                  />
                  <PrivateRoute
                    path={`/twitter_user/:source`}
                    render={(props) => <MixtapeSourceTimeline {...props}
                      user={user}
                      isSignedIn={isSignedIn}
                      subscribe={this.subscribe}
                      unsubscribe={this.unsubscribe}
                      platform={enums.PLATFORMS.TWITTER}
                      searchableState={searchableState}
                      refreshActiveVotes={this.refreshActiveVotes}
                      toggleVoteModal={this.toggleVoteModal}
                      getPlatformCredential={this.getPlatformCredential}
                    />}
                  />
                  <Route
                    path={`/news_source/:source`}
                    render={(props) => <MixtapeSourceTimeline {...props}
                      platform={enums.PLATFORMS.NEWS}
                      searchableState={searchableState}
                      user={user}
                      isSignedIn={isSignedIn}
                      subscribe={this.subscribe}
                      unsubscribe={this.unsubscribe}
                      refreshActiveVotes={this.refreshActiveVotes}
                      toggleVoteModal={this.toggleVoteModal}
                      getPlatformCredential={this.getPlatformCredential}
                    />}
                  />
                  <PrivateRoute
                    path={`/youtube_channel/:source`}
                    render={(props) => <MixtapeSourceTimeline {...props}
                      platform={enums.PLATFORMS.YOUTUBE}
                      searchableState={searchableState}
                      user={user}
                      isSignedIn={isSignedIn}
                      subscribe={this.subscribe}
                      unsubscribe={this.unsubscribe}
                      refreshActiveVotes={this.refreshActiveVotes}
                      toggleVoteModal={this.toggleVoteModal}
                      getYoutubeCredential={this.getYoutubeCredential}
                      getPlatformCredential={this.getPlatformCredential}
                    />}
                  />
                  <PrivateRoute
                    path={`/reddit_subreddit/:source`}
                    render={(props) => <MixtapeSourceTimeline {...props}
                      platform={enums.PLATFORMS.REDDIT}
                      searchableState={searchableState}
                      user={user}
                      isSignedIn={isSignedIn}
                      subscribe={this.subscribe}
                      unsubscribe={this.unsubscribe}
                      refreshActiveVotes={this.refreshActiveVotes}
                      toggleVoteModal={this.toggleVoteModal}
                      getPlatformCredential={this.getPlatformCredential}
                    />}
                  />
                  <PrivateRoute
                    path={`/deleteUser`}
                    render={(props) => <DeleteUserComponent {...props}
                      userId={user.id}
                      onInitializeState={this.onInitializeState}
                    />}
                  />
                  <Route
                    path="/home"
                    render={(props) => <Billboard {...props}
                      user={user}
                      isSignedIn={isSignedIn}
                      refreshActiveVotes={this.refreshActiveVotes}
                      handleKeyPress={this.handleKeyPress}
                      toggleVoteModal={this.toggleVoteModal}
                      subscribe={this.subscribe}
                      unsubscribe={this.unsubscribe}
                      toggleVote={this.toggleVote}
                      voteModalState={voteModalState}
                      updateActiveVotes={this.updateActiveVotes}
                    />}
                  />
                  <Route
                    path="/signIn"
                    render={(props) => (
                      <SignIn
                        {...props}
                        user={user}
                        redirectToReferrer={redirectToReferrer}
                      />
                    )}
                  />
                  <Route
                    path="/"
                    render={(props) => (
                      <SignIn
                    {...props}
                      user={user}
                      redirectToReferrer={redirectToReferrer}
                      />
                    )}
                  />
                </Switch>

              </div>
              <div style={{
                marginTop: '300px'
              }}>
                <Footer/>
              </div>
            </div>
          </ScrollToTop>
        </Router>
    )
  }
}

export default App
