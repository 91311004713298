import React, { Fragment } from 'react'
import List from '@material-ui/core/List'
import UseStyles from './UseStyles'
import ListItemComponent from './ListItemComponent'
import NavLink from './NavLink'
import ConditionalWrapper from './ConditionalWrapper'

export default function DrawerWithLinks(props) {
    const classes = UseStyles.menusUseStyles()
    const {
        routes,
        navHeader,
        parent,
        onPlatformSelect,
        argument
    } = props


 return (
        <div>
            <div className={classes.toolbar} />
            <div style={{ fontSize: '20px' }}>{navHeader}</div>
            {/* <hr /> */}
            <List>
                {
                    routes.map((route) => (
                        route.displayName !== "SignOut"
                            ? <ConditionalWrapper
                                key={route.displayName}
                                condition={(parent === 'billboard' || parent === 'userSettings')}
                                wrapper={children => (
                                    <NavLink
                                        route={route}
                                        parent={parent}
                                        children={children}
                                    />
                                )}
                            >
                                <Fragment>
                                    <ListItemComponent
                                        route={route}
                                        argument={argument}
                                        onPlatformSelect={onPlatformSelect}
                                    />
                                </Fragment>
                            </ConditionalWrapper>
                            : null
                    ))}
            </List>
        </div>
 )
}