import React from 'react'
import { Redirect } from 'react-router-dom'

import localUtils from '../../functions/localUtils'

import './AuthHandler.css'

const AuthHandlerBody = ({platformName}) => {
    
    const credentials = localUtils.getCredentials() || {}
    const isAuthorized = !!credentials[platformName]

    return (
      <div className="auth-handler">
        {
          isAuthorized
            ? <Redirect
              to={`/userSettings/${platformName}`}
            />
            : <h3>'Redirecting... Please Wait'</h3>
        }
      </div>
    )
}

export default AuthHandlerBody