import React from 'react'
import ReactSpinner from 'react-bootstrap-spinner'

const RectangularSpinner = () => {
    return (
      <div className="spinner-rectangular">
        <ReactSpinner type="border" color="primary" size="5"/>
      </div>
    )
  }

  export default RectangularSpinner