import twitter from '../assets/twitterLogo.svg'
import youtube from '../assets/youtubeLogo.svg'
import reddit from '../assets/redditLogo.svg'
import news from '../assets/news.png'
import mixtapeSourceIcon from '../assets/cassette.svg'
import voteIcon from '../assets/1vote-on.svg'
import groupIcon from '../assets/groups.png'
import defaultNewsIcon from '../assets/defaultNewsIcon.png'
import defaultSubredditIcon from '../assets/defaultSubredditIcon.png'
import defaultYoutubeIcon from '../assets/youtubeLogo.svg'
import defaultVideoIcon from '../assets/defaultVideoIcon.svg';
import defaultPeopleIcon from '../assets/defaultPeopleIcon.svg';
import defaultLifestyleIcon from '../assets/defaultLifestyleIcon.svg';
import defaultNewsIcon2 from '../assets/defaultNewsIcon2.svg'

import YouTubeIcon from '@material-ui/icons/YouTube'
import RedditIcon from '@material-ui/icons/Reddit'
import TwitterIcon from '@material-ui/icons/Twitter'

const PLATFORMS = {
  ONEVOTE: {
    id: 0,
    name: '1vote',
    displayName: '1vote',
    mixtapeSourcesName: '1vote Users',
  },
  TWITTER: {
    id: 1,
    name: 'twitter',
    displayName: 'Twitter',
    src: twitter,
    mixtapeSourcesName: 'Twitter Followees',
    icon: TwitterIcon
  },
  NEWS: {
    id: 2,
    name: 'news',
    displayName: 'News',
    src: news,
    mixtapeSourcesName: 'News Sources',
  },
  YOUTUBE: {
    id: 3,
    name: 'youtube',
    displayName: 'YouTube',
    src: youtube,
    mixtapeSourcesName: 'YouTube Channels',
    icon: YouTubeIcon
  },
  REDDIT: {
    id: 4,
    name: 'reddit',
    displayName: 'Reddit',
    src: reddit,
    mixtapeSourcesName: 'Subreddits',
    icon: RedditIcon
  },
}

const DEFAULT_ELECTION_TYPES = [
  {
    id: 0,
    name: 'news', 
    displayName: 'News', 
    image: defaultNewsIcon2,
  },
  {
    id: 1,
    name: 'videos', 
    displayName: 'Videos', 
    image: defaultVideoIcon
  },
  {
    id: 2,
    name: 'people', 
    displayName: 'People', 
    image: defaultPeopleIcon
  },
  {
    id: 3,
    name: 'lifestyle', 
    displayName: 'Lifestyle', 
    image: defaultLifestyleIcon
  },
]

const DEFAULT_ELECTIONS = [
  {
    id: 1,
    name: "breaking",
    displayName: "Breaking",
    description: "What's the most important breaking news right now?",
    imageUrl: "https://static.1vote.co/front_page_icon.jpg",
    electionType: DEFAULT_ELECTION_TYPES[0],
    electionTypeName: 'news',
    electionTypeId: 0
  },
  {
    id: 2,
    name: "opinion",
    displayName: "Opinion",
    description: "What's the best opinion piece right now?",
    imageUrl: "https://static.1vote.co/opinion_news_icon.svg",
    electionType: DEFAULT_ELECTION_TYPES[0],
    electionTypeName: 'news',
    electionTypeId: 0
  },
  {
    id: 3,
    name: "covid", 
    displayName: "COVID19", 
    description: "What's the most important COVID-19 related news right now?",
    imageUrl: "https://static.1vote.co/covid_news_icon.png",
    electionType: DEFAULT_ELECTION_TYPES[0],
    electionTypeName: 'news',
    electionTypeId: 0
  },
  {
    id: 4,
    name: "politics", 
    displayName: "Politics", 
    description: "What's the most important news in US politics right now?",
    imageUrl: "https://static.1vote.co/politics_icon.jpg",
    electionType: DEFAULT_ELECTION_TYPES[0],
    electionTypeName: 'news',
    electionTypeId: 0
  },
  {
    id: 5,
    name: "business", 
    displayName: "Business", 
    description: "What's the most important business or financial news right now?",
    imageUrl: "https://static.1vote.co/business_icon.jpg",
    electionType: DEFAULT_ELECTION_TYPES[0],
    electionTypeName: 'news',
    electionTypeId: 0
  },
  {
    id: 6,
    name: "entertainment", 
    displayName: "Entertainment", 
    description: "What's the most interesting entertainment news right now?",
    imageUrl: "https://static.1vote.co/entertainment_icon.jpg",
    electionType: DEFAULT_ELECTION_TYPES[0],
    electionTypeName: 'news',
    electionTypeId: 0
  },
  {
    id: 7,
    name: "global", 
    displayName: "Global", 
    description: "What's the most important global news right now?",
    imageUrl: "https://static.1vote.co/global_icon.jpg",
    electionType: DEFAULT_ELECTION_TYPES[0],
    electionTypeName: 'news',
    electionTypeId: 0
  },
  {
    id: 8,
    name: "science", 
    displayName: "Science", 
    description: "What's the most important science or technology news right now?",
    imageUrl: "https://static.1vote.co/science_icon.png",
    electionType: DEFAULT_ELECTION_TYPES[0],
    electionTypeName: 'news',
    electionTypeId: 0
  },  
  {
    id: 9,
    name: "sports", 
    displayName: "Sports", 
    description: "What's the most interesting sports news right now?",
    imageUrl: "https://static.1vote.co/sports_icon.jpg",
    electionType: DEFAULT_ELECTION_TYPES[0],
    electionTypeName: 'news',
    electionTypeId: 0
  },
  {
    id: 10,
    name: "best_video",
    displayName: "Best Video",
    description: "What's the best video right now?",
    imageUrl: "https://static.1vote.co/best_video_icon.jpg",
    electionType: DEFAULT_ELECTION_TYPES[1],
    electionTypeName: 'videos',
    electionTypeId: 1
  },
  {
    id: 11,
    name: "music_video",
    displayName: "Music Video",
    description: "What's the best music video right now?",
    imageUrl: "https://static.1vote.co/front_page_icon.jpg",
    electionType: DEFAULT_ELECTION_TYPES[1],
    electionTypeName: 'videos',
    electionTypeId: 1
  },
  {
    id: 12,
    name: "beauty_video", 
    displayName: "Beauty", 
    description: "What's the best beauty video right now?",
    imageUrl: "https://static.1vote.co/front_page_icon.jpg",
    electionType: DEFAULT_ELECTION_TYPES[1],
    electionTypeName: 'videos',
    electionTypeId: 1
  },
  {
    id: 13,
    name: "sports_video", 
    displayName: "Sports", 
    description: "What's the best sports video right now?",
    imageUrl: "https://static.1vote.co/politics_icon.jpg",
    electionType: DEFAULT_ELECTION_TYPES[1],
    electionTypeName: 'videos',
    electionTypeId: 1
  },
  {
    id: 14,
    name: "gaming_video", 
    displayName: "Gaming", 
    description: "What's the best gaming video right now?",
    imageUrl: "https://static.1vote.co/business_icon.jpg",
    electionType: DEFAULT_ELECTION_TYPES[1],
    electionTypeName: 'videos',
    electionTypeId: 1
  },
  {
    id: 15,
    name: "trailers_video", 
    displayName: "Trailers", 
    description: "What's the best trailer right now?",
    imageUrl: "https://static.1vote.co/entertainment_icon.jpg",
    electionType: DEFAULT_ELECTION_TYPES[1],
    electionTypeName: 'videos',
    electionTypeId: 1
  },
  {
    id: 16,
    name: "animal_video", 
    displayName: "Animal", 
    description: "What's the best animal video right now?",
    imageUrl: "https://static.1vote.co/global_icon.jpg",
    electionType: DEFAULT_ELECTION_TYPES[1],
    electionTypeName: 'videos',
    electionTypeId: 1
  },
  {
    id: 17,
    name: "comedy_video", 
    displayName: "Comedy", 
    description: "What's the best comedy video right now?",
    imageUrl: "https://static.1vote.co/science_icon.png",
    electionType: DEFAULT_ELECTION_TYPES[1],
    electionTypeName: 'videos',
    electionTypeId: 1
  },  
  {
    id: 18,
    name: "cooking_video", 
    displayName: "Cooking", 
    description: "What's the best cooking video right now?",
    imageUrl: "https://static.1vote.co/sports_icon.jpg",
    electionType: DEFAULT_ELECTION_TYPES[1],
    electionTypeName: 'videos',
    electionTypeId: 1
  },
]

const INITIAL_PLATFORMS = {
  news: {
    mixtapeSources: {
      'cnn': {
        mixtapeSourceId: "cnn",
        imageLinkPath: "/news_source/cnn",
        title: "CNN",
        description: "View the latest news and breaking news today for U.S., world, weather, entertainment, politics and health at CNN",
        url: "http://us.cnn.com",
        category: "general",
        language: "en",
        country: "us",
        filterName: "CNN",
        imageUrl: "https://cdn.iconscout.com/icon/free/png-256/cnn-282163.png",
        rectangularimageUrl: "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b1/CNN.svg/1024px-CNN.svg.png"
      },
      'entertainment-weekly': {
        mixtapeSourceId: "entertainment-weekly",
        imageLinkPath: "/news_source/entertainment-weekly",
        title: "Entertainment Weekly",
        description: "Online version of the print magazine includes entertainment news, interviews, reviews of music, film, TV and books, and a special area for magazine subscribers.",
        url: "http://www.ew.com",
        category: "entertainment",
        language: "en",
        country: "us",
        filterName: "Entertainment Weekly",
        imageUrl: "https://d1yjjnpx0p53s8.cloudfront.net/styles/logo-thumbnail/s3/0025/1031/brand.gif?itok=9yjx3Fap",
        rectangularimageUrl: "https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/Entertainment_Weekly_logo.svg/1280px-Entertainment_Weekly_logo.svg.png"
      },
      'espn': {
        mixtapeSourceId: "espn",
        imageLinkPath: "/news_source/espn",
        title: "ESPN",
        description: "ESPN has up-to-the-minute sports news coverage, scores, highlights and commentary for NFL, MLB, NBA, College Football, NCAA Basketball and more.",
        url: "http://espn.go.com",
        domain: "espn.com",
        category: "sports",
        language: "en",
        country: "us",
        filterName: "ESPN",
        imageUrl: "https://d1yjjnpx0p53s8.cloudfront.net/styles/logo-thumbnail/s3/092013/espn_nuevo_logo.png?itok=Dew0aaPw",
        rectangularimageUrl: "https://upload.wikimedia.org/wikipedia/commons/thumb/2/2f/ESPN_wordmark.svg/1280px-ESPN_wordmark.svg.png"
      },
      'fortune': {
        mixtapeSourceId: "fortune",
        imageLinkPath: "/news_source/fortune",
        title: "Fortune",
        description: "Fortune 500 Daily and Breaking Business News",
        url: "http://fortune.com",
        category: "business",
        language: "en",
        country: "us",
        filterName: "Fortune",
        imageUrl: "https://upload.wikimedia.org/wikipedia/commons/5/5f/Logo_Fortune.jpg",
        rectangularimageUrl: "https://content.fortune.com/wp-content/uploads/2016/10/fortune-logo-19481951-1280x739.jpg"
      },
      'fox-news': {
        mixtapeSourceId: "fox-news",
        imageLinkPath: "/news_source/fox-news",
        title: "Fox News",
        description: "Breaking News, Latest News and Current News from FOXNews.com. Breaking news and video. Latest Current News: U.S., World, Entertainment, Health, Business, Technology, Politics, Sports.",
        url: "http://www.foxnews.com",
        category: "general",
        language: "en",
        country: "us",
        filterName: "Fox News",
        imageUrl: "https://www.thearcticinstitute.org/wp-content/uploads/2019/03/Fox-News-logo-96x96.jpg",
        rectangularimageUrl: "https://www.broadcastingcable.com/.image/t_share/MTU4Nzc0ODkxMjQ2NTI4MTYx/fox-news-channel-logo.jpg"
      },
      'msnbc': {
        mixtapeSourceId: "msnbc",
        imageLinkPath: "/news_source/msnbc",
        title: "MSNBC",
        description: "Breaking news and in-depth analysis of the headlines, as well as commentary and informed perspectives from The Rachel Maddow Show, Morning Joe & more.",
        url: "http://www.msnbc.com",
        category: "general",
        language: "en",
        country: "us",
        filterName: "MSNBC",
        imageUrl: "https://static-s.aa-cdn.net/img/ios/396885309/32c6a4cb4737727185ff4f3ba08fb4bd?v=1",
        rectangularimageUrl: "https://www.logodatabases.com/files/wp-content/uploads/2012/05/msnbc-logo-wallpaper-500x285.jpg"
      },
      'national-geographic': {
        mixtapeSourceId: "national-geographic",
        imageLinkPath: "/news_source/national-geographic",
        title: "National Geographic",
        description: "Reporting our world daily: original nature and science news from National Geographic.",
        url: "http://news.nationalgeographic.com",
        category: "science",
        language: "en",
        country: "us",
        filterName: "National Geographic",
        imageUrl: "https://wattsupwiththat.files.wordpress.com/2015/03/national-geographic-logo1.jpg?resize=100%2C75",
        rectangularimageUrl: "https://upload.wikimedia.org/wikipedia/commons/f/fc/Natgeologo.svg"
      },
      'techcrunch': {
        mixtapeSourceId: "techcrunch",
        imageLinkPath: "/news_source/techcrunch",
        title: "TechCrunch",
        description: "TechCrunch is a leading technology media property, dedicated to obsessively profiling startups, reviewing new Internet products, and breaking tech news.",
        url: "https://techcrunch.com",
        category: "technology",
        language: "en",
        country: "us",
        filterName: "TechCrunch",
        imageUrl: "https://res-4.cloudinary.com/crunchbase-production/image/upload/c_lpad,h_120,w_120,f_auto,b_white,q_auto:eco/v1487972722/z2yb0lc1dci3dalkwcvj.png",
        rectangularimageUrl: "https://www.pipelinerx.com/wp-content/uploads/TechCrunch-Logo-2.jpg"
      },
    }
  },
  twitter: {
    mixtapeSources: {}
  },
  youtube: {
    mixtapeSources: {}
  },
  reddit: {
    mixtapeSources: {}
  }
}

const platformFromPlatformName = (platformName) => {
  const methodName = 'enums.platformFromPlatformName'
  console.log(methodName, '- platformName:', platformName)

  const platforms = Object.values(PLATFORMS).filter(val => val.name === platformName)

  if (platforms.length !== 1) {
    throw Error(`Found ${platforms.length} platforms with name ${platformName}`)
  } else {
    const platform = platforms[0]
    console.log(methodName, '- platform:', platform)
    return platforms[0]
  }
}

const POST_CARD_LIST_TYPE = {
  BILLBOARD: {
    id: 1,
    name: 'Billboard',
    refreshOnVote: false,
  },
  MIXTAPE: {
    id: 2,
    name: 'Mixtape',
    refreshOnVote: false,
  },
  MIXTAPE_SOURCE_TIMELINE: {
    id: 3,
    name: 'MixtapeSourceTimeline',
    refreshOnVote: false,
  },
  PROFILE: {
    id: 4,
    name: 'Profile',
    refreshOnVote: true,
  },
  WINNERS: {
    id: 5,
    name: 'Winners',
    refreshOnVote: true
  }
}

const SEARCHABLE_TYPE = {
  TWITTER_USER: {
    id: 1,
    pluralName: 'Twitter Users',
    platform: PLATFORMS.TWITTER,
    isSubscribable: true,
    isNavigable: true,
    settingsPagePath: '/UserSettings/twitter',
    defaultIcon: mixtapeSourceIcon,
    subscriptionIdType: 'twitterPlatformUserId'
  },
  NEWS_SOURCE: {
    id: 2,
    pluralName: 'News Sources',
    platform: PLATFORMS.NEWS,
    isSubscribable: true,
    isNavigable: true,
    settingsPagePath: '/UserSettings/news',
    defaultIcon: defaultNewsIcon,
    subscriptionIdType: 'newsSourceId'

  },
  YOUTUBE_CHANNEL: {
    id: 3,
    pluralName: 'YouTube Channels',
    platform: PLATFORMS.YOUTUBE,
    isSubscribable: true,
    isNavigable: true,
    settingsPagePath: '/UserSettings/youtube',
    defaultIcon: defaultYoutubeIcon,
    subscriptionIdType: 'youtubeChannelId'
  },
  REDDIT_SUBREDDIT: {
    id: 4,
    pluralName: 'Subreddits',
    platform: PLATFORMS.REDDIT,
    isSubscribable: true,
    isNavigable: true,
    settingsPagePath: '/UserSettings/reddit',
    defaultIcon: defaultSubredditIcon,
    subscriptionIdType: 'subreddit'
  },
  ONEVOTE_USER: {
    id: 5,
    pluralName: '1vote Users',
    platform: PLATFORMS.ONEVOTE,
    isSubscribable: false,
    isNavigable: true,
    settingsPagePath: '/UserSettings/1vote',
    defaultIcon: voteIcon,
    subscriptionIdType: null
  },
  ONEVOTE_GROUP: {
    id: 6,
    pluralName: 'Groups',
    platform: PLATFORMS.ONEVOTE,
    isSubscribable: true,
    isNavigable: false,
    settingsPagePath: '/UserSettings/groups',
    defaultIcon: groupIcon,
    subscriptionIdType: null
  },
}

const getSearchableTypeById = (id) => {
  const methodName = 'enums.getSearchableTypeById'
  console.log(methodName, '- id:', id)

  const filteredValues = Object.values(SEARCHABLE_TYPE).filter(val => val.id === id)
  console.log(methodName, '- filteredValues:', filteredValues)

  if (filteredValues.length !== 1) {
    throw new Error(`${methodName} - filterValues length = ${filteredValues.length}`)
  }
  return filteredValues[0]
}

const getSearchableTypeByPlatformId = (platformId) => {
  const methodName = 'enums.getSearchableTypeByPlatformId'
  console.log(methodName, '- platformId:', platformId)

  switch (platformId) {
    case PLATFORMS.TWITTER.id:
      return SEARCHABLE_TYPE.TWITTER_USER
    case PLATFORMS.NEWS.id:
      return SEARCHABLE_TYPE.NEWS_SOURCE
    case PLATFORMS.YOUTUBE.id:
      return SEARCHABLE_TYPE.YOUTUBE_CHANNEL
    case PLATFORMS.REDDIT.id:
      return SEARCHABLE_TYPE.REDDIT_SUBREDDIT
    case PLATFORMS.ONEVOTE.id:
      return SEARCHABLE_TYPE.ONEVOTE_USER
  }
}

const SETTINGS_PAGE = {
  USER_SETTINGS: {
    id: 1,
    title: 'Edit Profile',
    platform: PLATFORMS.ONEVOTE,
    searchableType: null,
  },
  GROUP_SETTINGS: {
    id: 2,
    title: 'Groups',
    platform: PLATFORMS.ONEVOTE,
    searchableType: SEARCHABLE_TYPE.ONEVOTE_GROUP,
  },
  TWITTER_SETTINGS: {
    id: 3,
    title: 'Twitter Settings',
    platform: PLATFORMS.TWITTER,
    searchableType: SEARCHABLE_TYPE.TWITTER_USER,
  },
  NEWS_SETTINGS: {
    id: 4,
    title: 'News Settings',
    platform: PLATFORMS.NEWS,
    searchableType: SEARCHABLE_TYPE.NEWS_SOURCE,
  },
  YOUTUBE_SETTINGS: {
    id: 5,
    title: 'YouTube Settings',
    platform: PLATFORMS.YOUTUBE,
    searchableType: SEARCHABLE_TYPE.YOUTUBE_CHANNEL,
  },
  REDDIT_SETTINGS: {
    id: 6,
    title: 'Reddit Settings',
    platform: PLATFORMS.REDDIT,
    searchableType: SEARCHABLE_TYPE.REDDIT_SUBREDDIT,
  },
  ONEVOTE_USER_SEARCH: {
    id: 7,
    title: '1vote Settings',
    platform: PLATFORMS.ONEVOTE,
    searchableType: SEARCHABLE_TYPE.ONEVOTE_USER,
  },
}

const getPlatformSettingsPageFromPlatformId = (platformId) => {
  const methodName = 'enums.getPlatformSettingsPageFromPlatformId'
  console.log(methodName, '- platformId:', platformId)

  switch (platformId) {
    case PLATFORMS.TWITTER.id:
      return SETTINGS_PAGE.TWITTER_SETTINGS
    case PLATFORMS.NEWS.id:
      return SETTINGS_PAGE.NEWS_SETTINGS
    case PLATFORMS.YOUTUBE.id:
      return SETTINGS_PAGE.YOUTUBE_SETTINGS
    case PLATFORMS.REDDIT.id:
      return SETTINGS_PAGE.REDDIT_SETTINGS
    case PLATFORMS.ONEVOTE.id:
      return SETTINGS_PAGE.ONEVOTE_USER_SEARCH
  }
}

export default {
  PLATFORMS,
  DEFAULT_ELECTION_TYPES,
  DEFAULT_ELECTIONS,
  INITIAL_PLATFORMS,
  platformFromPlatformName,
  getSearchableTypeById,
  getSearchableTypeByPlatformId,
  POST_CARD_LIST_TYPE,
  SEARCHABLE_TYPE,
  SETTINGS_PAGE,
  getPlatformSettingsPageFromPlatformId
}