import React from 'react'

import SubUnsubButton from '../../Buttons/SubUnsubButton'
import SmallCircularImagesContainer from '../SmallCircularImages/SmallCircularImagesContainer'
import SmallTextContainer from '../SmallText/SmallTextContainer'

import '../PostCard.css'

const CircularImagesAndSmallTextContainer = (props)=>{
    
    const {
        imagesContainerLinkUrl1,
        imagesContainerLinkUrl2,
        imagesContainerLinkUrl3,
        imagesContainerImgOptions1,
        imagesContainerImgOptions2,
        imagesContainerImgOptions3,
        imagesContainerAltText1,
        imagesContainerAltText2,
        imagesContainerAltText3,
        textContainerLinkUrl1,
        textContainerLinkUrl2,
        textContainerText1,
        textContainerText2,
        isVerified,
    } = props

    return (
        <div className="post-circle-images-and-small-text-container">
            {/* (SMALL) CIRCLE IMAGES */}
            <SmallCircularImagesContainer 
                linkUrl1={imagesContainerLinkUrl1}
                linkUrl2={imagesContainerLinkUrl2}
                linkUrl3={imagesContainerLinkUrl3}
                imgOptions1={imagesContainerImgOptions1}
                imgOptions2={imagesContainerImgOptions2}
                imgOptions3={imagesContainerImgOptions3}
                altText1={imagesContainerAltText1}
                altText2={imagesContainerAltText2}
                altText3={imagesContainerAltText3}
            />
            {/* SMALL TEXT */}
            <SmallTextContainer 
                linkUrl1={textContainerLinkUrl1}
                linkUrl2={textContainerLinkUrl2}
                text1={textContainerText1}
                text2={textContainerText2}
                isVerified={isVerified}
            />
            <SubUnsubButton
                {...props}
            />
        </div>
    )
}
export default CircularImagesAndSmallTextContainer