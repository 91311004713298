import localUtils from './localUtils'
import arrayUtils from './arrayUtils'

const voteForPost = async (electionId, userId, postId) => {
  const methodName = 'functions.api.voteForPost'
  console.log(methodName, '- electionId, userId, postId:', electionId, userId, postId)

  const url = `${process.env.REACT_APP_ONEVOTE_API_URL}/voteForPost`

  const response = await fetch(url,
    {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': localUtils.getToken(),
      },
      body: JSON.stringify({
        electionId,
        userId,
        postId,
      })
    })
  // console.log(methodName, '- response:', response)
  const data = await response.json()
  console.log(methodName, '- data:', data)

  const outData = {
    voteId: parseInt(data.id),
    electionId,
    userId: parseInt(userId),
    postId: parseInt(postId),
    datetimeActivated: data.datetime_activated,
    datetimeDeactivated: data.datetime_deactivated
  }
  console.log(methodName, '- outData:', outData)

  return outData
}


const voteForPostFromPlatformProps = async (electionId, userId, platformId, platformProps) => {
  const methodName = 'functions.api.voteForPostFromPlatformProps'
  console.log(methodName, '- electionId, userId, platformId, platformProps:',
  electionId, userId, platformId, platformProps)

  const url = `${process.env.REACT_APP_ONEVOTE_API_URL}/voteForPostFromPlatformProps`

  const response = await fetch(url,
    {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': localUtils.getToken(),
      },
      body: JSON.stringify({
        electionId,
        userId,
        platformId,
        platformProps,
      })
    })
  // console.log(methodName, '- response:', response)

  const data = await response.json()
  console.log(methodName, '- data:', data)

  const outData = {
    voteId:  parseInt(data.id),
    electionId,
    userId: parseInt(userId),
    postId: parseInt(data.post_id),
    datetimeActivated: data.datetime_activated,
    datetimeDeactivated: data.datetime_deactivated,
  }
  console.log(methodName, '- outData:', outData)

  return outData
}

const unVoteForPost = async (electionId, userId) => {
  const methodName = 'functions.api.unVoteForPost'
  console.log(methodName, '- electionId, userId:', electionId, userId)

  const url = `${process.env.REACT_APP_ONEVOTE_API_URL}/unVoteForPost`

  const response = await fetch(url, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': localUtils.getToken(),
    },
    body: JSON.stringify({
      electionId,
      userId,
    })
  })
  // console.log(methodName, '- response:', response)

  const data = await response.json()
  console.log(methodName, '- data:', data)

  const outData = {
    voteId:  parseInt(data.id),
    electionId,
    userId: parseInt(userId),
    postId: parseInt(data.post_id),
    datetimeActivated: data.datetime_activated,
    datetimeDeactivated: data.datetime_deactivated,
  }
  console.log(methodName, '- outData:', outData)

  return outData
}

const twitterSignIn = async (platformName, platform, credential) => {
  const methodName = 'functions.api.twitterSignIn'
  console.log(methodName, '- platform, credential:', platform, credential)

  const url = `${process.env.REACT_APP_ONEVOTE_API_URL}/twitterSignIn`

  const response = await fetch(url, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': null,
    },
    body: JSON.stringify({
      platformName,
      platform,
      credential,
    }),
  })
  // console.log(methodName, '- response:', response)

  const data = await response.json()
  console.log(methodName, '- data', data)

  return data
}

const youtubeSignIn = async (platformName, platform, credential) => {
  const methodName = 'functions.api.youtubeSignIn'
  console.log(methodName, '- platform, credential:', platform, credential)

  const url = `${process.env.REACT_APP_ONEVOTE_API_URL}/youtubeSignIn`

  const response = await fetch(url, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': null,
    },
    body: JSON.stringify({
      platformName,
      platform,
      credential,
    }),
  })
  // console.log(methodName, '- response:', response)

  const data = await response.json()
  console.log(methodName, '- data', data)

  return data
}

const updateUserData = async (appUserData, credentials) => {
  const methodName = 'functions.api.updateUserData'
  console.log(methodName, '- appUserData, credentials:', appUserData, credentials)

  const url = `${process.env.REACT_APP_ONEVOTE_API_URL}/updateUserData`

  const userData = { ...appUserData, credentials }

  const response = await fetch(url, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': null,
    },
    body: JSON.stringify({
      userData,
    }),
  })
  // console.log(methodName, '- response:', response)

  const data = await response.json()
  console.log(methodName, '- data', data)

  return data
}

const getUser = async (id) => {
  const methodName = 'functions.api.getUser'
  console.log(methodName, '- id:', id)

  const url = `${process.env.REACT_APP_ONEVOTE_API_URL}/getUser`

  const response = await fetch(url, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': localUtils.getToken(),
    },
    body: JSON.stringify({
      id,
    }),
  })
  // console.log(methodName, '- response:', response)

  const data = await response.json()
  console.log(methodName, '- data', data)

  return data
}

const deleteUser = async (userId) => {
  const methodName = 'functions.api.deleteUser'
  console.log(methodName, '- userId:', userId)

  const url = `${process.env.REACT_APP_ONEVOTE_API_URL}/deleteUser`

  const response = await fetch(url, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': localUtils.getToken(),
    },
    body: JSON.stringify({
      userId,
    }),
  })
  // console.log(methodName, '- response:', response)

  const data = await response.json()
  console.log(methodName, '- data', data)

  return data
}

const getActiveVotes = async (userId) => {
  const methodName = 'functions.api.getActiveVotes'
  console.log(methodName, '- userId:', userId)

  const url = `${process.env.REACT_APP_ONEVOTE_API_URL}/getActiveVotes`

  const response = await fetch(url, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': window.localStorage.getItem('token'),
    },
    body: JSON.stringify({
      userId,
    }),
  })
  // console.log(methodName, '- response:', response)

  const data = await response.json()
  console.log(methodName, '- data:', data)

  const obj = arrayUtils.arrayToObject(data, 'electionId')
  console.log(methodName, '- obj:', obj)

  return obj
}

const getUserTimeline = async (electionId, userId) => {
  const methodName = 'functions.api.getUserTimeline'
  console.log(methodName, '- electionId, userId:', electionId, userId)

  const url = `${process.env.REACT_APP_ONEVOTE_API_URL}/getUserTimeline`

  const response = await fetch(url, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': localUtils.getToken(),
    },
    body: JSON.stringify({
      electionId,  
      userId
    })
  })

  const data = await response.json()
  console.log(methodName, '- data:', data)

  return data
}

const getUsersCurrentlyVotedPosts = async(userId)=> {
  const methodName = 'functions.api.getUsersCurrentlyVotedPosts'
  console.log(methodName, '- userId:', userId)

  const url = `${process.env.REACT_APP_ONEVOTE_API_URL}/getUsersCurrentlyVotedPosts`

  const response = await fetch(url, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': localUtils.getToken(),
    },
    body: JSON.stringify({
      userId
    })
  })

  const data = await response.json()
  console.log(methodName, "- data:", data)

  // const outData = data.map(post => {
  //   return {
  //     key: post.key,
  //     postId: parseInt(post.postId),
  //     platformId: parseInt(post.platformId),
  //     voteCount: parseInt(post.voteCount),
  //     datetimeActivated: post.datetimeActivated,
  //     electionId: parseInt(post.electionId),
  //     electionDisplayName: post.electionDisplayName,
  //     platformProps: post.platformProps,
  //   }
  // })
  // console.log(methodName, '- outData:', outData)

  return data
}

const searchOneVoteUsers = async (q) => {
  const methodName = 'functions.api.searchOneVoteUsers'
  console.log(methodName,'- 1VoteUser:', q)

  const url = `${process.env.REACT_APP_ONEVOTE_API_URL}/searchOneVoteUsers`

  const response = await fetch(url, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': localUtils.getToken(),
    },
    body: JSON.stringify({
      q,
    }),
  })
  // console.log(methodName, '- response:', response)

  const data = await response.json()
  console.log(methodName, '- data:', data)

  return data
}

const refreshYoutubeCredential = async (credential) => {
  const methodName = 'functions.api.refreshYoutubeCredential'
  console.log(methodName)

  const url = `${process.env.REACT_APP_ONEVOTE_API_URL}/refreshYoutubeCredential`

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': localUtils.getToken(),
    },
    body: JSON.stringify({
      credential,
    })
  })
  // console.log(methodName, '- response:', response)

  const data = await response.json()
  console.log(methodName, '- data:', data)

  return data
}

const getTwitterRequestToken = async () => {
  const methodName = 'functions.api.getTwitterRequestToken'
  console.log(methodName)

  const url = `${process.env.REACT_APP_ONEVOTE_API_URL}/getTwitterRequestToken`

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': localUtils.getToken(),
    },
    body: JSON.stringify({
      requestToken: '',
      requestTokenSecret: ''
    })
  })
  // console.log(methodName, '- response:', response)

  const data = await response.json()
  console.log(methodName, '- data:', data)

  window.localStorage.setItem('twitterRequestToken', JSON.stringify(data))
  return data
}

const getTweetInfo = async (id, credential) => {
  const methodName = 'functions.api.getTweetInfo'
  console.log(methodName, '- id, credential:', id, credential)

  const url = `${process.env.REACT_APP_ONEVOTE_API_URL}/getTweetInfo`

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': localUtils.getToken(),
    },
    body: JSON.stringify({
      id,
      credential,
    })
  })
  // console.log(methodName, '- response:', response)

  const data = await response.json()
  console.log(methodName, '- data:', data)

  return data
}

const getMixtape = async (mixtapeProps) => {
  const methodName = 'functions.api.getMixtape'
  console.log(methodName, '- mixtapeProps:', mixtapeProps)

  const url = `${process.env.REACT_APP_ONEVOTE_API_URL}/getMixtape`

  const response = await fetch(url, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': localUtils.getToken(),
    },
    body: JSON.stringify(mixtapeProps),
  })
  // console.log(methodName, '- response:', response)

  const data = await response.json()
  console.log(methodName, '- data:', data)

  return data
}

const getLeaderboard = async (electionId) => {
  const methodName = 'functions.api.getLeaderboard'
  console.log(methodName, '- electionId:', electionId)

  const url = `${process.env.REACT_APP_ONEVOTE_API_URL}/getLeaderboard`

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': localUtils.getToken(),
    },
    body: JSON.stringify({
      electionId
    })
  })
  // console.log(methodName, '- response:', response)

  const data = await response.json()
  console.log(methodName, '- data:', data)

  return data
}

const getElectionWinners = async (electionTypeId) => {
  const methodName = 'functions.api.getElectionWinners'
  console.log(methodName, '- electionTypeId:', electionTypeId)

  const url = `${process.env.REACT_APP_ONEVOTE_API_URL}/getElectionWinners`

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': localUtils.getToken(),
    },
    body: JSON.stringify({
      electionTypeId
    })
  })
  // console.log(methodName, '- response:', response)

  const data = await response.json()
  console.log(methodName, '- data:', data)

  return data
}

const test = async () => {
  const methodName = 'functions.api.test'
  console.log(methodName)

  const url = `${process.env.REACT_APP_ONEVOTE_API_URL}/`

  const response = await fetch(url)
  // console.log(methodName, '- response:', response)

  const data = await response.json()
  console.log(methodName, '- data:', data)

  return data
}

const getDefaultCategories = async () => {
  const methodName = 'functions.api.getDefaultCategories'
  console.log(methodName)

  const url = `${process.env.REACT_APP_ONEVOTE_API_URL}/getDefaultCategories`

  const response = await fetch(url, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': window.localStorage.getItem('token')
    },
    body: JSON.stringify({
    })
  })
  // console.log(methodName, '- response:', response)

  const data = await response.json()
  console.log(methodName, '- data:', data)

  return data
}

const getElections = async (electionTypeIdsArray) => {
  const methodName = 'functions.api.getElections'
  console.log(methodName)

  const url = `${process.env.REACT_APP_ONEVOTE_API_URL}/getElections`

  const response = await fetch(url, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': window.localStorage.getItem('token')
    },
    body: JSON.stringify({
      electionTypeIdsArray
    })
  })
  // console.log(methodName, '- response:', response)

  const data = await response.json()
  console.log(methodName, '- data:', data)

  return data
}

const getGroups = async (userId) => {
  const methodName = 'functions.api.getGroups'
  console.log(methodName, '- userId:', userId)

  const url = `${process.env.REACT_APP_ONEVOTE_API_URL}/getGroups`

  const response = await fetch(url, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': window.localStorage.getItem('token')
    },
    body: JSON.stringify({
      userId,
    })
  })
  // console.log(methodName, '- response:', response)

  const data = await response.json()
  console.log(methodName, '- data:', data)

  return data
}

const searchGroups = async (q) => {
  const methodName = 'functions.api.searchGroups'
  console.log(methodName, '- q:', q)

  const url = `${process.env.REACT_APP_ONEVOTE_API_URL}/searchGroups`

  const response = await fetch(url, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': window.localStorage.getItem('token')
    },
    body: JSON.stringify({
      q,
    })
  })
  // console.log(methodName, '- response:', response)

  const data = await response.json()
  console.log(methodName, '- data:', data)

  return data
}

const createGroup = async (
  createdByUserId, name, displayName, description, imageUrl, createdAt) => {

  const methodName = 'functions.api.createGroup'
  console.log(methodName, '- createdByUserId, name, displayName, description, imageUrl, createdAt:',
    createdByUserId, name, displayName, description, imageUrl, createdAt)

  const url = `${process.env.REACT_APP_ONEVOTE_API_URL}/createGroup`

  const response = await fetch(url, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': window.localStorage.getItem('token')
    },
    body: JSON.stringify({
      createdByUserId,
      name,
      displayName,
      description,
      imageUrl,
      createdAt
    })
  })
  // console.log(methodName, '- response:', response)

  const data = await response.json()
  console.log(methodName, '- data:', data)

  return data
}

const leaveGroup = async (userId, groupId) => {
  const methodName = 'functions.api.leaveGroup'
  console.log(methodName, '- userId, groupId:', userId, groupId)

  const url = `${process.env.REACT_APP_ONEVOTE_API_URL}/leaveGroup`

  const response = await fetch(url, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': window.localStorage.getItem('token')
    },
    body: JSON.stringify({
      userId,
      groupId,
    })
  })
  // console.log(methodName, '- response:', response)

  const data = await response.json()
  console.log(methodName, '- data:', data)

  return data
}

const joinGroup = async (userId, groupId, groupMemberLevelId) => {
  const methodName = 'functions.api.joinGroup'
  console.log(methodName, '- userId, groupId, groupMemberLevelId:', userId, groupId,
    groupMemberLevelId)

  const url = `${process.env.REACT_APP_ONEVOTE_API_URL}/joinGroup`

  const response = await fetch(url, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': window.localStorage.getItem('token')
    },
    body: JSON.stringify({
      userId,
      groupId,
      groupMemberLevelId,
    })
  })
  // console.log(methodName, '- response:', response)

  const data = await response.json()
  console.log(methodName, '- data:', data)

  return data
}

const lookupGroup = async (name) => {
  const methodName = 'functions.api.lookupGroup'
  console.log(methodName, '- name:', name)

  const url = `${process.env.REACT_APP_ONEVOTE_API_URL}/lookupGroup`

  const response = await fetch(url, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': window.localStorage.getItem('token')
    },
    body: JSON.stringify({
      name
    })
  })
  // console.log(methodName, '- response:', response)

  const data = await response.json()
  console.log(methodName, '- data:', data)

  return data
}

const getCredential = async (platformId, platformProps) => {
  const methodName = 'functions.api.getCredential'
  console.log(methodName, '- platformId, platformProps:', platformId, platformProps)

  const url = `${process.env.REACT_APP_ONEVOTE_API_URL}/getCredential`

  const response = await fetch(url, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': window.localStorage.getItem('token')
    },
    body: JSON.stringify({
      platformId,
      platformProps
    })
  })
  // console.log(methodName, '- response:', response)

  const data = await response.json()
  console.log(methodName, '- data:', data)

  return data
}

const getInfo = async (platformId, credential) => {
  const methodName = 'functions.api.getInfo'
  console.log(methodName, '- platformId, credential:', platformId, credential)

  const url = `${process.env.REACT_APP_ONEVOTE_API_URL}/getInfo`

  const response = await fetch(url, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': window.localStorage.getItem('token')
    },
    body: JSON.stringify({
      platformId,
      credential,
    })
  })
  // console.log(methodName, '- response:', response)

  const data = await response.json()
  console.log(methodName, '- data:', data)

  return data
}

const getUsersMixtapeSources = async (platformId, credential) => {
  const methodName = 'functions.api.getUsersMixtapeSources'
  console.log(methodName, '- platformId, credential:', platformId, credential)

  const url = `${process.env.REACT_APP_ONEVOTE_API_URL}/getUsersMixtapeSources`

  const response = await fetch(url, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': window.localStorage.getItem('token')
    },
    body: JSON.stringify({
      platformId,
      credential,
    })
  })
  // console.log(methodName, '- response:', response)

  const data = await response.json()
  console.log(methodName, '- data:', data)

  return data
}

const searchMixtapeSources = async (platformId, credential, q) => {
  const methodName = 'functions.api.searchMixtapeSources'
  console.log(methodName, '- platformId, q, credential:', platformId, q, credential)

  const url = `${process.env.REACT_APP_ONEVOTE_API_URL}/searchMixtapeSources`

  const response = await fetch(url, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': window.localStorage.getItem('token')
    },
    body: JSON.stringify({
      platformId,
      credential,
      q,
    })
  })
  // console.log(methodName, '- response:', response)

  const data = await response.json()
  console.log(methodName, '- data:', data)

  return data
}

const addMixtapeSource = async (platformId, credential, mixtapeSource) => {
  const methodName = 'functions.api.addMixtapeSource'
  console.log(methodName, '- platformId, credential, mixtapeSource:',
    platformId, credential, mixtapeSource)

  const url = `${process.env.REACT_APP_ONEVOTE_API_URL}/addMixtapeSource`

  const response = await fetch(url, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': window.localStorage.getItem('token')
    },
    body: JSON.stringify({
      platformId,
      credential,
      mixtapeSource,
    })
  })
  // console.log(methodName, '- response:', response)

  const data = await response.json()
  console.log(methodName, '- data:', data)

  return data
}

const removeMixtapeSource = async (platformId, credential, mixtapeSource) => {
  const methodName = 'functions.api.removeMixtapeSource'
  console.log(methodName, '- platformId, credential, mixtapeSource:',
    platformId, credential, mixtapeSource)

  const url = `${process.env.REACT_APP_ONEVOTE_API_URL}/removeMixtapeSource`

  const response = await fetch(url, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': window.localStorage.getItem('token')
    },
    body: JSON.stringify({
      platformId,
      credential,
      mixtapeSource,
    })
  })
  // console.log(methodName, '- response:', response)

  const data = await response.json()
  console.log(methodName, '- data:', data)

  return data
}

const getMixtapeSourceTimeline = async (platformId, credential, mixtapeSource) => {
  const methodName = 'functions.api.getMixtapeSourceTimeline'
  console.log(methodName, '- platformId, credential, mixtapeSource:',
    platformId, credential, mixtapeSource)

  const url = `${process.env.REACT_APP_ONEVOTE_API_URL}/getMixtapeSourceTimeline`

  const response = await fetch(url, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': window.localStorage.getItem('token')
    },
    body: JSON.stringify({
      platformId,
      credential,
      mixtapeSource,
    })
  })
  // console.log(methodName, '- response:', response)

  const data = await response.json()
  console.log(methodName, '- data:', data)

  return data
}

const getInitialMixtapeSources = async (platformId) => {
  const methodName = 'functions.api.getInitialMixtapeSources'
  console.log(methodName, '- platformId:', platformId)

  const url = `${process.env.REACT_APP_ONEVOTE_API_URL}/getInitialMixtapeSources`

  const response = await fetch(url, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      // 'Authorization': window.localStorage.getItem('token')
    },
    body: JSON.stringify({
      platformId,
    })
  })
  // console.log(methodName, '- response:', response)

  const data = await response.json()
  console.log(methodName, '- data:', data)

  return data
}

export default {
  // Test
  test,  //auth

  // SignIn
  twitterSignIn,
  youtubeSignIn,

  // Votes (auth)
  voteForPost,  // auth
  voteForPostFromPlatformProps, //auth
  unVoteForPost,  //auth

  // Post-SignIn-Data-Refresh
  getActiveVotes,  // auth
  getDefaultCategories,  // auth
  getElections,

  // Edit Profile
  updateUserData,  // auth

  // Main Views
  getLeaderboard,  // auth
  getElectionWinners, 
  // User Management
  getUser,  // auth
  deleteUser,  // auth

  // Mixtape Source Management (Youtube in youtubeUtils.js bypasses 1voteAPI, News not needed)
  searchOneVoteUsers, // auth
  getUserTimeline,  // auth
  getUsersCurrentlyVotedPosts,  // auth

  // Groups
  getGroups,  // auth
  searchGroups,  // auth
  createGroup,  // auth
  leaveGroup,  // auth
  joinGroup,  // auth
  lookupGroup,  // auth

  // Platform Dependent API
  refreshYoutubeCredential,
  getTwitterRequestToken,
  getTweetInfo,  // auth

  // Platform Independent API
  getCredential,
  getInfo,
  getUsersMixtapeSources,  // auth
  searchMixtapeSources, // auth
  addMixtapeSource, // auth
  removeMixtapeSource, // auth
  getMixtapeSourceTimeline, // auth
  getInitialMixtapeSources,
  
  // Mixtape API
  getMixtape,  // auth
}
