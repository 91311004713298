import React from 'react'
import {
    useRouteMatch,
    Link
} from "react-router-dom"

export default function NavLink(props) {
    let { url } = useRouteMatch()
    const {
        route,
        parent,
        children
    } = props
    
    return (
            <Link to={{
                pathname: `${url}${route.path}`,
                state: {
                    [parent]: {
                        displayName: route.displayName,
                        linkKey: route.linkKey,
                    }
                }
            }}
                style={{ color: 'black'}}
            >
                {children}
            </Link>
    )
}