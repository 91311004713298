import React from 'react'
import Img from 'react-image'
// import twitter from '../../../../../assets/Twitter_Social_Icon_Circle_Color.png'
import btn_twitter_link from '../../../../../assets/btn_twitter_link.svg'
import btn_twitter_signin from '../../../../../assets/btn_twitter_signin.svg'
import twitterUtils from '../../../../../functions/twitterUtils'

const TwitterAuth = (props) => {
  const methodName = 'TwitterAuth.render'
  console.log(methodName, '- props:', props)

  const { src } = props
  return (
    <Img
      className='sign-in-button-image'
      value={"twitter"}
      onClick={twitterUtils.getTwitterAuthorization}
      src={src==="link" ? btn_twitter_link : btn_twitter_signin}
      alt="Twitter link button"
    />

    // <div style={{
    //   display: 'flex',
    //   justifyContent: contentJustification
    // }}>
    //   <button
    //     className={className}
    //     value={"twitter"}
    //     onClick={twitterUtils.getTwitterAuthorization}
    //     style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
    //   >
    //     <img
    //       src={twitter}
    //       alt="sign in with twitter"
    //       title="sign in with twitter"
    //       height="25px"
    //       style={{ marginLeft: '30px' }}
    //     />
    //     <div
    //       style={{
    //         marginRight: '30px',
    //         height: '25px',
    //         lineHeight: '25px',
    //         alignItems: 'center'
    //       }}
    //     >
    //       {message}
    //     </div>
    //   </button>
    // </div>
  )
}

export default TwitterAuth