import enums from '../enums/enums'

const clearLocalStorage = () => {
  const methodName = 'functions.localUtils.clearLocalStorage'
  console.log(methodName)
  window.localStorage.clear()
}

const setCredentials = (credentials) => {
  const methodName = 'functions.localUtils.setCredentials'
  console.log(methodName, '- credentials:', credentials)
  window.localStorage.setItem('credentials', JSON.stringify(credentials))
}

const getCredentials = () => {
  const methodName = 'functions.localUtils.getCredentials'
  console.log(methodName)

  // This should be the new standard for how to write these functions
  const json = window.localStorage.getItem('credentials')
  if (json === null){
    console.log(methodName, '- json is null:', json)
    return {}
  }
  const obj = JSON.parse(json)
  console.log(methodName, '- obj:', obj)
  return obj
}

const setSearchableState = (searchableState) => {
  const methodName = 'functions.localUtils.setSearchableState'
  console.log(methodName, '- searchableState:', searchableState)

  window.localStorage.setItem('searchableState', JSON.stringify(searchableState))
}

const getSearchableState = () => {
  const methodName = 'functions.localUtils.getSearchableState'
  console.log(methodName, '- start')

  const json = window.localStorage.getItem('searchableState')
  if (json === null){
    console.log(methodName, '- json is null:', json)
    return {}
  }
  const obj = JSON.parse(json)
  console.log(methodName, '- obj:', obj)
  return obj
}

const setUser = (user) => {
  const methodName = 'functions.localUtils.setUser'
  console.log(methodName, '- user:', user)
  window.localStorage.setItem('user', JSON.stringify(user))
}

const getUser = () => {
  const methodName = 'functions.localUtils.getUser'
  console.log(methodName)
  return JSON.parse(window.localStorage.getItem('user'))
}

const setToken = (token) => {
  const methodName = 'functions.localUtils.setToken'
  console.log(methodName, '- token:', token)
  window.localStorage.setItem('token', token)
}

const getToken = () => {
  const methodName = 'functions.localUtils.getToken'
  console.log(methodName)
  return window.localStorage.getItem('token')
}

const getTwitterCredential = () => {
  const methodName = 'functions.localUtils.getTwitterCredential'
  console.log(methodName)

  const credentials = getCredentials()
  return credentials[enums.PLATFORMS.TWITTER.name]
}

const getYoutubeCredential = () => {
  const methodName = 'functions.localUtils.getYoutubeCredential'
  console.log(methodName)

  const credentials = getCredentials()
  return credentials[enums.PLATFORMS.YOUTUBE.name]
}

const getRedditCredential = () => {
  const methodName = 'functions.localUtils.getRedditCredential'
  console.log(methodName)

  const credentials = getCredentials()
  return credentials[enums.PLATFORMS.REDDIT.name]
}

const setCampaignAttribution = (codes) => {
  const methodName = 'functions.localUtils.setCampaignAttribution'
  window.localStorage.setItem('campaignAttribution', JSON.stringify(codes))
}

const getCampaignAttribution = () => {
  const methodName = 'functions.localUtils.getCampaignAttribution'
  console.log(methodName)

  const json = window.localStorage.getItem('campaignAttribution')
  if (json === null){
    console.log(methodName, '- json is null:', json)
    return {}
  }
  const obj = JSON.parse(json)
  console.log(methodName, '- obj:', obj)
  return obj
}

export default {
  clearLocalStorage,
  setCredentials,
  getCredentials,
  setSearchableState,
  getSearchableState,
  setUser,
  getUser,
  setToken,
  getToken,
  getTwitterCredential,
  getYoutubeCredential,
  getRedditCredential,
  setCampaignAttribution,
  getCampaignAttribution,
}