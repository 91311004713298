import React, { Fragment } from 'react'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import './../PostCard/PostCard.css'
import UseStyles from './UseStyles'
import NavLink from './NavLink'
import ConditionalWrapper from './ConditionalWrapper'

const ScrollableTabs = (props) => {
  const methodName = 'UserSettingsScrollableTabs.render'
  console.log(methodName, '- props:', props)

  const classes = UseStyles.scrollableTabsUseStyles();
  const { routes, linkKey, parent, onInitializeState, onTabClick, argument, isSignedIn } = props

  const handleChange = (event, newValue) => {
  };

  const onSignOutClick = () => {
    const methodName = 'NavigationIcon.onSignOutClick'
    console.log(methodName)
    onInitializeState()
  }
  console.log(methodName, '- isSignedIn: ', isSignedIn)

  return (
    <div>
      <AppBar 
        position="static" 
        color="default" 
        className={classes.tabBar}
      >
        <Tabs
          value={linkKey}
          onChange={handleChange}
          indicatorColor="primary"
          variant="scrollable"
          scrollButtons="on"
          aria-label="scrollable auto tabs example"
        >
          {
            routes.map((route) => (
              <ConditionalWrapper
                key={route.displayName}
                condition={(parent === 'billboard' || parent==='userSettings')}
                wrapper={children => (
                  <NavLink
                    route={route}
                    parent={parent}
                    children={children}
                  />
                )}
              >
                <Fragment>
                  <Tab
                    style={{background: 'none', width: route.displayName==='Front Page' ? '115px' : 'auto', display:(route.displayName==='SignOut'&&!isSignedIn)?'none':null}}
                    icon={route.icon}
                    label={route.displayName}
                    wrapped={false}
                    onClick={route.displayName==="SignOut" 
                        ? onSignOutClick 
                        : ()=>onTabClick(route[argument]) 
                      }
                  />
                </Fragment>  
              </ConditionalWrapper>
            ))
          }
        </Tabs>
      </AppBar>
    </div>
  );
}

export default ScrollableTabs