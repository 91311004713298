import React from 'react'

import YoutubePostCardHeader from '../Headers/YoutubePostCardHeader'
import Vote from '../../Buttons/Vote'
import CircularImagesAndSmallTextContainer from '../CircularImagesAndSmallTextContainer/CircularImagesAndSmallTextContainer'
import TitleAndDescription from '../TitleAndDescription/TitleAndDescription'
import youtubeUtils from '../../../functions/youtubeUtils'

import defaultYoutubeIcon from '../../../assets/youtubeLogo.svg'

const youtubeSquareImageUrl = 'https://cdn.iconscout.com/icon/free/png-256/youtube-86-226404.png'
const youtubeUrl = 'https://www.youtube.com'
const youtubeDisplayUrl = 'www.youtube.com'

class YoutubePostCard extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        channelThumbnailUrl: ''
      }
    }
  
    componentDidMount = async () => {
      const methodName = 'YoutubePostCard.componentDidMount'
      console.log(methodName, '- props:', this.props)
  
      const { youtubeChannelId } = this.props.platformProps
  
      const data = await youtubeUtils.getYoutubeChannelInfo(youtubeChannelId)
      console.log(methodName, '- data;', data)
      const thumbnailUrl = data.thumbnails ? data.thumbnails.high.url : ''
      this.setState(
        {
          channelThumbnailUrl: thumbnailUrl
        }, () => {
          console.log(methodName, '- state:', this.state)
        }
      )
    }

    render = () => {
      const methodName = 'YoutubePostCard.render'
      console.log(methodName, '- this.props:', this.props)
  
      const {
        platformProps, 
        formatDatetimePublished
      } = this.props
      
      const {
        youtubeChannelId, 
        youtubeChannelTitle, 
        youtubeVideoDescription, 
        youtubeVideoId,
        youtubeVideoTitle, 
        youtubeVideoThumbnailUrl, 
        date
      } = platformProps
      
      const { channelThumbnailUrl } = this.state
  
      const capsText1 = youtubeChannelTitle || ''
      const capsText2 = ''
  
      const embedUrl = `${youtubeUrl}/embed/${youtubeVideoId}?enablejsapi=1&autoplay=1`
      const videoUrl = `${youtubeUrl}/watch?v=${youtubeVideoId}`
      const channelUrl = `${youtubeUrl}/channel/${youtubeChannelId}`
  
      const datetimePublished=formatDatetimePublished(date)
  
      const altText = `YouTube Channel: ${youtubeChannelTitle}`
  
      return (
        <div className="post">
          <YoutubePostCardHeader 
            youtubeVideoUrl={videoUrl} 
            youtubeEmbedUrl={embedUrl} 
            postTitle={youtubeVideoTitle} 
            youtubePreviewContentSource={[youtubeVideoThumbnailUrl, channelThumbnailUrl]}
  
          />
          <CircularImagesAndSmallTextContainer
            {...this.props}
            imagesContainerLinkUrl1={youtubeUrl}
            imagesContainerLinkUrl2={channelUrl}
            imagesContainerImgOptions1={youtubeSquareImageUrl}
            imagesContainerImgOptions2={[channelThumbnailUrl, defaultYoutubeIcon]}
            imagesContainerAltText1={youtubeDisplayUrl}
            imagesContainerAltText2={altText}
            textContainerLinkUrl1={channelUrl}
            textContainerLinkUrl2={channelUrl}
            textContainerText1={capsText1}
            textContainerText2={capsText2}
          />
          <TitleAndDescription 
            linkUrl={videoUrl}
            datetimePublished={datetimePublished}
            postTitle={youtubeVideoTitle}
            description={youtubeVideoDescription}
          />
          <Vote props={this.props} />          
        </div>
      )
    }
  }

  export default YoutubePostCard