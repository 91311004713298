import React from 'react'

import SmallCircularImage from './SmallCircularImage'

import '../PostCard.css'

const SmallCircularImagesContainer = (props) =>{
    const methodName = 'SmallCircularImagesContainer.render'
    console.log(methodName, '- props:', props)
    const {
        linkUrl1,
        linkUrl2,
        linkUrl3,
        imgOptions1,
        imgOptions2,
        imgOptions3,
        altText1,
        altText2,
        altText3
    } = props

    return(
        <div className="post-circle-images-container">
            <SmallCircularImage 
                linkUrl={linkUrl1} 
                imgOptions={imgOptions1} 
                altText={altText1}
            />
            <SmallCircularImage 
                linkUrl={linkUrl2} 
                imgOptions={imgOptions2} 
                altText={altText2}
            />
            {!!linkUrl3
                ? <SmallCircularImage 
                    linkUrl={linkUrl3} 
                    imgOptions={imgOptions3} 
                    altText={altText3}
                />
                : null
            }
        </div>

    )
}

export default SmallCircularImagesContainer