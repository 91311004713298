import React from 'react'
import LazyLoad from 'react-lazyload'
import Img from 'react-image'
import '../PostCard.css'
import RectangularSpinner from '../../UXElements/RectangularSpinner'
import youtubePlayRed from '../../../assets/youtube_social_icon_red.png'
import youtubePlayDark from '../../../assets/youtube_social_icon_dark.png'

class YoutubePostCardHeader extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      videoClicked: false,
      videoMouseOver: false,
    }
  }

  componentDidMount = async () => {
    const methodName = 'YoutubePostCardHeader.componentDidMount'
    console.log(methodName, '- props:', this.props)
  }

  onMouseEnter = () => {
    this.setState({ videoMouseOver: true })
  }

  onMouseLeave = () => {
    this.setState({ videoMouseOver: false })
  }

  render = () => {
    const methodName = 'RedditPostCard.render'
    console.log(methodName, '- this.props:', this.props)

    const {
      videoClicked, videoMouseOver
    } = this.state

    const {
      youtubeVideoUrl, youtubeEmbedUrl, youtubePreviewContentSource, postTitle
    } = this.props

    return (
      <div className="post">

        {/* LARGE RECTANGULAR IMAGE */}

        {videoClicked
          ? <div>
            <a
              href={youtubeVideoUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              <iframe
                src={youtubeEmbedUrl}
                id="ytplayer"
                className="rectangle-iframe"
                title={postTitle}
                allow={"fullscreen"}>
              </iframe>
            </a>
          </div>
          :
          <div className={'youtube-image-container'}>
            <LazyLoad height={200} offset={100} once={true}>
              <Img
                src={youtubePreviewContentSource}
                alt={postTitle}
                title={postTitle}
                onMouseEnter={this.onMouseEnter}
                onMouseLeave={this.onMouseLeave}
                className="rectangle-image"
                loader={<RectangularSpinner/>}
                onClick={() => this.setState(
                  { videoClicked: true },
                  () => console.log(methodName, '- setState complete'))}
              />
            </LazyLoad>
            <LazyLoad height={45} offset={100} once={true}>
              <Img
                src={videoMouseOver ? youtubePlayRed : youtubePlayDark}
                onMouseEnter={this.onMouseEnter}
                style={{ opacity: videoMouseOver ? 1 : 0.8 }}
                className={'youtube-play'}
                onClick={() => this.setState(
                  { videoClicked: true },
                  () => console.log(methodName, '- setState complete'))}
              />
            </LazyLoad>
          </div>
        }
      </div>
    )
  }
}

export default YoutubePostCardHeader