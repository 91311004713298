import React from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import AddIcon from '@material-ui/icons/Add'
import enums from '../../enums/enums'

const SubscribeButton = (props) => {
  const { onSubscribe, searchableType, isSignedIn, toggleVoteModal } = props
  let text = ''

  if (searchableType.id === enums.SEARCHABLE_TYPE.ONEVOTE_GROUP.id) {
    text = `Add to your groups`
  } else {
    text = `Subscribe on 1vote and ${searchableType.platform.displayName}`
  }

  return (
    <div className={'mixtape-source-button-2-sub-unsub'}>
      <Tooltip title={text}>
        {(isSignedIn === false) ? (<AddIcon
            alt={text}
            style={{ fontSize: 35 }}
            onClick={() => toggleVoteModal({})}
          />) : (
          <AddIcon
            alt={text}
            style={{ fontSize: 35 }}
            onClick={() => onSubscribe()}
          />
          )}
      </Tooltip>
    </div>
  )
}

export default SubscribeButton