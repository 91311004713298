import React from 'react'

import DefaultPostCardHeader from '../Headers/DefaultPostCardHeader'
import Vote from '../../Buttons/Vote'
import CircularImagesAndSmallTextContainer from '../CircularImagesAndSmallTextContainer/CircularImagesAndSmallTextContainer'
import TitleAndDescription from '../TitleAndDescription/TitleAndDescription'

import api from '../../../functions/api'
import localUtils from '../../../functions/localUtils'

import twitterSquareImageUrl from '../../../assets/Twitter_Logo_Blue.svg'

const rectangularImageUrl = 'https://media.wired.com/photos/5941a9186600f013e9634520/191:100/w_1910,h_1000,c_limit/Twitter-FeatureArt.jpg'
const twitterUrl = 'https://www.twitter.com'
const twitterDisplayUrl = 'www.twitter.com'

class TwitterPostCard extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        bodyText: 'Currently Unavailable',
        authorName: '',
        authorScreenName: '',
        authorBannerImage: '',
        authorProfileImage: '',
        imageUrl: '',
        retweetText: '',
        retweetAuthorScreenName: '',
        datetimePublished: '',
        isVerified: false,
      }
    }
  
    componentDidMount = async () => {
      const methodName = 'TwitterPostCard.componentDidMount'
      console.log(methodName, '- props:', this.props)
  
      const { twitterPostId } = this.props.platformProps
      const { formatDatetimePublished } = this.props
      const credential = localUtils.getTwitterCredential()
  
      const data = await api.getTweetInfo(twitterPostId, credential)
      console.log(methodName, '- data:', data)
  
      const imageUrl = (!data.extended_entities
        ? null
        : data.extended_entities.media[0].media_url_https)
  
      let retweetText, retweetAuthorScreenName
      if (data.retweeted_status) {
        retweetText = data.retweeted_status.text
        retweetAuthorScreenName = data.retweeted_status.user.screen_name
      }
  
      if (!data.user) {
        return
      }
      let datetimePublished=formatDatetimePublished(data.created_at)
  
      this.setState(
        {
          bodyText: data.full_text,
          authorName: data.user.name,
          authorScreenName: data.user.screen_name,
          authorBannerImage: data.user.profile_banner_url,
          authorProfileImage: data.user.profile_image_url_https,
          imageUrl: imageUrl,
          retweetText: retweetText,
          retweetAuthorScreenName: retweetAuthorScreenName,
          datetimePublished: datetimePublished,
          isVerified: data.user.verified
        },
        () => {
          console.log(methodName, '- state:', this.state)
        }
      )
    }
  
    render = () => {
      const methodName = 'TwitterPostCard.render'
      console.log(methodName, '- this.props:', this.props)
  
      const {
        platformProps,
      } = this.props
  
      const { twitterPostId } = platformProps

      const {
        bodyText, 
        authorName, 
        authorScreenName, 
        authorBannerImage,
        authorProfileImage, 
        imageUrl, 
        retweetText,
        retweetAuthorScreenName, 
        datetimePublished, 
        isVerified
      } = this.state
  
      const capsText1 = authorName || ''
      const capsText2 = authorScreenName !== '' ? '@' + authorScreenName : ''
  
      let postTitle = `@${authorScreenName} Tweets:`
      let text = bodyText
      if (!!retweetText) {
        postTitle = `@${authorScreenName} Retweets @${retweetAuthorScreenName}:`
        text = retweetText
      }
  
      const postUrl = authorScreenName !== '' ? `https://twitter.com/${authorScreenName}/status/${twitterPostId}` : `https://twitter.com/`
      const twitterAuthorProfileUrl = `https://twitter.com/${authorScreenName}`
  
      return (
        <div className="post">
          <DefaultPostCardHeader 
            postUrl={postUrl} 
            postTitle={postTitle}
            contentSource={[imageUrl, authorBannerImage, rectangularImageUrl]}
          />
          <CircularImagesAndSmallTextContainer
            {...this.props}
            imagesContainerLinkUrl1={twitterUrl}
            imagesContainerLinkUrl2={twitterAuthorProfileUrl}
            imagesContainerImgOptions1={twitterSquareImageUrl}
            imagesContainerImgOptions2={authorProfileImage}
            imagesContainerAltText1={twitterDisplayUrl}
            imagesContainerAltText2={capsText2}
            textContainerLinkUrl1={twitterAuthorProfileUrl}
            textContainerLinkUrl2={twitterAuthorProfileUrl}
            textContainerText1={capsText1}
            textContainerText2={capsText2}
            isVerified={isVerified}
          />
          <TitleAndDescription 
            linkUrl={postUrl}
            datetimePublished={datetimePublished}
            postTitle={postTitle}
            description={text}
          />
          <Vote props={this.props} />
        </div>
      )
    }
  }

  export default TwitterPostCard