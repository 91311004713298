import React from 'react'
import LazyLoad from 'react-lazyload'
import Img from 'react-image'
import RectangularSpinner from '../../UXElements/RectangularSpinner'

import '../PostCard.css'

export default function DefaultPostCardHeader(props) {
  const methodName = 'DefaultPostCardHeader.render'
  console.log(methodName, '- props:', props)

    const {
      postUrl,
      postTitle,
      contentSource,
    } = props

    return (
      <div>

        <a
          href={postUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          <LazyLoad height={200} offset={100} once={true}>
            <Img
              src={contentSource}
              className="rectangle-image"
              alt={postTitle}
              title={postTitle}
              loader={<RectangularSpinner/>}
            />
          </LazyLoad>
        </a>

      </div>
    )
  }

