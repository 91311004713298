import React from 'react';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';
import Img from 'react-image'
import { Link } from "react-router-dom"

import enums from '../../enums/enums'

import './NavigationBar.css'
import defaultProfileIcon from '../../assets/default_profile.png'

class NavigationIcon extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownOpen: false
    };
  }

  toggle = () => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }

  onSignOutClick = () => {
    const methodName = 'NavigationIcon.onSignOutClick'
    console.log(methodName, this.props)
    this.props.onInitializeState()
  }

  render() {
    // const methodName = 'NavigationIcon.render'
    const { user, isSignedIn } = this.props
    const { dropdownOpen } = this.state
    return (
      <div style={{ width: '50px', marginLeft: '10px' }}>
        <Dropdown
          isOpen={dropdownOpen}
          toggle={this.toggle}
          id="oneVoteUserAvatarToggle"
          className="dropdownMenu"
        >
          <DropdownToggle
            tag="span"
            onClick={this.toggle}
            data-toggle="dropdown"
            aria-expanded={dropdownOpen}
          >
            <Img
              src={[user.imageUrl, defaultProfileIcon]}
              alt="avatar"
              style={{ borderRadius: '100%', display: "inline-block", borderStyle: 'solid', borderWidth: '1px', height: '50px' }}
              className="user-av"
            />
          </DropdownToggle>
          <DropdownMenu
            style={{
              backgroundColor: 'rgba(255,255,255,0.85)',
              marginLeft: '-60px',
              borderColor: 'transparent',
              boxShadow: '4px 4px 8px 0px rgba( 0, 0, 0, 0.2 )',
            }}
          >

            <Link 
            to={`/home/${enums.DEFAULT_ELECTION_TYPES[0].name}`}
            >
              <DropdownItem className="">Elections</DropdownItem>

            </Link>
            <Link to="/feed">
              <DropdownItem className="" id="oneVoteMixtapeButton">Feed</DropdownItem>
            </Link>
            <Link to="/userSettings">
              <DropdownItem className="" id="oneVoteSettingsButton">Settings</DropdownItem>
            </Link>
            <Link to={`/1vote_user/${user.id}`}>
              <DropdownItem className=""  id="oneVoteUsersButton">Profile</DropdownItem>
            </Link>
            {isSignedIn
              ?   <Link to="/" onClick={this.onSignOutClick}>
                    <DropdownItem className="" id="oneVoteSignOutButton">Sign Out</DropdownItem>
                  </Link>
              : null
            }
          </DropdownMenu>
        </Dropdown>
      </div>
    )
  }
}

export default NavigationIcon
