import React from 'react'

import PostCard from '../PostCard/PostCard'
import localUtils from '../../functions/localUtils'
import YoutubeAuth from '../Settings/PlatformSettings/YoutubeSettings/YoutubeAuth/YoutubeAuth'
import TwitterAuth from '../Settings/PlatformSettings/TwitterSettings/TwitterAuth/TwitterAuth'
import RedditAuth from '../Settings/PlatformSettings/RedditSettings/RedditAuth/RedditAuth'

import interactionUtils from '../../functions/interactionUtils'

import '../PostCardList/PostCardList.css'

class MixtapePostCardList extends React.Component {
  constructor(props) {
    const methodName = 'MixtapePostCardList.constructor'
    console.log(methodName, '- start')
    // console.log(methodName, '- props:', props)
    super(props)
  }

  render() {
    const methodName = 'MixtapePostCardList.render'
    console.log(methodName, '- start')
    // console.log(methodName, this.props, this.state)

    const { posts, filteredPlatformId, subscribe, unsubscribe, user, isSignedIn, onVoteChange, toggleVoteModal } = this.props
    console.log(methodName, '- posts.length:', posts.length)
    console.log(methodName, '- filteredPlatformId:', filteredPlatformId)

    return (
      <div className="postCardListContainer">
        {
          (filteredPlatformId === 1 && !localUtils.getTwitterCredential())
            ? 
            <div>
              <h6>Add Twitter content to your Mixtape</h6>
              <TwitterAuth
                src={isSignedIn ? "link" : "signIn"}
              />
            </div>
            : (filteredPlatformId === 3 && !localUtils.getYoutubeCredential())
            ? 
            <div>
              <h6>Add YouTube content to your Mixtape</h6>
              <YoutubeAuth
                src={isSignedIn ? "link" : "signIn"}
                />
            </div>
            : (filteredPlatformId === 4 && !localUtils.getRedditCredential())
              ? isSignedIn ?
                <div>
                  <h6>Add Reddit content to your Mixtape</h6>
                  <RedditAuth
                    src="link"
                  />
                </div>
              : <div>
                  <h6>Sign In to add Reddit content to your Mixtape</h6>
                  {/* <RedditAuth
                    src="link"
                  /> */}
                </div>
              :
              <div className="postCardList">
                {
                  posts.map((post, i) => {
                    if (filteredPlatformId === 0 || filteredPlatformId === post.platformId) {
                      const subscriptionAndLinkageStatus = interactionUtils.mixtapeSourceSubscriptionAndLinkageStatus(post, user)
                      const { userIsSubscribed, isPlatformLinked } = subscriptionAndLinkageStatus
                      return (
                        <PostCard
                          key={post.key}
                          postId={post.postId}
                          platformId={post.platformId}
                          platformProps={post.platformProps}
                          voteCount={post.voteCount}
                          postIsVoted={post.postIsVoted}
                          toggleVoteModal={toggleVoteModal}
                          onVoteChange={onVoteChange}
                          user={user}
                          subscribe={subscribe}
                          unsubscribe={unsubscribe}
                          userIsSubscribed={userIsSubscribed}
                          isPlatformLinked={isPlatformLinked}
                          isSignedIn={isSignedIn}
                        />
                      )
                    } else {
                      return null
                    }
                  })
                }
              </div>
        }
      </div>
    )
  }
}

export default MixtapePostCardList