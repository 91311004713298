import React from 'react'

import SmallText from './SmallText'

import '../PostCard.css'

const SmallTextContainer = (props) =>{
    const methodName = 'SmallTextContainer.render'
    console.log(methodName, '- props:', props)
    const {
        linkUrl1,
        linkUrl2,
        text1,
        text2,
        isVerified
    } = props

    return(
        <div className="post-small-text-container">
            <SmallText 
                linkUrl={linkUrl1} 
                text={text1}
                isVerified={isVerified}
            />
            <SmallText 
                linkUrl={linkUrl2} 
                text={text2}
            />
        </div>
    )
}

export default SmallTextContainer