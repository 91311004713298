import api from './api'
import ReactGA from 'react-ga'


const getTwitterAuthUrl = async () => {
  const methodName = 'functions.twitterUtils.getTwitterAuthUrl'
  console.log(methodName)

  const tokens = await api.getTwitterRequestToken()

  const authUrl = 'https://twitter.com/oauth/authenticate?oauth_token=' + tokens.requestToken
  console.log(methodName, '- authUrl:', authUrl)

  return authUrl
}

const getTwitterAuthorization = async () => {
  const methodName = 'functions.twitterUtils.getTwitterAuthorization'
  console.log(methodName)

  const authUrl = await getTwitterAuthUrl()
  console.log(methodName, '- authUrl:', authUrl)

  ReactGA.pageview('/authHandler/twitter')

  try {
    window.open(authUrl, "_self")
  }
  catch (error) {
    console.error('error getting authorization from twitter:', error.message)
  }
}

export default {
  getTwitterAuthorization,
}
