import React from 'react'
import { Route, useRouteMatch } from 'react-router-dom'
import ReactGA from 'react-ga'

import TwitterAuthHandler from './TwitterAuthHandler'
import YoutubeAuthHandler from './YoutubeAuthHandler'
import RedditAuthHandler from './RedditAuthHandler'

import localUtils from '../../functions/localUtils'
import api from '../../functions/api'

const AuthHandler = (props) => {
  let { path } = useRouteMatch()
  const handleSignInResponse = (signInResponse, pageView, provider)=>{
    
    const { credentials, token, user } = signInResponse

    const { setUser, refreshUserData } = props
    
    handleGAConversionEvent(pageView, provider)
    
    localUtils.setToken(token)
    localUtils.setCredentials(credentials)
    
    setUser(user, false)

    refreshUserData(user, credentials)
  }

  const handleGAConversionEvent = (pageView, provider) =>{
    //GA conversion event
    const localUser = localUtils.getUser()

    ReactGA.pageview(`/authHandler/${pageView}`)
    ReactGA.event({
      category: 'user',
      action: 'auth',
      label: `provider: ${provider}, campaign:`+localUtils.getCampaignAttribution().campaignId,
    })
  }

  const getPlatformInfo = async(platformId, credential)=>{
    const methodName = 'getPlatformInfo'

    const info = await api.getInfo(platformId, credential)
    console.log(methodName, '- userInfo:', info)

    const mixtapeSources = await api.getUsersMixtapeSources(platformId, credential)
    console.log(methodName, '- mixtapeSources:', mixtapeSources)

    const platform = { info, mixtapeSources }
    console.log(methodName, '- platform:', platform)
    return platform
  }

  const handleSignIn = async(
    credentials,
    platformId, 
    platformProps, 
    platformName, 
    provider, 
    otherSignInPlatformName, 
    pageView,
    signInMethod
  ) =>{
    const methodName = `${platformName}AuthHandler - handleSignIn`

    const { setCredential, setPlatformState } = props

    const credential = await api.getCredential(platformId, platformProps)
    console.log(methodName, '- credential:', credential)

    const platform = await getPlatformInfo(platformId, credential)

    if (!!credentials[otherSignInPlatformName]) {
      await setCredential(platformName, credential)

      setPlatformState(platformName, platform)
    } else {
      const signInResponse = await signInMethod(platformName, platform, credential)
      console.log(methodName, '- signInResponse:', signInResponse)
      
      handleSignInResponse(signInResponse, pageView, provider)
    }
  }

  return (
    <div>
      <Route
        path={`${path}/twitter`}
        render={(routeProps) => <TwitterAuthHandler {...routeProps}
          setUser={props.setUser}
          refreshUserData={props.refreshUserData}
          setPlatformState={props.setPlatformState}
          setCredential={props.setCredential}
          isSignedIn = {props.isSignedIn}
          handleSignIn={handleSignIn}
        />}
      />
      <Route
        path={`${path}/youtube`}
        render={(routeProps) => <YoutubeAuthHandler {...routeProps}
          setPlatformState={props.setPlatformState}
          setCredential={props.setCredential}
          setUser={props.setUser}
          refreshUserData={props.refreshUserData}
          isSignedIn = {props.isSignedIn}
          handleSignIn={handleSignIn}
        />}
      />
      <Route
        path={`${path}/reddit`}
        render={(routeProps) => <RedditAuthHandler {...routeProps}
          setPlatformState={props.setPlatformState}
          setCredential={props.setCredential}
        />}
      />
    </div>
  )
}

export default AuthHandler