import React, { Component } from 'react'

import AuthHandlerBody from './AuthHandlerBody'

import localUtils from '../../functions/localUtils'
import api from '../../functions/api'
import enums from '../../enums/enums'

class YoutubeAuthHandler extends Component {
  constructor(props) {
    const methodName = 'YoutubeAuthHandler.constructor'
    console.log(methodName, '- props,', props)
    super(props)
  }

  componentDidMount = async () => {
    const methodName = 'YoutubeAuthHandler.componentDidMount'
    console.log(methodName, '- props:', this.props)

    const { handleSignIn } = this.props
    const platformId = enums.PLATFORMS.YOUTUBE.id

    const urlParams = new URLSearchParams(window.location.search)
    const code = urlParams.get('code')
    const state = urlParams.get('state')
    console.log(methodName, '- state, code:', state, code)

    const credentials = await localUtils.getCredentials() || {}
    console.log(methodName, '- credentials:', credentials)

    const platformName='youtube'
    const provider='google'
    const otherSignInPlatformName='twitter'
    const signInMethod = api.youtubeSignIn
    const pageView = 'YoutubeAuthHandler'

    const hasNeededInfo = code && state === platformName

    if (hasNeededInfo) {
      console.log(methodName, `- ${platformName} code present!`)
      const platformProps = { code }

      handleSignIn(credentials, platformId, platformProps, platformName, provider, otherSignInPlatformName, pageView, signInMethod)
    }
  }

  render() {
    const methodName = 'YoutubeAuthHandler.render'
    console.log(methodName, '- props:', this.props)

    return <AuthHandlerBody platformName='youtube'/>
  }
}

export default YoutubeAuthHandler