import React from 'react'
import LazyLoad from 'react-lazyload'
import Img from 'react-image'

import '../PostCard.css'

const SmallCircularImage = (props) =>{
    const methodName = 'SmallCircularImage.render'
    console.log(methodName, '- props:', props)
  
      const {
        linkUrl,
        imgOptions,
        altText,
      } = props

    return(

    <a
        href={linkUrl}
        target="_blank"
        rel="noopener noreferrer"
    >
        <LazyLoad height={40} offset={100} once={true}>
            <Img
                src={imgOptions}
                className="circle-image"
                alt={altText}
                title={altText}
            />
        </LazyLoad>
    </a>
    )
}

export default SmallCircularImage