
import React from 'react';
import ReactSpinner from 'react-bootstrap-spinner';

const CircularSpinner = () => {
    return (
      <div style={{width: '40px', height: '40px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <ReactSpinner type="border" color="primary" size="1" />
      </div>
    )
  }

export default CircularSpinner  