import React, { Component } from 'react'
import './VoteModal.css'
import VoteModalRow from './VoteModalRow'
import SignInModal from '../SignIn/SignInModal'
import enums from '../../enums/enums'

class VoteModal extends Component {
  constructor(props) {
    super(props)
    const methodName = 'VoteModal.constructor'
    console.log(methodName, '- start')
    this.state = {
      electionType: enums.DEFAULT_ELECTION_TYPES[0],
    }

    this.handleKeyUp = this.handleKeyUp.bind(this)
    this.handleOutsideClick = this.handleOutsideClick.bind(this)
  }

  componentDidMount = async () => {
    const methodName = 'VoteModal.componentDidMount'
    console.log(methodName, '- start')

    window.addEventListener('keyup', this.handleKeyUp, false)
    document.addEventListener('click', this.handleOutsideClick, false)
  }

  getElectionsAndVoteStatuses = () => {
    const methodName = "VoteModal.getElectionsAndVoteStatuses"
    console.log(methodName, '- start')

    const { user, voteModalState } = this.props
    console.log(methodName, '- user, voteModalState:', user, voteModalState)

    const { postProps } = voteModalState
    const { activeVotes, elections } = user
    console.log(methodName, '- postProps, activeVotes, elections:', postProps, activeVotes, elections)

    const out = elections.map((election, i) => {
      const itStr = `- Iteration ${i}`
      console.log(methodName, '- election:', election)

      const { id: electionId, name: electionName} = election
      console.log(methodName, itStr, '- electionId, electionName:', electionId, electionName)
      
      const vote = activeVotes[electionId] || {}
      console.log(methodName, itStr, '- vote:', vote)

      const { postId: votePostId } = vote
      console.log(methodName, itStr, '- votePostId, typeof votePostId:', votePostId, typeof votePostId)

      const { postId: postPostId } = postProps
      console.log(methodName, itStr, '- postPostId, typeof postPostId:', postPostId, typeof postPostId)

      let isVoted = (votePostId === postPostId)
      console.log(methodName, itStr, '- isVoted:', isVoted)
      return {
        ...election,
        key: election.id,
        isVoted: isVoted
      }
    })
    console.log(methodName, '- out:', out)
    const electionTypeElections = out.filter(election=>{
      console.log(methodName, '- election', election.electionTypeId===postProps.electionTypeId, !!!postProps.electionTypeId, typeof postProps.electionTypeId)
      return (election.electionTypeId===postProps.electionTypeId || typeof postProps.electionTypeId==='undefined')
    })
    console.log(methodName, '- electionTypeElections', electionTypeElections)
    return electionTypeElections
  }

  // Remove listeners immediately before a component is unmounted and destroyed.
  componentWillUnmount = () => {
    const methodName = "VoteModal.componentWillUnmount"
    console.log(methodName, '- start')

    window.removeEventListener('keyup', this.handleKeyUp, false)
    document.removeEventListener('click', this.handleOutsideClick, false)
  }

  handleKeyUp = (e) => {
    const methodName = "VoteModal.componentWillUnmount"
    console.log(methodName, '- start')

    const keys= {
      27: () => {
        e.preventDefault()
        this.onModalClose('')
        window.removeEventListener('keyup', this.handleKeyUp, false)
      },
    }
    if (keys[e.keyCode]) {
      keys[e.keyCode]()
    }
  }
  // Handle the mouse click on browser window.
  handleOutsideClick = (e) => {
    const methodName = 'voteModal.handleOutsideClick'
    console.log(methodName, '- start')

    if (!(this.modal === null && this.modal === undefined)) {
      const { className } = e.target
      console.log(methodName, '- className:', className)

      const isVoteModal = className === 'voteModal'
      console.log(methodName, '- isVoteModal:', isVoteModal)

      if (isVoteModal) {
        this.onModalClose('')
        document.removeEventListener('click', this.handleOutsideClick, false)
      }
    }
  }

  onModalClose = async () => {
    const methodName = 'voteModal.onModalClose'
    console.log(methodName, '- start')

    const { toggleVoteModal } = this.props
    toggleVoteModal('')
  }


  onElectionTypeSelect = (e) => {
    const methodName = 'VoteModal.onElectionTypeSelect'
    console.log(methodName, '- start')

    const electionTypeId = parseInt(e.target.value)
    console.log(methodName, '- electionTypeId:', electionTypeId)

    this.setState({
      electionType: enums.DEFAULT_ELECTION_TYPES[electionTypeId]
    }, ()=> console.log(methodName, 'this.state.electionType', this.state.electionType))
  }

  render() {
    const methodName = 'VoteModal.render'
    console.log(methodName, '- start')

    const { 
      user, 
      voteModalState, 
      isSignedIn,
      updateActiveVotes,
      refreshActiveVotes,
      updatePostProps,
      toggleVote
    } = this.props
    const { electionType } = this.state;
    const { id } = electionType
    const activeVotes = user.activeVotes
    const modalElections = this.getElectionsAndVoteStatuses()

    console.log(methodName, '- modalElections, activeVotes, voteModalState:',
      modalElections, activeVotes, voteModalState)

    return (
      (isSignedIn) ?
      <div className="voteModal" ref={node => (this.modal = node)}>
        <article className="voteModalBody">
          <div className="voteModalHeader">
           <select
              className="ElectionTypeSelect"
              onChange={this.onElectionTypeSelect}
              value={id}
            >
              <option className="ElectionTypeOption" value="0">News</option>
              <option className="ElectionTypeOption" value="1">Video</option>
              {/* <option className="ElectionTypeOption" value="2">Lifestyle</option>
              <option className="ElectionTypeOption" value="3">People</option> */}
            </select>
            <p
              className="modalClose"
              onClick={() => this.onModalClose('')}
            >
              &times;
            </p>
          </div>
          <div className="electionsContainer">
          {
            modalElections.map((modalElection) => {
              if(modalElection.electionTypeId===id)
              {
                  return (
                    <VoteModalRow
                      displayName={modalElection.displayName}
                      isVoted={modalElection.isVoted}
                      voteModalState={voteModalState}
                      activeVotes={activeVotes}
                      key={modalElection.id}
                      user={user}
                      updateActiveVotes={updateActiveVotes}
                      refreshActiveVotes={refreshActiveVotes}
                      updatePostProps={updatePostProps}
                      election={modalElection}
                      toggleVote={toggleVote}
                    />
                )
              } else {
                return null
              }
            })
          }
          </div>
        </article>
      </div>
        :
        <div className="voteModal" ref={node => (this.modal = node)}>
          <article
            className="voteModalBody">
            <div className="voteModalHeader">
              <p
                className="modalClose"
                onClick={() => this.onModalClose('')}
              >
                &times;
              </p>
            </div>
            <div className="voteModalSignInBody">
            <SignInModal
              onModalClose={this.onModalClose}
            />
            </div>
          </article>
        </div>
          )
  }
}

export default VoteModal

