import React, { Component } from 'react'
import {
  Link,
  Redirect
} from "react-router-dom"
import SearchBar from './SearchBar'
import SearchIcon from '@material-ui/icons/Search'
import './Search.css'
import enums from '../../enums/enums'

class Search extends Component {
  constructor(props) {
    super(props)
    this.state = {
      searchableType: enums.SEARCHABLE_TYPE.TWITTER_USER,
      searchField: '',
      hasSearchBeenMade: false
    }
  }

  onSearchableSelect = (e) => {
    const methodName = 'Search.onSearchableSelect'
    console.log(methodName, '- start')

    const searchableTypeId = parseInt(e.target.value)
    console.log(methodName, '- searchableTypeId:', searchableTypeId)

    this.setState({
      searchableType: enums.getSearchableTypeById(searchableTypeId)
    })
  }

  onSearchChange = (event) => {
    this.setState({ searchField: event.target.value })
  }

  onSearchClick = async () => {
    this.setState({ hasSearchBeenMade: true }, () => {
      this.setState({ hasSearchBeenMade: false })
    })
  }

  render() {
    const methodName = 'Search.render'
    console.log(methodName, '- props, state:', this.props, this.state)

    const { handleKeyPress } = this.props
    const { hasSearchBeenMade, searchableType, searchField } = this.state
    const { id: searchableTypeId, settingsPagePath } = searchableType
    return (
      hasSearchBeenMade
        ?
        <Redirect to={{
          pathname: settingsPagePath,
          state: {
            searchField,
          },
        }}
        />
        :
        <div className="Search">
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <select
              className="SearchableSelect"
              onChange={this.onSearchableSelect}
              value={searchableTypeId}
            >
              <option className="SearchableSelectOption" value="1">Twitter</option>
              <option className="SearchableSelectOption" value="2">News</option>
              <option className="SearchableSelectOption" value="3">YouTube</option>
              <option className="SearchableSelectOption" value="4">Reddit</option>
              {/* <option className="SearchableSelectOption" value="5">1vote</option> */}
              {/* <option className="SearchableSelectOption" value="6">Groups</option> */}
            </select>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '90%'
              }}
            >
              <SearchBar
                onChange={this.onSearchChange}
                handleKeyPress={handleKeyPress}
                onSearchClick={this.onSearchClick}
                searchField={searchField}
                searchableType={searchableType}
                displayBorder={'none'}
                displayWidth={'100%'}
              />
              <Link
                to={{
                  pathname: settingsPagePath,
                  state: {
                    searchField: searchField,
                  }
                }}
                style={{ width: '40px', outline: 'none' }}
              >
                <button
                  onClick={this.onSearchClick}
                  style={{
                    height: '100%',
                    padding: '0',
                    border: 'none',
                    background: 'none'
                  }}
                >
                  <SearchIcon/>
                </button>
              </Link>
            </div>
          </div>
        </div>)
  }

}

export default Search
