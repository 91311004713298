import React from 'react'
import LazyLoad from 'react-lazyload'
import ReactSpinner from 'react-bootstrap-spinner'

import '../../PostCard.css'

const RectangularSpinner = () => {
  return (
    <div className="spinner-rectangular">
      <ReactSpinner type="border" color="primary" size="5"/>
    </div>
  )
}

export default function GifvPostCardHeader(props) {
  const methodName = 'GifvPostCardHeader.render'
  console.log(methodName, '- props:', props)

  const {
    postUrl,
    contentSource,
  } = props

  return (
    <div>

      <a
        href={postUrl}
        target="_blank"
        rel="noopener noreferrer"
      >
        <LazyLoad offset={100} once={true}>
          <div className="rectangle-image-reddit">
            <div>
              <div style={{
                backgroundColor: 'whitesmoke',
                left: 0,
                height: 0,
                position: 'relative',
                paddingBottom: '100%'
              }}>
                <video loop
                       muted
                       webkit-playsinline=""
                       autoPlay
                       loader={<RectangularSpinner/>}
                       style={{
                         top: 0,
                         left: 0,
                         width: '100%',
                         height: '100%',
                         position: 'absolute'
                       }}>Your
                  browser does not support HTML5 video.
                  <source src={contentSource}
                          type="video/mp4"/>
                </video>
              </div>
            </div>
          </div>
        </LazyLoad>
      </a>

    </div>
  )
}

