import React from 'react'
import YoutubePostCardHeader from '../YoutubePostCardHeader'
import GifvPostCardHeader from './GifvPostCardHeader'
import ImagePostCardHeader from './ImagePostCardHeader'
import DefaultPostCardHeader from '../DefaultPostCardHeader'

import redditRectangularImageUrl from '../../../../assets/reddit_postcard_banner.png'

const youtubeUrl = 'https://www.youtube.com'

const RedditPostCardHeaders = (props) => {
  const methodName = 'RedditPostCardHeaders.render'
  console.log(methodName, '- props:', props)

  const {
    postUrl, linkUrl, linkDomain, postTitle, mediaThumbnailUrl, redditVideoFallbackUrl, thumbnail, subredditBannerUrl
  } = props

  const fileExtension = linkUrl.split(/[#?]/)[0].split('.').pop().trim()
  const imageLinkUrl = (fileExtension === ('gif') || fileExtension === ('jpg') || fileExtension === ('png')) ? linkUrl : ''

  const defaultContentSource = [imageLinkUrl, mediaThumbnailUrl, thumbnail, subredditBannerUrl, redditRectangularImageUrl]
  const imgurFallbackContentSource = [linkUrl + '.png'].concat(defaultContentSource)
    
  const youtubeVideoIdArr = (linkUrl.match(/(?<=youtube.com\/v=).*/) || linkUrl.match(/(?<=youtu\.be\/).*/) || linkUrl.match(/(?<=youtube.com\/watch\?v=).*/))
  const youtubeVideoId = (youtubeVideoIdArr ? youtubeVideoIdArr[0] : null)
  const youtubeEmbedUrl = `${youtubeUrl}/embed/${youtubeVideoId}?enablejsapi=1&autoplay=1`
  const youtubeVideoUrl = `${youtubeUrl}/watch?v=${youtubeVideoId}`

  const gifMp4 = linkUrl.replace('.gifv','.mp4')
  const gifvContentSource = [gifMp4].concat(defaultContentSource)


  if (youtubeVideoId) {
    return (
      <YoutubePostCardHeader
        youtubeVideoUrl={youtubeVideoUrl}
        youtubeEmbedUrl={youtubeEmbedUrl}
        postTitle={postTitle}
        youtubePreviewContentSource={defaultContentSource}
      />
    )
  } else if (
    (
      fileExtension === 'gifv' ||
      fileExtension === 'mp4' ||
      fileExtension === 'webm'
    ) && (linkDomain !== 'gifrun.com')
  ) {
    return (
      <GifvPostCardHeader
        postUrl={postUrl}
        postTitle={postTitle}
        contentSource={gifvContentSource}
      />
    )
  } else if (redditVideoFallbackUrl) {
    return (
      <GifvPostCardHeader
        postUrl={postUrl}
        postTitle={postTitle}
        contentSource={redditVideoFallbackUrl}
      />
    )
  } else if (
    (fileExtension === 'gif' && linkDomain !== 'gifrun.com') ||
    fileExtension === 'jpg' ||
    fileExtension === 'png'
  ) {
    return (
      <ImagePostCardHeader
        postUrl={postUrl}
        postTitle={postTitle}
        contentSource={defaultContentSource}
      />
    )
  } else if (
    fileExtension !== 'jpg' &&
    fileExtension !== 'png' &&
    linkDomain === 'imgur.com'
  ) {
    return (
      <ImagePostCardHeader
        postUrl={postUrl}
        postTitle={postTitle}
        contentSource={imgurFallbackContentSource}
      />
    )
  } else {
    return (
      <DefaultPostCardHeader
        postUrl={postUrl}
        postTitle={postTitle}
        contentSource={defaultContentSource}
      />
    )
  }
}

export default RedditPostCardHeaders