import React from 'react'
import SearchableButton from '../Buttons/SearchableButton'
import ShowMoreButton from '../Buttons/ShowMoreButton'
// import mixtapeSourceIcon from '../../assets/cassette.svg'
import '../Settings/Container.css'

class SearchableButtonList extends React.Component {
  constructor(props) {
    const methodName = 'SearchableButtonList.constructor'
    console.log(methodName, '- start')

    super(props)
    this.state = {
      numberToDisplay: 12,
    }
  }

  increaseNumberToDisplay = () => {
    const methodName = 'SearchableButtonList.increaseNumberToDisplay'
    console.log(methodName, '- start')

    const { numberToDisplay } = this.state
    this.setState({ numberToDisplay: numberToDisplay + 12 })
  }

  render() {
    const methodName = 'SearchableButtonList.render'
    console.log(methodName, this.props, this.state)

    const { 
      searchables, 
      subscribe, 
      unsubscribe, 
      searchableType,
      setSearchableState, 
      subscribedSearchableIds,
      isSignedIn,
      toggleVoteModal
    } = this.props
    
    const { numberToDisplay } = this.state

    const displaySearchables = searchables.slice(0, numberToDisplay)

    return (
      <div>
        <div style={{ width: '100%' }} className="container">
          {
            displaySearchables.map((searchable, i) => {
              const userIsSubscribed = subscribedSearchableIds.has(searchable.mixtapeSourceId)
              const onSubscribe = () => subscribe(searchable, searchableType)
              const onUnsubscribe = () => unsubscribe(searchable, searchableType)
              return (
                <SearchableButton
                  key={i}
                  searchableType={searchableType}
                  searchable={searchable}
                  setSearchableState={setSearchableState}
                  userIsSubscribed={userIsSubscribed}
                  onSubscribe={onSubscribe}
                  onUnsubscribe={onUnsubscribe}
                  isSignedIn={isSignedIn}
                  toggleVoteModal={toggleVoteModal}
                />
              )
            })
          }
        </div>

        <div>
          {(searchables.length > numberToDisplay)
            ?
            <div>
              <ShowMoreButton
                onClick={this.increaseNumberToDisplay}
                name={"Show More"}
              />
            </div>
            :
            <div>
            </div>
          }
        </div>
      </div>
    )
  }
}

export default SearchableButtonList