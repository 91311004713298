import React, { Component } from 'react'
import { Link, Switch, Redirect } from "react-router-dom"
import Menus from '../Menus/Menus'
import SearchBar from './SearchBar'
import SearchIcon from '@material-ui/icons/Search'
import voteOn from '../../assets/1vote-on.svg'
import twitter from '../../assets/twitterLogo.svg'
import youtube from '../../assets/youtubeLogo.svg'
import reddit from '../../assets/redditLogo.svg'
import news from '../../assets/news.png'
import enums from '../../enums/enums'

class SearchMobile extends Component {
  constructor(props) {
    super(props)
    this.state = {
      searchableType: enums.SEARCHABLE_TYPE.TWITTER_USER,
      searchField: '',
      hasSearchBeenMade: false,
    }
  }

  onSearchableTypeSelect = (searchableType) => {
    this.setState({
      searchableType
    })
  }

  onSearchChange = (event) => {
    this.setState({ searchField: event.target.value })
  }

  onSearchClick = async () => {
    this.setState({ hasSearchBeenMade: true }, () => {
      this.setState({ hasSearchBeenMade: false })
    })
  }

  render() {
    const { searchableType, searchField, hasSearchBeenMade } = this.state
    const { handleKeyPress, location, user } = this.props
    const { settingsPagePath } = searchableType
    const routes = [
      {
        name: 'twitter',
        displayName: 'Twitter',
        path: '',
        linkKey: 1,
        icon: <img
          src={twitter}
          height={'25px'}
          alt="twitter"
        />,
        selected: searchableType.id === enums.SEARCHABLE_TYPE.TWITTER_USER.id,
        package: enums.SEARCHABLE_TYPE.TWITTER_USER,
      },
      {
        name: 'news',
        displayName: 'News',
        path: '',
        linkKey: 2,
        icon: <img
          src={news}
          height={'25px'}
          alt="news"
        />,
        selected: searchableType.id === enums.SEARCHABLE_TYPE.NEWS_SOURCE.id,
        package: enums.SEARCHABLE_TYPE.NEWS_SOURCE,
      },
      {
        name: 'youtube',
        displayName: 'YouTube',
        path: '',
        linkKey: 3,
        icon: <img
          src={youtube}
          height={'25px'}
          alt="youtube"
        />,
        selected: searchableType.id === enums.SEARCHABLE_TYPE.YOUTUBE_CHANNEL.id,
        package: enums.SEARCHABLE_TYPE.YOUTUBE_CHANNEL,
      },
      {
        name: 'reddit',
        displayName: 'Reddit',
        path: '',
        linkKey: 4,
        icon: <img
          src={reddit}
          height={'25px'}
          alt="reddit"
        />,
        selected: searchableType.id === enums.SEARCHABLE_TYPE.REDDIT_SUBREDDIT.id,
        package: enums.SEARCHABLE_TYPE.REDDIT_SUBREDDIT,
      },
      {
        name: '1vote',
        displayName: '1vote',
        path: '',
        linkKey: 5,
        icon: <img
          src={voteOn}
          height={'25px'}
          alt="1vote"
        />,
        selected: searchableType.id === enums.SEARCHABLE_TYPE.ONEVOTE_USER.id,
        package: enums.SEARCHABLE_TYPE.ONEVOTE_USER,
      },
      {
        name: 'groups',
        displayName: 'Groups',
        path: '',
        linkKey: 6,
        icon: <img
          src={enums.SEARCHABLE_TYPE.ONEVOTE_GROUP.defaultIcon}
          height={'25px'}
          alt="voteOn"
        />,
        selected: searchableType.id === enums.SEARCHABLE_TYPE.ONEVOTE_GROUP.id,
        package: enums.SEARCHABLE_TYPE.ONEVOTE_GROUP,
      },
    ]
    return (
      hasSearchBeenMade
        ?
        <Redirect to={{
          pathname: settingsPagePath,
          state: {
            searchField,
          },
        }}
        />
        :
      <div style={{ marginTop: '150px' }}>
        <Switch>
          <Menus {...this.props}
            displayName={(location.state === undefined || location.state.userSettings === undefined) ? 'User Settings' : location.state.userSettings.displayName}
            linkKey={searchableType.id - 1}
            user={user}
            routes={routes}
            onPlatformSelect={this.onSearchableTypeSelect}
            parent="search"
            argument="package"
            navHeader="View by Platform"
          >
            <h3>Find {searchableType.pluralName}</h3>
            <div
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'center'
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  border: 'silver 1px solid',
                  width: '300px'
                }}
              >
                <SearchBar
                  onChange={this.onSearchChange}
                  handleKeyPress={handleKeyPress}
                  onSearchClick={this.onSearchClick}
                  searchField={searchField}
                  searchableType={searchableType}
                  displayBorder={'none'}
                  displayWidth={'95%'}
                />
                <Link
                  to={{
                    pathname: searchableType.settingsPagePath,
                    state: {
                      searchField,
                    }
                  }}
                  style={{ width: '40px' }}
                >
                  <button
                    onClick={this.onSearchClick}
                    style={{
                      height: '100%',
                      width: '100%',
                      padding: '0',
                      border: 'none',
                      background: 'white'
                    }}
                  >
                    <SearchIcon/>
                  </button>
                </Link>
              </div>
            </div>
          </Menus>
        </Switch>
      </div>
    )
  }
}


export default SearchMobile
