import React, { Component } from 'react'
import YoutubeAuth from './YoutubeSettings/YoutubeAuth/YoutubeAuth'
import TwitterAuth from './TwitterSettings/TwitterAuth/TwitterAuth'
import RedditAuth from './RedditSettings/RedditAuth/RedditAuth'
import PlatformAlreadyLinked from './PlatformAlreadyLinked'
import localUtils from '../../../functions/localUtils'
import enums from '../../../enums/enums'


import '../GenericSettings.css'
import '../../Buttons/Buttons.css'

class PlatformSettings extends Component {
  constructor(props) {
    const methodName = 'PlatformSettings.constructor'
    console.log(methodName, '- props,', props)

    super(props)
    this.state = {
      isAuthorized: false,
    }
  }

  updateIsAuthorized = () => {
    const methodName = 'PlatformSettings.updateIsAuthorized'
    console.log(methodName)

    let isAuthorized = false
    const { platform } = this.props
    if (platform.id === enums.PLATFORMS.TWITTER.id) {
      isAuthorized = !!localUtils.getTwitterCredential()
    } else if (platform.id === enums.PLATFORMS.NEWS.id || platform.id === enums.PLATFORMS.ONEVOTE.id) {
      isAuthorized = true
    } else if (platform.id === enums.PLATFORMS.YOUTUBE.id) {
      isAuthorized = !!localUtils.getYoutubeCredential()
    } else if (platform.id === enums.PLATFORMS.REDDIT.id) {
      isAuthorized = !!localUtils.getRedditCredential()
    }

    this.setState(
      { isAuthorized },
      () => console.log(methodName, '- isAuthorized', isAuthorized)
    )
  }

  componentDidMount = async () => {
    const methodName = 'PlatformSettings.componentDidMount'
    console.log(methodName, '- props, state:', this.props, this.state)

    this.updateIsAuthorized()
  }

  componentDidUpdate(prevProps) {
    if (this.props.platform !== prevProps.platform) {
      this.setState({ isAuthorized: false }, () => {
        this.updateIsAuthorized()
      })
    }
  }

  render() {
    const methodName = 'PlatformSettings.render'
    console.log(methodName, '- props, state:', this.props, this.state)

    const {
      setMixtapeSourcesState, user, subscribe, unsubscribe, platform,
      setSearchableState, getPlatformCredential, settingsPage, searchField, isSignedIn, toggleVoteModal
    } = this.props
    const { isAuthorized } = this.state
    return (
      <div className="GenericSettingsBody">
        <div className="GenericSettings">
          <h2> {settingsPage.title} </h2>
          <hr/>

          {
            (isAuthorized && !!user.platforms[platform.name]) || platform.id === enums.PLATFORMS.ONEVOTE.id
              ?
              <PlatformAlreadyLinked
                settingsPage={settingsPage}
                user={user}
                setMixtapeSourcesState={setMixtapeSourcesState}
                subscribe={subscribe}
                unsubscribe={unsubscribe}
                handleKeyPress={this.props.handleKeyPress}
                platform={platform}
                unlinkPlatform={this.props.unlinkPlatform}
                searchField={searchField}
                getYoutubeCredential={this.props.getYoutubeCredential}
                setSearchableState={setSearchableState}
                getPlatformCredential={getPlatformCredential}
                isSignedIn={isSignedIn}
                toggleVoteModal={toggleVoteModal}
              />
              :
              <div>
                {(platform.id === enums.PLATFORMS.TWITTER.id
                  ? 
                    <div>
                    <h6>Add Twitter content to your Mixtape</h6>
                    <TwitterAuth
                      src={isSignedIn ? "link" : "signIn"}
                    />
                    </div>
                  : (platform.id === enums.PLATFORMS.YOUTUBE.id
                      ?
                      <div>
                        <h6>Add Youtube content to your Mixtape</h6>
                        <YoutubeAuth
                          src={isSignedIn ? "link" : "signIn"}
                        />
                      </div>
                      : 
                      isSignedIn 
                        ?
                        <div>
                          <h6>Add Reddit content to your Mixtape</h6>
                          <RedditAuth
                            src="link"
                          />
                        </div>
                        : 
                        <div>
                          <h6>Sign In to add Reddit content to your Mixtape</h6>
                          {/* <RedditAuth
                            src="link"
                          /> */}
                        </div>
                  ))}
                  <hr/>
              </div>
          }
        </div>
      </div>
    )
  }
}

export default PlatformSettings