import React from 'react'
import { DateTime } from 'luxon'

const datetimeToStr = (input) => {
  if (!input) {
    return null
  }
  return DateTime.fromJSDate(new Date(input))
    .toLocaleString(DateTime.DATETIME_MED)
}

const VotePostCardCaption = (props) => {
  const methodName = 'VotePostCardCaption.render'
  console.log(methodName, '- props:', props)

  const { electionDisplayName, datetimeActivated, datetimeDeactivated } = props

  const datetimeActivatedStr = datetimeToStr(datetimeActivated)

  if (datetimeDeactivated) {
    const datetimeDeactivatedStr = datetimeToStr(datetimeDeactivated)
    return (
      <div className="vote-post-card-caption">
        <b>
          <hr/>
          <div className="vote-post-card-top-caption">
            {electionDisplayName}
          </div>
          <div className="vote-post-card-bottom-caption">
            <div className="vote-post-card-bottom-left-caption">
              {datetimeActivatedStr}
            </div>
            <div className="vote-post-card-bottom-middle-caption">
              -
            </div>
            <div className="vote-post-card-bottom-right-caption">
              {datetimeDeactivatedStr}
            </div>
          </div>
        </b>
      </div>
    )
  } else {
    return (
      <div className="vote-post-card-caption">
        <b>
          <hr/>
          <div className="vote-post-card-top-caption">
            {electionDisplayName}
          </div>
          <div className="vote-post-card-bottom-caption">
            {datetimeActivatedStr} (current)
          </div>
        </b>
      </div>
    )
  }
}

export default VotePostCardCaption