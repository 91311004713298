import React from 'react'
import '../Buttons/Buttons.css'
import { Link } from "react-router-dom"
import './../PostCard/PostCard.css'
import Img from 'react-image'
import Tooltip from '@material-ui/core/Tooltip'
import SubUnsubButton from './SubUnsubButton'
import CircularSpinner from './CircularSpinner'

class SearchableButton extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      imageHasBeenClicked: false,
    }
  }

  onImageClick = () => {
    const methodName = 'SearchableButton.onImageClick'
    console.log(methodName)

    const { searchableType, searchable, setSearchableState } = this.props

    if (searchableType.isNavigable) {
      setSearchableState({ searchableType, searchable }, () => {
        this.setState({ imageHasBeenClicked: true })
      })
    } else {
      console.log(methodName, '- searchableType is not navigable, ignoring click:', searchableType)
    }
  }

  shouldComponentUpdate(prevProps, prevState) {
    const userIsSubscribedChanged = prevProps.userIsSubscribed !== this.props.userIsSubscribed
    const titleChanged = prevProps.searchable.title !== this.props.searchable.title
    const imageHasBeenClickedChanged = prevState.imageHasBeenClicked !== this.state.imageHasBeenClicked
    return (
      userIsSubscribedChanged
      || titleChanged
      || imageHasBeenClickedChanged
    )
  }

  render = () => {
    const methodName = 'SearchableButton.render'
    console.log(methodName, '- props, state:', this.props, this.state)

    const { searchable, searchableType } = this.props
    const { imageUrl, imageLinkPath, title, subtitle, description } = searchable
    const { defaultIcon } = searchableType

    const { imageHasBeenClicked } = this.state

    let actualSubtitle = null
    const possibleSubtitle = subtitle || description
    if (!!possibleSubtitle && (possibleSubtitle !== '')) {
      actualSubtitle = possibleSubtitle
    }

    // if (imageHasBeenClicked) {
    //   return (<Redirect to={imageLinkPath} />)
    // }

    return (

      <div className="mixtape-source-button-2">
        <Link
          style={{ textDecoration: "none", color: "black" }}
          to={imageLinkPath}
          className="mixtape-source-button-link"
        >
          <div className="mixtape-source-button-2-avatar">
            <Img
              src={[imageUrl, defaultIcon]}
              className={"circle-image"}
              alt="social media account"
              onClick={this.onImageClick}
              loader={<CircularSpinner />}
            />
          </div>

          <div className="mixtape-source-button-2-text-box" onClick={this.onImageClick}>
            <Tooltip title={`${title}`}>
              <div className="mixtape-source-button-2-text-sub-box">
                {title}
              </div>
            </Tooltip>


            {
              (!!actualSubtitle) ?
                <Tooltip title={`${actualSubtitle}`}>
                  <div className="mixtape-source-button-2-text-sub-box">
                    {actualSubtitle}
                  </div>
                </Tooltip>
                : null
            }

          </div>
        </Link>
        {searchableType.isSubscribable
          ? <SubUnsubButton
            {...this.props}
            isPlatformLinked={true}
          />
          : null
        }

      </div>

    )
  }
}


export default SearchableButton
