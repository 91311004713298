import React from 'react'
import { Link } from "react-router-dom"
import UseStyles from './UseStyles'
import voteIcon from '../../assets/1vote-logo.svg'
import Img from 'react-image'
import Button from '@material-ui/core/Button'
import defaultProfileIcon from '../../assets/default_profile.png'
import enums from '../../enums/enums'

export default function MainHeader(props) {
  const classes = UseStyles.menusUseStyles()
  const {
    user,
    parent,
    isSignedIn,
  } = props

  return (
    <div style={{
      display: 'flex',
      justifyContent: 'space-between',
      className: classes.drawer,
      verticalAlign: 'baseline'
    }}>
      <div style={{ width: '30%' }}>
        <Link
            to={`/home/${enums.DEFAULT_ELECTION_TYPES[0].name}`}
            style={{ textDecoration: 'none', className: 'billboardNav' }}
        >
          <div style={{height: '60px', paddingLeft: '40px', display: 'flex', justifyContent:'center', alignItems: 'center'}}>
            <img
              src={voteIcon}
              alt="1vote icon"
              width="auto"
              height="45px"
            />
          </div>
        </Link>
      </div>
      <div style={{ width: '40%' }}>
      </div>
      <div style={{ width: '30%' }}>
        <div style={{
          position: 'relative',
          top: '50%',
          transform: 'translateY(-50%)'
        }}>
          {(isSignedIn)
            ? (<Link to={`/1vote_user/${user.id}`}>
              <Img
                src={[user.imageUrl, defaultProfileIcon]}
                height={'45px'}
                width={'45px'}
                style={{ borderRadius: '100%', marginRight:'-35px' }}
                alt="avatar"
              />
            </Link>)
            : (
              ((parent !== 'signIn') ? (
                  <Link to={"/signIn"}>
                    <Button variant="contained" color="primary">
                      Sign In
                    </Button>
                  </Link>
                ) : (
                <Link 
                to={`/home/${enums.DEFAULT_ELECTION_TYPES[0].name}`}
                >
                  <Button variant="contained" color="primary" style={{width: '90%' }}>
                    Explore
                  </Button>
                </Link>
                )
              ))
          }
        </div>
      </div>
    </div>
  )
}