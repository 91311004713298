
import React from 'react'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'

export default function ListItemComponent(props) {
    const {
        route,
        onPlatformSelect,
        argument
    } = props

    return (
        <ListItem
            button
            key={route.linkKey}
            selected={route.selected}
            onClick={() => { onPlatformSelect(route[argument]) }}
        >
            <ListItemIcon>{route.icon}</ListItemIcon>
            <ListItemText primary={route.displayName} />
        </ListItem>
    )
}