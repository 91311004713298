import React, { Component } from 'react'
import Button from '@material-ui/core/Button'
import ReactGA from 'react-ga'


import './UserSettings.css'
import '../GenericSettings.css'


const EditProfileField = ({ name, displayName, placeholder, onFormChange, maxLength=null, isSignedIn }) => {
  return (
    <div className="UserSettingsField">
      <div>
        <label 
          htmlFor={name}
          style={{fontWeight: '600', marginTop: '.5rem', }}
        > {displayName} </label>
      </div>
      <div>
        <input
          style={{width: '360px', padding: '.5rem', borderStyle: 'solid', borderWidth: '1px' }}
          type="text"
          name={name}
          placeholder={placeholder}
          id={name}
          onChange={onFormChange}
          maxLength={maxLength}
          disabled={!isSignedIn}
        />
      </div>
    </div>
  )
}

const EditProfileSubmitButton = ({ data, onClick }) => {
  const methodName = 'EditProfile.SubmitField'
  console.log(methodName, '- data:', data)
  return (
    <div className="UserSettingsSubmitButton">
      <Button variant="contained" color="primary" id="oneVoteUserProfileSettingsUpdate"
        onClick={()=>onClick(data)}
      >
        Update
      </Button>
    </div>
  )
}

class UserSettings extends Component {
  constructor(props) {
    super(props)
    this.state = {
      displayName: '',
      description: '',
      profileSuccessfullyUpdated: false,
      profileUnsuccessfullyUpdated: false,
    }
  }

  componentDidMount = async () => {
    const methodName = 'UserSettings.componentDidMount'
    console.log(methodName, '- user:', this.props.user)

    const { user } = this.props

    this.setState({
        displayName: user.displayName,
        description: user.description,
      },
      () => console.log(
        methodName, '- setState complete:', this.state))

    ReactGA.pageview('/userSettings')
  }

  onClick = (data) => {
    const methodName = 'UserSettings.onClick'
    console.log(methodName, '- data:', data)

    const { user, setUser, isSignedIn, toggleVoteModal } = this.props
    if(!isSignedIn){
      return toggleVoteModal({})
    }

      return async () => {
        console.log(methodName, '- update submitted')
        const wasSuccess = await setUser({...user, ...data}, true)

        if (wasSuccess) {
          console.log(methodName, '- updated successful!')
          this.setState({
            profileSuccessfullyUpdated: true, profileUnsuccessfullyUpdated: false,
          })
        } else {
          console.log(methodName, '- update unsuccessful')
          this.setState({
            profileSuccessfullyUpdated: false, profileUnsuccessfullyUpdated: true,
          })
        }
      }
  }

  onFormChange = (event) => {
    const methodName = 'UserSettings.onFormChange'
    console.log(methodName, event.target.name, event.target.value)

    switch (event.target.name) {
      case 'displayName':
        this.setState({ displayName: event.target.value })
        break
      case 'description':
        this.setState({ description: event.target.value })
        break
      default:
        return
    }
  }

  render() {
    const methodName = 'UserSettings.render'
    const { settingsPage, isSignedIn } = this.props
    const {
      displayName, description, profileSuccessfullyUpdated, profileUnsuccessfullyUpdated,
    } = this.state
    console.log(methodName, '- props, state;', this.props, this.state)

    return (
      <div className="GenericSettingsBody">
        <div className="GenericSettings">
          <h2> {settingsPage.title} </h2>
          <hr/>

          {profileSuccessfullyUpdated
            ? <p style={{ color: 'green' }}> Profile successfully updated. </p>
            : ''
          }

          {profileUnsuccessfullyUpdated
            ? <p style={{ color: 'red' }}> An error occurred updating your profile. </p>
            : ''
          }

          <EditProfileField
            name="displayName"
            displayName={'Display Name'}
            placeholder={displayName}
            onFormChange={this.onFormChange}
            maxLength={50}
            isSignedIn={isSignedIn}
          />
          <EditProfileField
            name="description"
            displayName={'Description'}
            placeholder={description}
            onFormChange={this.onFormChange}
            maxLength={140}
            isSignedIn={isSignedIn}
          />
          <EditProfileSubmitButton
            data={this.state}
            onClick={this.onClick}
          />

          <hr/>

        </div>
      </div>
    )
  }
}

export default UserSettings