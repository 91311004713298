import React from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import ReactGA from 'react-ga'

import MixtapePostCardList from '../Mixtape/MixtapePostCardList'
import PostCardListPlaceholder from '../PostCardList/PostCardListPlaceholder'
import Menus from '../Menus/Menus'

import api from '../../functions/api'
import interactionUtils from '../../functions/interactionUtils'
import enums from '../../enums/enums'
import localUtils from '../../functions/localUtils'

import mixtape from '../../assets/cassette.svg'
import DynamicFeedIcon from '@material-ui/icons/DynamicFeed';
import './Mixtape.css'


class Mixtape extends React.Component {
  constructor(props) {
    const methodName = 'Mixtape.constructor'
    console.log(methodName, '- start')
    // console.log(methodName, '- props:', props)
    super(props)
    this.state = {
      posts: [],
      nextStoredPostIndex: parseInt(0),
      storedPostsLength: parseInt(0),
      filteredPlatformId: 0,
      postsType: "mixtapePosts"
    }
  }

  componentDidMount = async () => {
    const methodName = 'Mixtape.componentDidMount'
    console.log(methodName, '- start')

    await this.storePosts()
    await interactionUtils.getPosts(this.setState.bind(this), this.state)

    ReactGA.pageview('/mixtape')
  }

  storePosts = async () => {
    const methodName = 'Mixtape.storePosts'
    console.log(methodName, '- start')

    const { getYoutubeCredential } = this.props
    const { postsType } = this.state
    const user = localUtils.getUser()
    const { platforms } = user
    console.log(methodName, '- Object.keys(platforms):', Object.keys(platforms))

    const credentials = localUtils.getCredentials()
    const electionId = 1

    // ##### 1 - TWITTER ##### //
    let twitterAccessToken = ''
    let twitterSecret = ''
    if (platforms.hasOwnProperty('twitter')&&!!platforms.twitter.info) {
      console.log(methodName, 'user has twitter')

      if (!credentials.hasOwnProperty('twitter')) {
        throw Error('no twitter credentials')
      }

      twitterAccessToken = credentials.twitter.accessToken
      twitterSecret = credentials.twitter.accessTokenSecret
    }
    console.log(methodName, '- twitterAccessToken, twitterSecret',
      twitterAccessToken, twitterSecret)

    // ##### 2 - NEWS ##### //
    let newsLanguage = 'en'
    let newsSourceStr = ''
    if (platforms.hasOwnProperty('news')) {
      console.log(methodName, 'user has news')
      newsSourceStr = Object.keys(platforms.news.mixtapeSources).join(",")
    }
    console.log(methodName, '- newsLanguage, newsSourceStr',
      newsLanguage, newsSourceStr)

    // ##### 3 - YOUTUBE ##### //
    let youtubeAccessToken = ''
    let youtubeChannelIds = []
    if (platforms.hasOwnProperty('youtube')&&!!platforms.youtube.info) {
      console.log(methodName, 'user has youtube')

      if (!credentials.hasOwnProperty('youtube')) {
        throw Error('no youtube credentials')
      }

      const youtubeTokens = await getYoutubeCredential()
      youtubeAccessToken = youtubeTokens.access_token
      const youtubeChannels = Object.values(platforms.youtube.mixtapeSources)
      console.log(methodName, '- youtubeChannels:', youtubeChannels)
      youtubeChannels.forEach(yc => youtubeChannelIds.push(yc.playlistId))
    }
    console.log(methodName, '- youtubeAccessToken, youtubeChannelIds:',
      youtubeAccessToken, youtubeChannelIds)

    // ##### 4 - REDDIT ##### //
    let redditRefreshToken = ''
    if (platforms.hasOwnProperty('reddit')&&!!platforms.reddit.info) {
      console.log(methodName, 'user has reddit')

      if (!credentials.hasOwnProperty('reddit')) {
        throw Error('no youtube credentials')
      }

      redditRefreshToken = credentials['reddit'].refresh_token
    }
    console.log(methodName, '- redditRefreshToken:', redditRefreshToken)

    const mixtapeProps = {
      electionId,
      twitterAccessToken,
      twitterSecret,
      newsSourceStr,
      newsLanguage,
      youtubeChannelIds,
      youtubeAccessToken,
      redditRefreshToken,
    }
    console.log(methodName, '- mixtapePosts:', mixtapeProps)

    const mixtapePosts = await api.getMixtape(mixtapeProps)
    console.log(methodName, '- mixtapePosts:', mixtapePosts)

    const mappedPosts = interactionUtils.mapPosts(mixtapePosts, user)

    console.log('mappedPosts - data', mappedPosts)

    await interactionUtils.setSessionPosts(postsType, mappedPosts)

    this.setState({ storedPostsLength: mappedPosts.length })
  }

  onVoteChange = async (electionId, postId, platformProps, isVoted) => {
    const methodName = 'MixtapeSourceTimeline.onVoteChange'
    console.log(methodName, '- electionId, postId, platformProps, isVoted:', electionId, postId, platformProps, isVoted)

    return interactionUtils.onVoteChange(this.setState.bind(this), postId, platformProps, this.state, isVoted)
  }

  onPlatformFilterClick = (platformId) => {
    const methodName = 'Mixtape.onPlatformFilterClick'
    console.log(methodName, '- platformId:', platformId)

    if (this.state.filteredPlatformId === platformId) {
      this.setState({
        filteredPlatformId: 0
      })
    } else {
      this.setState({
        filteredPlatformId: platformId
      })
    }
  }

  render() {
    const methodName = 'Mixtape.render'
    console.log(methodName, '- start')

    const { posts, storedPostsLength, nextStoredPostIndex, filteredPlatformId } = this.state
    const routes = [
      {
        displayName: 'All Platforms',
        path: '', 
        linkKey: 0,
        id: 0,
        icon: <DynamicFeedIcon
          height={'25px'}
          alt="all platforms"
          title="all platforms"
        />,
        selected: filteredPlatformId === 0
      }
    ]

    Object.values(enums.PLATFORMS).slice(1).forEach((platform,i)=>{
      let platformCopy = {...platform}
      const selected = filteredPlatformId ===platform.id
      platformCopy.selected=selected
      platformCopy.icon = <img
        src={platformCopy.src}
        height={'25px'}
        alt={platformCopy.name}
        title={platformCopy.name}
      />
      platformCopy.linkKey = platformCopy.id
      platformCopy.path = ''
      routes.push(platformCopy)
    })
    return (
      <div>
        <Menus
          {...this.props}
          linkKey={!!filteredPlatformId ? filteredPlatformId : 0}
          routes={routes}
          onPlatformSelect={this.onPlatformFilterClick}
          user={this.props.user}
          parent='mixtape'
          argument='linkKey'
          navHeader='View by Platform'
        >
          <div className="MixtapeBody">
            {posts.length > 0
              ?
              (<div className="MixtapePostcardListWrapper">
                <div className="MixtapePostCardList">

                  <InfiniteScroll
                    dataLength={posts.length}
                    next={()=>interactionUtils.getPosts(this.setState.bind(this), this.state)}
                    hasMore={(storedPostsLength > nextStoredPostIndex)}
                    // loader={<h5>Loading Your Posts...</h5>}
                    //should go away if everything is filtered out
                    //believe we're hitting external endpoints everytime we render a postcard, which happens everytime we change filters so may want to limit that based on time between last call so we don't get cutoff
                    // endMessage={<h5>You ran out of posts! Refresh for more, or add more post sources.</h5>}
                  >
                    <MixtapePostCardList
                      {...this.props}
                      postCardListType={enums.POST_CARD_LIST_TYPE.MIXTAPE}
                      posts={posts}
                      onVoteChange={this.onVoteChange}
                      filteredPlatformId={filteredPlatformId}
                    />
                  </InfiniteScroll>
                </div>
              </div>)
              :
              <PostCardListPlaceholder />
            }
          </div>
        </Menus>

      </div>

    )
  }

}

export default Mixtape
