import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import localUtils from '../../functions/localUtils'
import api from '../../functions/api'
import './AuthHandler.css'
import enums from '../../enums/enums'
import ReactGA from 'react-ga'

class RedditAuthHandler extends Component {
  constructor(props) {
    const methodName = 'RedditAuthHandler.constructor'
    console.log(methodName, '- props,', props)
    super(props)
  }

  componentDidMount = async () => {
    const methodName = 'RedditAuthHandler.componentDidMount'
    console.log(methodName, '- props:', this.props)

    const { setPlatformState, setCredential } = this.props
    const platformId = enums.PLATFORMS.REDDIT.id

    // (JL) I had this line below instead, not sure why, but wanted to save it
    // const urlParams = new URLSearchParams(this.props.location.search)
    const urlParams = new URLSearchParams(window.location.search)
    const code = urlParams.get('code')
    const state = urlParams.get('state')
    console.log(methodName, '- state, code:', state, code)

    if ((code) && (state === 'reddit')) {
      console.log(methodName, '- reddit code present!')

      const credential = await api.getCredential(platformId, {code})
      console.log(methodName, '- credential:', credential)

      if (credential.refresh_token) {
        await setCredential('reddit', credential)

        const info = await api.getInfo(platformId, credential)
        console.log(methodName, '- userInfo:', info)

        const mixtapeSources = await api.getUsersMixtapeSources(platformId, credential)
        console.log(methodName, '- mixtapeSources:', mixtapeSources)

        const platform = { info, mixtapeSources }
        console.log(methodName, '- platform:', platform)

        setPlatformState('reddit', platform)

        //GA conversion event
        const localUser = localUtils.getUser()

        ReactGA.pageview('/authHandler/RedditAuthHandler')
        ReactGA.event({
          category: 'user',
          action: 'auth',
          label: 'provider: reddit, campaign:'+localUtils.getCampaignAttribution().campaignId,
        })
      }
    }
  }

  render() {
    const methodName = 'RedditAuthHandler.render'
    console.log(methodName, '- props:', this.props)

    const credentials = localUtils.getCredentials() || {}
    const isAuthorized = !!credentials["reddit"]

    return (
      <div className="auth-handler">
        {
          isAuthorized
            ? <Redirect
              to={'/userSettings/reddit'}
            />
            : <h3>'Redirecting... Please Wait'</h3>
        }
      </div>
    )
  }
}

export default RedditAuthHandler