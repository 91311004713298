import React from 'react'
import LazyLoad from 'react-lazyload'
import Img from 'react-image'
import ReactSpinner from 'react-bootstrap-spinner'

import '../../PostCard.css'

const RectangularSpinner = () => {
  return (
    <div className="spinner-rectangular">
      <ReactSpinner type="border" color="primary" size="5"/>
    </div>
  )
}

export default function ImagePostCardHeader(props) {
  const methodName = 'ImagePostCardHeader.render'
  console.log(methodName, '- props:', props)

  const {
    postUrl,
    postTitle,
    contentSource,
  } = props


  return (
    <div>

      <a
        href={postUrl}
        target="_blank"
        rel="noopener noreferrer"
      >
        <LazyLoad offset={100} once={true}>
          <div className="rectangle-image-reddit">
            <Img
              src={contentSource}
              className="rectangle-image-reddit"
              alt={postTitle}
              title={postTitle}
              loader={<RectangularSpinner/>}
            />
          </div>
        </LazyLoad>
      </a>
      
    </div>
  )
}

