import React from 'react'
import BillboardBody from './BillboardBody'
import Menus from '../Menus/Menus'
import './Billboard.css'
import groupsIcon from '../../assets/groups.png'
import Img from 'react-image'
import {
  Switch,
  Route
} from "react-router-dom"
import enums from '../../enums/enums'


class Billboard extends React.Component {
  constructor(props) {
    const methodName = 'Billboard.constructor'
    console.log(methodName, '- start')
    // console.log(methodName, '- props:', props)
    super(props)
    this.state = {
      electionId: 0,
      electionName: '',
      path: '',
      electionTypeId: 0,
      electionTypeName: '',
    }
  }

  render() {
    const methodName = 'Billboard.render'
    console.log(methodName, '- start')
    // console.log(methodName, this.props, this.state)
    const {
      user,
      location,
      isSignedIn,
    } = this.props

    const materialUiRoutes = []

    enums.DEFAULT_ELECTION_TYPES.forEach((electionType, i) => {
      let electionTypeCopy = {...electionType}
      const selected = location.pathname.replace(`/home/${electionTypeCopy.name}/`, '')===electionTypeCopy.name
      electionTypeCopy.selected = selected
      electionTypeCopy.icon = <Img 
        src={[electionTypeCopy.image, groupsIcon] } 
        className="circle-image" 
        alt={electionTypeCopy.name} 
        title={electionTypeCopy.name} 
      />
      electionTypeCopy.linkKey = i
      electionTypeCopy.path = `/${electionTypeCopy.name}/`
      materialUiRoutes.push(electionTypeCopy)
    })
    const reactRouterRoutes = materialUiRoutes.concat(user.elections.map(election=>election))
    console.log(methodName, '- reactRouterRoutes:', reactRouterRoutes)
    return (
      <Switch>
        <Menus {...this.props}
          displayName={location.pathname.replace('/home/', '')}
          linkKey={(location.state === undefined || location.state.billboard === undefined) ? 0 : location.state.billboard.linkKey}
          user={user}
          isSignedIn={isSignedIn}
          routes={materialUiRoutes}
          parent={'billboard'}
          navHeader={''}
          onPlatformSelect={()=>{}}
        >
          {
            reactRouterRoutes.map(electionPage => {
              console.log(methodName, '- electionPage:', electionPage, electionPage.linkKey, !!electionPage.path)
              const path = !!electionPage.path ? `/home/${electionPage.name}` : `/home/${electionPage.electionTypeName}/${electionPage.name}`
              return (
                <Route 
                  exact
                  path={path}
                  key={electionPage.name}
                >
                  <div className="billboardPages">
                    <BillboardBody {...this.props}
                      name={electionPage.name}
                      election={electionPage}
                      key={electionPage.id}
                    />
                  </div>
                </Route>
              )
            })
          }
        </Menus>
      </Switch>
    )
  }
}

export default Billboard


