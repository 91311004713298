import React from 'react'
import PostCard from '../../PostCard'
import './VotePostCard.css'
import VotePostCardCaption from './VotePostCardCaption'

const VotePostCard = (props) => {
  const methodName = 'VotePostCard.render'
  console.log(methodName, '- start')

  const { electionDisplayName, datetimeActivated, datetimeDeactivated, ...post } = props
  return (
    <div
      className="vote-post-card-body"
    >
      <VotePostCardCaption
        electionDisplayName={electionDisplayName}
        datetimeActivated={datetimeActivated}
        datetimeDeactivated={datetimeDeactivated}
      />
      <PostCard
        {...post}
      />
    </div>)
}

export default VotePostCard