import React, { Component } from 'react'
import PostCardList from '../PostCardList/PostCardList'
import api from '../../functions/api'
import Menus from '../Menus/Menus'
import './OneVoteUserTimeline.css'
import allIcon from '../../assets/all-icon1.svg'
import groupsIcon from '../../assets/groups.png'
import Img from 'react-image'
import arrayUtils from '../../functions/arrayUtils'
import enums from '../../enums/enums'
import ReactGA from 'react-ga'


class OneVoteUserTimeline extends Component {
  constructor(props) {
    const methodName = 'OneVoteUserTimeline.constructor'
    console.log(methodName, '- start')
    // console.log(methodName, '- props:', props)
    super(props)
    this.state = {
      electionId: 0,
      electionTypeId: -1,
    }
  }

  /*ideally, all current votes route would show all live votes, in separate electionType sections with links to those elections that would then show all of that user's archived votes in that election;
  ideally, each electionType route would do the same as above, but just for that electionType (like billboard)
  for now though, I think we'll just show all 
  */
  sortFunc = (a, b) => null

  getUserDetailsFromUserId = async (userId) => {
    const methodName = 'OneVoteUserTimeline.getUserDetailsFromUserId'
    console.log(methodName, '- userId:', userId)

    const voteUser = await api.getUser(userId)
    console.log(methodName, '- voteUser:', voteUser)

    return voteUser
  }

  getPosts = async () => {
    const methodName = 'OneVoteUserTimeline.getPosts'
    console.log(methodName, '- start')

    const { match, user } = this.props
    const { activeVotes } = user
    const { electionId, electionTypeId } = this.state
    const { userId } = match.params

    if(userId!=='undefined'){

      console.log(methodName, 'electionId, userId', electionId, userId)

      let posts = []
      if (electionTypeId === -1) {
        posts = await api.getUsersCurrentlyVotedPosts(userId)
        console.log(methodName, '- posts, electionTypeId:', posts, electionTypeId)
      } 
      else {
        const data = await api.getUsersCurrentlyVotedPosts(userId)
        console.log(methodName, '- data, electionTypeId:', data, electionTypeId)

        posts = data.filter(post=>post.electionTypeId===electionTypeId)
        console.log(methodName, '- posts, fuck:', posts)

      }
      // else {
      //   posts = await api.getUserTimeline(electionId, userId)
      // }
      console.log(methodName, '- posts:', posts)

      return posts.map(post => {
        const postId = parseInt(post.postId)

        const votedPost = activeVotes[post.electionId]
        const votedPostId = votedPost ? parseInt(votedPost.postId) : 0

        return {
          ...post,
          postIsVoted: votedPostId === postId,
        }
      })
    } else {
      return []
    }
  }

  onElectionFilterClick = (electionTypeId) => {
    const methodName = 'OneVoteUserTimeline.onElectionFilterClick'
    console.log(methodName, '- electionTypeId:', electionTypeId)

    this.setState(prevState => {
      if (prevState.electionTypeId === electionTypeId || electionTypeId === -1) {
        return { electionTypeId: -1 }
      } else {
        return { electionTypeId }
      }
    }, () => console.log(methodName, '- setState complete'))
  }

  render() {
    const methodName = 'OneVoteUserTimeline.render'
    console.log(methodName, this.props, this.state)

    const { electionId, electionTypeId } = this.state
    const { userId } = this.props.match.params
    console.log(methodName, '- userId: ', userId)

    const isValidUserId = userId!=='undefined'
    console.log(methodName, '- isValidUserId: ', isValidUserId)

    const loadingMessage = isValidUserId ? "Currently no posts voted for." : "Sign in and start Voting to build out your profile!"
    console.log(methodName, '- loading message: ', loadingMessage)
    const {
      user,
      location,
    } = this.props

    const foundElection = arrayUtils.getByField(user.elections, 'id', electionId, false)
    const election = foundElection || { id: 0 }
    const materialUiRoutes = [ {
      electionName: 'All Live',
      displayName: 'All Live Votes',
      path: '/',
      electionId: 0,
      icon: (<Img
          src={[allIcon, groupsIcon]}
          className="circle-image"
          alt={'All Live Votes'}
          title={'All Live Votes'}
        />),
      id: -1,
      linkKey: 0,
      selected: electionTypeId === -1
    },]

    enums.DEFAULT_ELECTION_TYPES.forEach((electionType, i) => {
      let electionTypeCopy = {...electionType}
      const selected = electionTypeId===electionTypeCopy.id
      electionTypeCopy.selected = selected
      electionTypeCopy.icon = <Img 
        src={[electionTypeCopy.image, groupsIcon] } 
        className="circle-image" 
        alt={electionTypeCopy.name} 
        title={electionTypeCopy.name} 
      />
      electionTypeCopy.linkKey = i+1
      electionTypeCopy.path = ''
      materialUiRoutes.push(electionTypeCopy)
    })
    const reactRouterRoutes = materialUiRoutes.concat(user.elections.map(election=>election))
    console.log(methodName, '- reactRouterRoutes:', reactRouterRoutes)

    ReactGA.pageview(`/1vote_user/${userId}/${this.state.electionId}`)

    return (
      <Menus {...this.props}
        displayName={(location.state === undefined || location.state.billboard === undefined) ? 'Front Page' : location.state.billboard.displayName}
        linkKey={electionTypeId+1}
        user={user}
        routes={materialUiRoutes}
        onPlatformSelect={this.onElectionFilterClick}
        currentElection={electionTypeId+1}
        argument={'id'}
        parent={'oneVoteUserTimeline'}
      >
        <div className="ProfileBody" style={{marginTop:'120px'}}>
          <div className="ProfilePostcardListWrapper">
            <div className="ProfilePostCardList">
              <PostCardList
                {...this.props}
                key={`PostCardList-${userId}-${electionId}-${electionTypeId}`}
                postCardListType={enums.POST_CARD_LIST_TYPE.PROFILE}
                postCardListName={`${userId}-${electionId}-${electionTypeId}`}
                getPosts={this.getPosts}
                sortFunc={this.sortFunc}
                election={election}
                loadingMessage={loadingMessage}
              />
            </div>
          </div>
        </div>
      </Menus>
    )
  }
}

export default OneVoteUserTimeline