import ReactGA from 'react-ga'
import localUtils from './localUtils'

const initializeGoogleAnalytics = () => {
  const methodName = 'googleanalyticsUtils.initializeGoogleAnalytics'
  const googleAnalyticsProject = 'UA-145479778-1'

  let userId

  //if user from ad campaign, use temporary id
   if (localUtils.getCampaignAttribution().userId) {
     userId = localUtils.getCampaignAttribution().userId
   } else {
     userId = localUtils.getUser().id
   }

  ReactGA.initialize(googleAnalyticsProject, {
    titleCase: false,
    gaOptions: {
      userId: userId,
      campaignSource: localUtils.getCampaignAttribution().campaignSource,
      campaignMedium: localUtils.getCampaignAttribution().campaignMedium,
      campaignContent: localUtils.getCampaignAttribution().campaignContent,
      campaignId: localUtils.getCampaignAttribution().campaignId,
    }
  })

}

export default {
  initializeGoogleAnalytics
}