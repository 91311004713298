import React from 'react'
import Button from '@material-ui/core/Button'
import Tooltip from '@material-ui/core/Tooltip'

import newsSources from './NewsSettings/newsSources'
import SearchableButtonList from '../../Search/SearchableButtonList'
import api from '../../../functions/api'
import arrayUtils from '../../../functions/arrayUtils'
import localUtils from '../../../functions/localUtils'
import SearchBar from '../../Search/SearchBar'
import SearchIcon from '@material-ui/icons/Search'
import enums from '../../../enums/enums'
import { ScrollToSearch } from '../../Scroll/ScrollToLocation'
import '../../Buttons/Buttons.css'
import ReactGA from 'react-ga'


const UnlinkButton = (props) => {
  const methodName = 'UnlinkButton.render'
  console.log(methodName, '- props:', props)
  const { platform, unlinkPlatform } = props
  return (
    <div>
      <div style={{display: 'flex', justifyContent: 'flex-end', marginRight: '14%'}}>
        <div style={{display: 'flex', flexDirection: 'column', justifyContent:'center', marginRight: '5px'}}>
          <span>{`Connected the wrong ${platform.displayName} account?`}</span>
        </div>
        <div style={{ alignItems: 'center' }}>
          <Tooltip title={`This will remove the link between 1vote and ${platform.displayName}` }>
            <Button
              variant="outlined" color="primary" size="small"
              onClick={() => unlinkPlatform(platform)}
            >
              Unlink
            </Button>
          </Tooltip>
        </div>
        </div>
        <hr/>
    </div>
  )
}


class PlatformAlreadyLinked extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      searchField: '',
      searchResults: []
    }
  }

  componentDidMount() {
    if (!!this.props.searchField) {
      this.setState({
        searchField: this.props.searchField,
      }, () => {
        this.onSearchClick()
      })
    }

    ReactGA.pageview(`/userSettings/${this.props.platform.name}`);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.searchField !== this.props.searchField) {
      this.setState({
        searchField: this.props.searchField,
      }, () => {
        this.onSearchClick()
      })
    }
  }

  getSubscribedSearchables = () => {
    const methodName = 'MixtapeSourceAlreadyLinked.getSubscribedSearchables'
    console.log(methodName, '- start')

    const { settingsPage, user } = this.props
    const { searchableType } = settingsPage
    const { platforms } = user
    const { platform } = searchableType

    if (!searchableType.isSubscribable) {
      return {}
    }

    return platforms[platform.name].mixtapeSources
  }

  onSearchChange = (event) => {
    this.setState({ searchField: event.target.value })
  }

  onSearchClick = async () => {
    const methodName = 'MixtapeSourceAlreadyLinked.onSearchClick'
    console.log(methodName, this.props, this.state)

    const { platform, getPlatformCredential } = this.props
    const { searchField } = this.state
    console.log(methodName, '- searchField:', searchField)
    let searchResults = ''

    // Get Search Results
    if (platform.id === enums.PLATFORMS.ONEVOTE.id) {
      console.log(methodName, '- 1vote Search')
      searchResults = await api.searchOneVoteUsers(searchField)
      console.log(methodName, '- searchResults: ', searchResults)
    } else if (platform.id === enums.PLATFORMS.NEWS.id) {
      console.log(methodName, '- News MixtapeSource Search')
      searchResults = newsSources.allNewsSources.filter(source => {
        return source.filterName.toLowerCase()
          .includes(searchField.toLowerCase())
      })
    } else {
      console.log(methodName, '- External Platform MixtapeSource Search')
      const credential = await getPlatformCredential(platform)
      searchResults = await api.searchMixtapeSources(platform.id, credential, searchField)
    }
    console.log(methodName, '- searchResults:', searchResults)

    // Set Search Results
    if (platform.id !== enums.PLATFORMS.NEWS.id) {
      this.setState({
        searchResults
      }, () => console.log(methodName, 'search results set!'))
    } else {
      this.setState({
        searchResults: arrayUtils.arrayToObject(searchResults, 'mixtapeSourceId')
      }, () => console.log(methodName, 'search results set!'))
    }
  }


  render() {
    const methodName = 'NewsSettings.render'
    console.log(methodName, this.props)

    const { 
      user, 
      subscribe, 
      unsubscribe, 
      setSearchableState, 
      unlinkPlatform, 
      settingsPage, 
      isSignedIn, 
      toggleVoteModal 
    } = this.props
    
    const { searchableType } = settingsPage
    const { pluralName, isSubscribable, platform } = searchableType
    const { searchResults } = this.state
    const { id: platformId, name: platformName } = platform

    const subscribedSearchables = this.getSubscribedSearchables()
    const subscribedSearchableIds = new Set(Object.keys(subscribedSearchables))

    const credentials = localUtils.getCredentials()

    const hasTwitter = !!credentials[enums.PLATFORMS.TWITTER.name]
    const hasYoutube = !!credentials[enums.PLATFORMS.YOUTUBE.name]
    const onTwitterHasYoutube = (platformId === enums.PLATFORMS.TWITTER.id) && hasYoutube
    const onYoutubeHasTwitter = (platformId === enums.PLATFORMS.YOUTUBE.id) && hasTwitter
    const onReddit = (platformId === enums.PLATFORMS.REDDIT.id)
    const safeToUnlink = (onTwitterHasYoutube || onYoutubeHasTwitter || onReddit)

    return (
      <div>

        {isSubscribable
          ? <div>
            <h3> Your {pluralName} </h3>

            <SearchableButtonList
              searchableType={searchableType}
              searchables={Object.values(subscribedSearchables)}
              subscribedSearchableIds={subscribedSearchableIds}
              subscribe={subscribe}
              unsubscribe={unsubscribe}
              setSearchableState={setSearchableState}
              isSignedIn={isSignedIn}
              toggleVoteModal={toggleVoteModal}
            />

            <hr/>
          </div>
          : ''
        }

        <ScrollToSearch {...this.props}>
          <h3> Find {pluralName} </h3>
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'center'
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: '.5rem',
                border: 'silver 1px solid',
                width: '400px'
              }}
            >
              <SearchBar
                onChange={this.onSearchChange}
                handleKeyPress={this.props.handleKeyPress}
                onSearchClick={this.onSearchClick}
                searchableType={searchableType}
                displayBorder={'none'}
                displayWidth={'100%'}
              />
              <button
                onClick={this.onSearchClick}
                style={{
                  width: '5%',
                  minWidth: '40px',
                  border: 'none',
                  background: 'none'
                }}
              >
                <SearchIcon/>
              </button>
            </div>
          </div>
          <SearchableButtonList
            searchableType={searchableType}
            searchables={Object.values(searchResults)}
            subscribedSearchableIds={subscribedSearchableIds}
            subscribe={subscribe}
            unsubscribe={unsubscribe}
            setSearchableState={setSearchableState}
            isSignedIn={isSignedIn}
            toggleVoteModal={toggleVoteModal}
          />
          <hr/>
        </ScrollToSearch>

        {safeToUnlink
          ? 
            <UnlinkButton
              platform={platform}
              unlinkPlatform={unlinkPlatform}
              userPlatformDisplayName={user.platforms[platformName].info.displayName}
          />
          : ''}
      </div>
    )
  }
}

export default PlatformAlreadyLinked